.quantity
{
    height: 1.5em;
    width: 1.5em;
}

.quantityIcon
{
    margin-left: -0.5em;
    margin-top: -0.5em;
}

/* Media Query for mobile */
       @media (max-width: 480px){
        .row.d-flex.justify-content-center.align-items-center.h-100 {
           width: 123%;
        }
    }
    