.dropdownarrow_image {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 8 4.94'%3e%3cpath id='ic_keyboard_arrow_down_24px' d='M6.94,7.84,10,10.893,13.06,7.84l.94.94-4,4-4-4Z' transform='translate(-6 -7.84)' fill='%232378e9'/%3e%3c/svg%3e")
    no-repeat !important;
  background-position: right 0.5rem center !important;
  appearance: none;
}
.dropdownarrow_image option:checked {
  background-color: #2378e9;
  color: white;
}
.dropdownarrow_image option:hover {
  background-color: #2378e9 !important;
  color: white !important;
}
@media (max-width: 576px) {
  .selectInputs {
    width: 100% !important;
  }
}
