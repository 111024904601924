.form-group.col-md-6 {
    text-align: left;
    /* margin-bottom: 2em; */
    padding: 2em 2em 0em 3em;
}
label {
    font-weight: 500;
}
a.course_link,a.course_cancel {
    color: white;
    text-decoration: none;
}
