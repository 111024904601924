
@import url('../../../Themes/themes.css');

button.SaveButton.Counselling
{
width: var(--CounsellingsavebuttonWidth) !important;
}
button.SaveButton.Counselling2 {
    width: var(--Counsellingsavebutton2Width) !important;
}
#cardItems{

    padding: 60px;
}

#preferredpost{

    margin-left: 16px;
    margin-top: 6px;
    width: 81%;
}

#preferredDept {
    margin-left: 16px;
    margin-top: 6px;
    width: 81%;
}
/* .form-check-label  {
  margin-top: 25px !important;
} */