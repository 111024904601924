/* Popup.css */

/* Popup overlay styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* --------Text Not Select----- */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  /* ----------------------------- */
}

.popup-content {
  background: white;
  border-radius: 8px;
  width: 900px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Note_Popup {
  background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-family: var(--fontfamily2);
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: var(--colorBlue);
  padding: 11px 14px;
}

.Warning_Popup {
  background: #f2dede 0% 0% no-repeat padding-box;
  border: 1px solid #c1393d;
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-family: var(--fontfamily2);
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #c1393d;
  padding: 11px 14px;
}

.popup-header {
  padding: 16px 24px;
  border-bottom: 3px solid #373435;
  display: flex;
  align-items: start;
  margin-bottom: 14px;
}

.popup-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.popup-body {
  padding: 0px 24px;
  max-height: 400px;
  overflow-y: auto;
}

.popup-text {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.popup-footer {
  padding: 16px 24px;
  border-top: 3px solid #373435;
  display: flex;
  justify-content: flex-end;
}

.popup-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #b71c1c;
}

.popup-icon {
  font-size: 18px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #c0272d;
}

/* Custom scrollbar styles for popup-body */
.popup-body::-webkit-scrollbar {
  width: 5px !important;
}

.popup-body::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.popup-body::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.popup-body::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


/* ---------------Server Time Popup (start)------------- */
#alert-dialog-slide-description ul {
  list-style-type: disc;
  margin-top: -25px;
  margin-left: -10px;
}

#alert-dialog-slide-description ul li::marker {
  color: #b71c1c;
  /* Color of the bullet points */
  font-size: 20px;
}
.dialog_Title{
  font-size: 20px;
  font-weight: 800;
  font-family: var(--fontfamily2); 
}
@media screen and (max-width: 576px) {
  .join-tests {
    padding-top: 20px !important;
  }
  .attemptWarning {
    margin-top: 30px !important;
  }
}
.server_time {
  text-align: left;
  font-weight: 500;
  font-family: var(--fontfamily2);
  font-size: 14px;
}
/* .Navigation_Button{
background-color: #c1393d;
align-items: center;
border-radius: var(--border-radius-sm);
color: #f1f1f1;
font-size: 14px;
font-weight: 600;
font-family: var(--fontfamily2);
letter-spacing: 0.8px;
padding: 6px 12px;
position: relative;
display: inline-flex;
justify-self: center;

} */


/* -------mobile view-------- */
@media screen and (max-width: 576px) {
  .server_time {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 2px; */
  }
  .dialog_Title{
    font-size: 16px;
  }
}

/* ---------------Server Time Popup(end)------------- */