.la_table {
    width: 100%;
    border-spacing: 0;
    text-align: left;
  }
  .la_table th {
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    line-height: 26px;
    padding: 18px 30px;
  }
  .la_table thead tr {
    background: #2b2d32;
  }
  .la_table td {
    padding: 30px 30px 31px;
    font-weight: 300;
    font-size: 16px;
    color: black-2;
    line-height: 26px;
    text-transform: uppercase;
  }
  .la_table tbody tr:nth-child(odd) {
    background: white;
  }
  .la_table tbody tr:nth-child(even) {
    background: #f8fbfc;
  }
  .la_table__wrapper {
    padding-top: 40px;
  }
  .la_btn {
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    text-transform: uppercase;
    width: 170px;
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    transition: background 0.3s ease;
    text-decoration: none;
  }
  .la_btn:hover {
    color: white;
  }
  .la_btn__active {
    color: #4ed99c;
    border: 2px solid #4ed99c;
  }
  .la_btn__active:hover {
    background: #4ed99c;
  }
  .la_btn__pledged {
    color: #f14044;
    border: 2px solid #f14044;
  }
  .la_btn__pledged:hover {
    background: #f14044;
  }
  @media (max-width: 768px) {
    .la_table td {
      display: block;
      text-align: right;
    }
    .la_table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
    }
    .la_table thead display none tr {
      margin-bottom: 30px;
      display: block;
    }
  }