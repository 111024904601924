/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */
@import url("../../../Themes/themes.css");

/* student confirm page style start */
input.form-check-input.app_status {
  margin-top: 1.6em;
  margin-left: 0em;
  margin-right: -1em;
}

.row.feedback {
  padding: 6px 17px 6px 17px;
  margin-bottom: 1.5em;
}

textarea#feedback {
  height: 72px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 1px solid var(--colorBlue); */
  border-radius: 6px;
  /* opacity: 0.2; */
}

label.feedback_label {
  margin-left: 0.2em !important;
  margin-bottom: 1em;
}

label.form-check-label.app_status {
  width: 52px !important;
}

.iconregister_app {
  color: var(--color);
  margin-left: 4em;
}

.register_app_small {
  /* font-size: var(--fontsize_subheading) !important; */
  letter-spacing: 0.24px;
  font: normal normal var(--labelweight) var(--fontsize) / var(--fontsize) var(--fontfamily2);
  text-align: left;
  /* width: 213px; */
  height: 16px;
}

span.flex_title_register_app {
  opacity: 1;
  height: 19px;
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  color: #6c757d;
  opacity: 1;
  margin-top: 25px;
}

.flex_sub_register_app {
  text-align: var(--regsisterappvaltextalign);
  font: normal normal var(--regsisterappvalfontweight) var(--regsisterappvalfontsize) var(--fontfamily2) !important;
  color: var(--regsisterappvaltextcolor);
  opacity: 1;
}

label.form-label.oneline {
  margin-bottom: 1.5em;
}

.s_batch_image {
  border-radius: 8px;
}

.inner_flex4.s_batch_detail {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #0000004d;
  border-radius: 8px;
  opacity: 1;
  border: 0px !important;
}

/* student confirm page style end */

/* //inputs style  */

.ListViewForm {
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
}

span .material-icons {
  cursor: pointer;
}

.ListViewForm .Inputs::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.ListViewForm .Inputs {
  height: 36px;
  width: 80%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.ListViewForm .Inputs:focus {
  border: 1px solid var(--colorFocusInpus) !important;
  outline: none;
}

.ListViewForm .FilterInputs {
  height: 36px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorLightBlue);
  border-radius: 6px;
  opacity: 0.9;
  /* UI Properties */
  /* background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px; */
  /* opacity: 1; */
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  margin-top: 2px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.ListViewForm .input-group-text {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: none;
  border-right: 1px solid var(--InputBorderColor);
  border-top: 1px solid var(--InputBorderColor);
  border-bottom: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.ListViewForm .Title {
  width: fit-content;
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 43px; */
  margin-top: 20px;
}

.ListViewForm .SaveButton {
  /* width: var(--listButtonWidth); */
  height: var(--listButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* .ListViewForm .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 0%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */
.ListViewForm .SaveButton:hover .material-icons {
  color: var(--colorRed) !important;
}

.ListViewForm .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.ListViewForm .SaveButton:hover a,
.ListViewForm .SaveButton:hover .material-icons {
  color: #ffffff !important;
}

.AdmissionForm .SaveButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
}

.ListViewForm table thead th .thead {
  display: flex;
}

.ListViewForm #example th .material-icons {
  opacity: 0.6;
  color: var(--colorBlue);
  cursor: pointer;
  font-size: 18px;
}

.ListViewForm #example th .active_icons {
  color: #ffffff !important;
  opacity: 1 !important;
  background-color: var(--colorRed) !important;
}

.ListViewForm #example #th {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  white-space: nowrap;

  letter-spacing: 0px;
  color: var(--colorTh);
  position: relative;
  /* opacity: 0.5; */
}

/* 
.ListViewForm #example #td{
  font-family: 'var(--fontfamily2)';
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  font-size: 14px;
  width: 10%;
} */
.ListViewForm .pag span {
  text-align: left;

  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
}

.ListViewForm .pag select {
  background: var(--colorRed) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--colorRed) !important;
  width: 4% !important;
  appearance: auto;
  height: fit-content !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: white !important;
  padding-right: 0px;
}

.ListViewForm .pag select option {
  background-color: none;
}

.ListViewForm .pag .pagenumber {
  text-align: center;

  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 2px;
}

.ListViewForm .pag .prev_button {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  position: relative;
  bottom: 2px;
}

.ListViewForm .pag .prev_button .material-icons {
  color: white;
  vertical-align: text-bottom;
  font-family: "Material Icons";
  font-size: 18px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;

  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.4;
}

/* 
.dropdown {
    position: relative;
    left: 50px;
    top: 50px;
  } */

.ListViewForm .filterBox {
  width: var(--fliterBoxWidth);
  max-height: 245px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  /* position: relative;
    top: -288px;
    left: 70%; */
  position: absolute;
  /* top: 66%;
  left: 66%; */
}

.ListViewForm .filterBox_bb {
  width: var(--fliterBoxWidth);
  max-height: 245px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  /* position: relative;
    top: -288px;
    left: 70%; */
  position: absolute;
  right: 19px;
  /* top: 66%;
  left: 66%; */
}

.ListViewForm .YellowText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorYellow);
  font: normal normal medium;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele);
  opacity: 1;
  text-wrap: nowrap;
  border-radius: 2px;
}

.ListViewForm .GreenText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.ListViewForm .RedText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorLightRed);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.ListViewForm .clearFilter {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  letter-spacing: 0px;
  color: var(--bluebrand);
  opacity: 1;
}

.ListViewForm .FilterButton {
  bottom: 8px;
  width: 135px;
  height: -moz-fit-content;
  height: 40px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* .ListViewForm .FilterButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 0%;
  right: -15px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */
.ListViewForm .FilterButton:hover a,
.ListViewForm .FilterButton:hover .material-icons {
  color: white !important;
}

.ListViewForm .FilterButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(8px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.ListViewForm .SaveButton a {
  text-decoration: none;
  color: var(--colorWhite);
  font-size: var(--fontSizeFourteen);
  text-align: left;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorWhite);
  opacity: 1;
}

.filterBoxOne {
  width: var(--fliterBoxWidth);
  max-height: 332px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 6px;
  opacity: 1;
  z-index: 999;
  position: absolute;
}

.filterBoxTwo {
  width: var(--fliterBoxWidth);
  max-height: 332px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 6px;
  opacity: 1;

  position: absolute;

  z-index: 999;
  overflow-y: scroll;
  overflow-x: clip;
}

/* .form-check-label {
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
} */

.material-icons {
  cursor: pointer;
}

/* .css-159ulw2-MuiPaper-root {
 box-shadow:none !important;
 justify-content: flex-start !important;
}  */

.ListViewForm #example #td {
  font-family: var(--fontfamily2);
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  font-size: var(--fontSizeFourteen);
}

.ListViewForm .form-label {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 1;
}

.ListViewForm .allclear {
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  text-align: left;
  /* font: normal normal medium 14px/19px var(--fontfamily2); */
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  position: relative;
  top: 3px;
  text-decoration: none;
}

.ListViewForm .ipage {
  margin-top: 3px;
}

#paperil ul {
  display: flex !important;
  justify-content: flex-start !important;
  box-shadow: none !important;
}

.widthForm {
  width: 48%;
}

.widthForms {
  width: 95%;
}

.ListViewForm .filterBoxs {
  width: 240px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;
}

.ruppe_color {
  color: var(--color);
}

/* .form-check-label  {
  margin-top: 25px !important;
} */

.dflex {
  display: flex;
  justify-content: end;
}

.dflexcenter {
  display: flex;
  justify-content: end;
}

.dflexendcolu {
  display: flex;
  justify-content: end;
  flex-direction: row;
}

.dflexcolum {
  display: flex;
  flex-direction: row;
}

.dflexbetww {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.dflexbetwwend {
  display: flex;
  justify-content: end;
  flex-direction: row;
}

/* // mobile view  */

@media only screen and (max-width: 576px) {
  .ListViewForm {
    margin-top: 50px;
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
  }

  .dflex {
    display: block;
  }

  .dflexcenter {
    display: flex;
    justify-content: center;
  }

  .dflexbetww {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }

  .dflexcolum {
    display: flex;
    flex-direction: column;
  }

  .dflexendcolu {
    display: flex;
    flex-direction: column;
  }

  .ListViewForm .SaveButton {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ListViewForm .Inputs {
    width: 85%;
  }

  .ListViewForm .pag .prev_button {
    width: 30px;
    height: 30px;
  }

  .TableItemPerpage {
    /* padding: 0; */
    margin-top: 20px;
  }

  .ipage {
    width: 25%;
    margin-top: 0px !important;
  }

  .table-responsive {
    max-width: 100% !important;
    padding-left: 8px !important;
    overflow-y: hidden;
  }

  .ListViewForm .filterBoxs {
    right: 18%;
  }

  .filterBoxTwo {
    right: 18%;
  }

  .filterBoxOne {
    right: 18%;
  }
}

@media (max-width: 767px) {
  .table-responsive {
    max-width: 395px;
    padding-left: 8px !important;
    overflow-y: hidden;
  }

  .ListViewForm .pag select {
    width: 15% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .ListViewForm {
    margin-top: 50px;
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
  }

  .ListViewForm .pag select {
    width: 6% !important;
  }

  .ListViewForm .SaveButton {
    padding: var(--MobilePaddingButton) !important;
  }

  .ListViewForm .filterBoxOne {
    right: 18%;
  }

  .ListViewForm .filterBoxTwo {
    right: 18%;
  }
}

.filter_mmm {
  right: 30px;
}

.st_confirm_outer {
  border: 2px solid var(--color-secondary-border);
  /* margin: 15px 10px; */
  border-radius: var(--border-radius-md);
}

.st_confirm_top_img {
  width: 130px;
  height: 130px;
  border-radius: 8px;
}

.st_confirm_icon {
  width: 20px;
  height: 24px;
}

.st_confirm_icon_color {
  color: var(--color-brand-800);
}

.st_confirm_top {
  /* justify-content: center; */
  align-items: center;
  padding: 20px;
}

.st_confirm_top .title {
  color: var(--color-brand-800);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  margin-left: 10px;
}

.amount_details {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 10px;
  column-gap: 5px;
}

.st_confirm_label {
  font-size: 14px;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
  padding: 0px;
  margin-bottom: 5px;
}

.st_confirm_value {
  font-size: 16px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  padding: 0px;
  margin-bottom: 5px;
}

.st_confirm_bottom {
  border: 2px solid var(--color-secondary-border);
  padding: 10px;
  margin: 30px 10px;
  border-radius: var(--border-radius-md);
  margin-top: 10px;
}

.st_confirm_inner {
  border: 2px solid var(--color-secondary-border);
  padding: 10px;
  /* margin: 30px 10px; */
  border-radius: var(--border-radius-md);
  margin-top: 10px;
}

.st_confirm_link {
  border: none;
  background: none;
  /* border-bottom: 1px solid var(--color-brand-800); */
  font-size: 12px;
  color: var(--color-brand-800);
  font-weight: var(--font-weight-bold);
  text-align: start;
  padding: 0;
}

.st_confirm_link a {
  border: none;
  background: none;
  text-decoration: underline;
  /* border-bottom: 1px solid var(--color-brand-800); */
  font-size: 12px;
  color: var(--color-brand-800);
  font-weight: var(--font-weight-bold);
  text-align: start;
  padding: 0;
}

.inner_box_list_item {
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-brand-800);
}

@media (min-width: 320px) {
  /*xs smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /*sm portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 768px) {

  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  /* .st_confirm_outer {
    margin: 30px 20px;
  } */
  .amount_details {
    margin-top: 0px;
  }

  .st_confirm_inner {
    padding: 20px;
  }
}

@media (min-width: 961px) {
  /*lg tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /*xl big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /*xxl hi-res laptops and desktops */
}