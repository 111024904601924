.applyBatches .css-1fdsijx-ValueContainer {
  border: 0.1px solid;
  height: 42px !important;
}

.Centredisabled {
  cursor: not-allowed;
  opacity: 0.3 !important;
  background: #c0272d !important;
  border: #c0272d !important;
}

.Centredisabled a {
  cursor: not-allowed;
}

.Centredisabled:hover {
  color: #c0272d;
}

/* Hide attachment-link on small screens */
@media screen and (max-width: 576px) {
  .attachment-link {
    display: none;
  }
}