/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  width: 100%;
}

html,
body {
  overflow-x: hidden;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.Purchase {
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
  margin-top: 20px;
  margin-bottom: 100px;
}

.Purchase .subnav {
  width: 100%;
  height: 21px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.Purchase .subnav ul {
  display: flex;
  padding: 0;
}

.Purchase .subnav .custom-item:active {
  color: var(--subnavActiveItem) !important;
}

.Purchase .subnav .custom-item {
  list-style-type: none;
  padding-left: 10px;
  margin-left: 20px;
  width: 31px;
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize) var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.Purchase .subnav .material-icons {
  width: 5px;
  height: 8px;
  font-size: var(--subnavIconsSize);
  margin-top: 1px;
}

.Purchase .Title {
  width: fit-content;
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 8px; */
  margin-top: 8px;
}

.Purchase .Title_ll {
  width: -moz-fit-content;
  /* width: 7%; */
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 37px; */
  margin-top: 8px;
}

.Purchase .progressTitle {
  margin: 30px;
  /* width: 1166px; */
  height: 80px;
  opacity: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //active card */

.Purchase .progressTitle .Card {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Purchase .progressTitle .Card .circle {
  width: 40px;
  height: 40px;
  background: var(--progressCardCircleColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--colorRed); */
  opacity: 1;
  border-radius: 50%;
  color: var(--progressCardCircleTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.Purchase .progressTitle .Card .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.Purchase .progressTitle .Card .Stepborders {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressActivestepborder);
  opacity: 1;
  position: absolute;
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressActivestepborder);
}

.Purchase .progressTitle .Card .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
  margin: 2px;
}

.Purchase .progressTitle .Card .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--labelweight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

/* //inactive card */

.Purchase .progressTitle .Cards {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Purchase .progressTitle .Cards .circle {
  width: 40px;
  height: 40px;
  /* UI Properties */
  background: var(--progressCardCircleInactiveColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--progressCardBorder);
  opacity: 0.2;
  border-radius: 50%;
  color: var(--progressCardCircleInactiveTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.Purchase .progressTitle .Cards .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  /* transform: matrix(0, 1, -1, 0, 0, 0); */
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  /* line-height: 16px; */
  /* left: 250px; */
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.Purchase .progressTitle .Cards .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin: 2px;
  width: max-content;
}

.Purchase .progressTitle .Cards .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--progressCardInactiveWeight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

.Purchase .StepOne .CancelButton,
.Purchase .sectionThree .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Purchase .sectionSix .SaveButtons {
  /* width: var(--purchaseButtonWidthLong); */
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .sectionSix .SaveButtons a {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontsizeThrteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.28px;
  color: #ffffff;
  width: max-content;
  opacity: 1;
  margin-left: 5px;
}

/* .Purchase .sectionSix .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -6%;
  right: 1px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */
/* .Purchase .StepOne .SaveButtons:hover {

  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
} */

/* .Purchase .StepOne .SaveButton,
.Purchase .sectionThree .SaveButton {
  width: var(--purchaseButtonWidthLong);
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
} */
.Purchase .StepOne .SaveButton a,
.Purchase .sectionThree .SaveButton a,
.Purchase .sectionSix .SaveButton a,
.Purchase .sectionFive .SaveButton a,
.Purchase .sectionTwo .SaveButton a,
.Purchase .sectionFour .SaveButton a {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontsizeThrteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  width: max-content;
  margin-left: 4px;
}

/* .Purchase .StepOne .SaveButton:hover::after,
.Purchase .sectionTwo .SaveButton:hover::after,
.Purchase .sectionThree .SaveButton:hover::after,
.Purchase .sectionFour .SaveButton:hover::after,
.Purchase .sectionFive .SaveButton:hover::after,
.Purchase .sectionSix .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -7%;
  right: -23px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.Purchase .StepOne .SaveButton:hover,
.Purchase .sectionTwo .SaveButton:hover,
.Purchase .sectionThree .SaveButton:hover,
.Purchase .sectionFour .SaveButton:hover,
.Purchase .sectionFive .SaveButton:hover,
.Purchase .sectionSix .SaveButton:hover {
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .StepOne .SaveButton:hover a,
.Purchase .sectionTwo .SaveButton:hover a,
.Purchase .sectionThree .SaveButton:hover a,
.Purchase .sectionFour .SaveButton:hover a,
.Purchase .sectionFive .SaveButton:hover a,
.Purchase .sectionSix .SaveButton:hover a {
  color: var(--saveButtonTextColor);
}

.Purchase .StepOne .SaveButton:hover img,
.Purchase .sectionTwo .SaveButton:hover img,
.Purchase .sectionThree .SaveButton:hover img,
.Purchase .sectionFour .SaveButton:hover img,
.Purchase .sectionFive .SaveButton:hover img,
.Purchase .sectionSix .SaveButton:hover img {
  color: var(--saveButtonTextColor);
}

.Purchase .StepOne .SaveButton:hover .material-icons,
.Purchase .sectionTwo .SaveButton:hover .material-icons,
.Purchase .sectionThree .SaveButton:hover .material-icons,
.Purchase .sectionFour .SaveButton:hover .material-icons,
.Purchase .sectionFive .SaveButton:hover .material-icons,
.Purchase .sectionSix .SaveButton:hover .material-icons {
  color: var(--saveButtonTextColor);
}

.Purchase .SaveButton_maa {
  /* width: 225px; */
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .SaveButton_maa a {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontsizeThrteen);
  width: max-content;
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  margin-left: 5px;
}

/* .Purchase .sectionSix .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -6%;
  right: 1px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */
/* .Purchase .StepOne .SaveButtons:hover {

  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
} */

/* .Purchase .StepOne .SaveButton,
.Purchase .sectionThree .SaveButton {
  width: var(--purchaseButtonWidthLong);
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
} */
.Purchase .SaveButton_maa a {
  text-align: left;
  width: max-content;
  font-family: var(--fontfamily2);
  font-size: var(--fontsizeThrteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  margin-left: 4px;
}

/* .Purchase .StepOne .SaveButton:hover::after,
.Purchase .sectionTwo .SaveButton:hover::after,
.Purchase .sectionThree .SaveButton:hover::after,
.Purchase .sectionFour .SaveButton:hover::after,
.Purchase .sectionFive .SaveButton:hover::after,
.Purchase .sectionSix .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -7%;
  right: -23px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.Purchase .SaveButton_maa:hover {
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  /* padding: var(--saveButtonPaddingHover); */
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .SaveButton_maa:hover a {
  color: var(--saveButtonTextColor);
}

.Purchase .SaveButton_maa:hover img {
  color: var(--saveButtonTextColor);
}

.Purchase .SaveButton_maa:hover .material-icons {
  color: var(--saveButtonTextColor);
}

.Purchase .StepOne .SaveButton_Prev,
.Purchase .sectionTwo .SaveButton_Prev,
.Purchase .sectionThree .SaveButton_Prev,
.Purchase .sectionFour .SaveButton_Prev,
.Purchase .sectionFive .SaveButton_Prev,
.Purchase .sectionSix .SaveButton_Prev {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  /* UI Properties */
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .StepOne .SaveButton_Prev:hover::before,
.Purchase .sectionTwo .SaveButton_Prev:hover::before,
.Purchase .sectionThree .SaveButton_Prev:hover::before,
.Purchase .sectionFour .SaveButton_Prev:hover::before,
.Purchase .sectionFive .SaveButton_Prev:hover::before,
.Purchase .sectionSix .SaveButton_Prev:hover::before {
  content: var(--saveButtonBeforIcon);
  position: absolute;
  top: 3%;
  left: 0px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-left: 30px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.Purchase .StepOne .SaveButton_Prev:hover,
.Purchase .sectionTwo .SaveButton_Prev:hover,
.Purchase .sectionThree .SaveButton_Prev:hover,
.Purchase .sectionFour .SaveButton_Prev:hover,
.Purchase .sectionFive .SaveButton_Prev:hover,
.Purchase .sectionSix .SaveButton_Prev:hover {
  height: 40px;
  /* UI Properties */
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);

  padding: var(--saveButtonPaddingHover);
  transform: translateX(12px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Purchase .radioGroup .formCheck {
  margin-bottom: 20px;
  margin-left: 19px;
}

.Purchase .radioGroup .formCheck .checkbox {
  width: var(--inputCheckboxwidth);
  height: var(--inputCheckboxheight);
  /* UI Properties */
  background: var(--inputcheckboxBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inputcheckboxBordercolor);
  opacity: 0.5;
}

.Purchase .radioGroup .formCheck .checkbox-label {
  height: 19px;
  /* UI Properties */
  text-align: var(--checkboxlabelalign);
  font: normal normal var(--checkboxlabelweight) var(--checkboxlabelSize) var(--fontfamily2) !important;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
  margin-right: 17px;
  vertical-align: super;
}

.Purchase .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.Purchase .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.Purchase .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .StepOne .box .forms .InputsFile,
.Purchase .sectionTwo .box .forms .InputsFile,
.Purchase .sectionThree .box .forms .InputsFile,
.Purchase .sectionFour .box .forms .InputsFile,
.Purchase .sectionFive .box .forms .InputsFile,
.Purchase .sectionSix .box .forms .InputsFile,
.Purchase .sectionPhoto .box .forms .InputsFile {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionTwo .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.Purchase .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.Purchase .sectionTwo .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  line-height: var(--LineHeight);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionTwo .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  line-height: var(--LineHeight);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* ///section three */

.Purchase .sectionThree .box {
  /* width: 1280px; */
  height: 220px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.Purchase .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */

  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionThree .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  line-height: var(--LineHeight);
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* //section four 
 */

.Purchase .sectionFour .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.Purchase .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 6px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.Purchase .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  line-height: var(--LineHeight);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionFour .box .final {
  width: 169px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionFour .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Purchase .sectionFour .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //sectio five */

.Purchase .sectionFive .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.Purchase .sectionFive .box .AddMore,
.Purchase .sectionTwo .box .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer;
  margin-top: 16px;
}

.Purchase .sectionFive .box .AddMore .material-icons,
.Purchase .sectionTwo .box .AddMore .material-icons {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.Purchase .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.Purchase .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
}

.Purchase .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.Purchase .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

.Purchase .sectionFive .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionFive .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Purchase .sectionFive .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Purchase .sectionTwo .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Purchase .sectionTwo .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //section Six */

.Purchase .sectionSix .box {
  /* width: 1280px; */
  height: fit-content;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.Purchase .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Purchase .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.Purchase .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.Purchase .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.Purchase .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin-top: 25px;
  margin-left: 20px;
}

.Purchase .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.Purchase .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

.Purchase .sectionSix .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Purchase .sectionSix .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Purchase .sectionSix .SaveButton {
  /* width: var(--purchaseButtonWidthLong); */
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Purchase .sectionSix .Checke {
  position: relative;
  bottom: 28px;
}

.BatchImage img {
  width: var(--batchImgWidth);
  height: var(--batchImgHeight);
  border-radius: var(--batchImgradius);
}

.BatchImage .title {
  /* height: 21px; */
  text-align: var(--batchTextalign);
  font: normal normal var(--batchtextweight) var(--batchTitlesize) var(--fontfamily2);
  letter-spacing: var(--batchtextletterspacing);
  color: var(--batchtextcolor);
  opacity: 1;
}

.BatchImage .subtitle {
  /* height: 16px; */
  width: max-content;
  text-align: var(--batchTextalign);
  font-size: var(--batchsubsize);
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
}

.IconsImage {
  list-style-type: none;
  color: var(--iconsTextcolor);
  display: flex;
  align-items: flex-start;
}

.IconsImage .title {
  text-align: var(--iconstextalign);
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: var(--iconstextopacity);
  margin: 1px;
  font-size: var(--iconstextsize);
  font-family: var(--fontfamily2);
}

.IconsImage .subtitle {
  text-align: left;
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: 1;
  margin: 0;
  font-size: var(--iconssubtextsize);
  font-family: var(--fontfamily2);
}

.Purchase .errors {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
}

.Purchase .sectionTwo .box .forms .Inputs:focus-visible,
.Purchase .sectionThree .box .forms .Inputs:focus-visible,
.Purchase .sectionFour .box .forms .Inputs:focus-visible,
.Purchase .sectionFive .box .forms .Inputs:focus-visible,
.Purchase .sectionSix .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  font-family: var(--fontfamily2);
  color: black;
}

.Purchase .sectionTwo .box .forms .Inputs::placeholder .Purchase .StepOne .box .forms .Inputs::placeholder .Purchase .sectionThree .box .forms .Inputs::placeholder .Purchase .sectionFour .box .forms .Inputs::placeholder .Purchase .sectionFive .box .forms .Inputs::placeholder .Purchase .sectionSix .box .forms .Inputs::placeholder {
  text-align: center;
  font: normal 14px/19px !important;
  font-family: var(--fontfamily2) !important;
  letter-spacing: 2px !important;
  outline: none;
  color: #041134 !important;
  opacity: 0.6;
  font-family: var(--fontfamily2);
}

.display_none {
  display: "";
}

/* //small devies */
/* @media only screen and (min-width: 576px) and (max-width: 768px) {

    .display_none{

        display: none;
    }

    .display_none_Stepborder{

        display: none;
    }
    
 } */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet devices
  .display_none_Stepborder {
    display: "";
  } */

  .Purchase #radiobuttons {
    flex-direction: column;
  }

  .Purchase .sectionSix .CancelButton {
    font-size: var(--fontSizeFourteen) !important;
    margin-right: 6px !important;
  }

  .Purchase .progressTitle .Card .cTitle,
  .Purchase .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .Purchase .progressTitle .Card .cSubtitle,
  .Purchase .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }

  .Purchase .progressTitle .Card .Stepborders {
    width: 32px;
    height: 0px;
    border: 1px solid var(--progressActivestepborder);
    opacity: 1;
    position: absolute;
    margin-left: 105px;
    margin-top: 18px;
    border-bottom: 1px solid var(--progressActivestepborder);
  }

  .Purchase .progressTitle .Card .Stepborder,
  .Purchase .progressTitle .Cards .Stepborder {
    width: 24px !important;

    margin-left: 102px !important;
  }

  .Purchase .sectionThree .box .forms label {
    height: 28px;
  }

  .Purchase .StepOne .SaveButton_Prev,
  .Purchase .sectionTwo .SaveButton_Prev,
  .Purchase .sectionThree .SaveButton_Prev,
  .Purchase .sectionFour .SaveButton_Prev,
  .Purchase .sectionFive .SaveButton_Prev,
  .Purchase .sectionSix .SaveButton_Prev {
    width: 100% !important;
    font-size: 11px !important;
  }

  .IconsImage .title {
    font-size: 11px !important;
  }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
  /* styles here */
}

.card .BatchImage {
  text-align: start !important;
}

.IconsImage .material-icons {
  font-size: 22px;
  line-height: 1.4 !important;
  margin-top: 10px;
}

/* .Purchase .SaveButtons {

    width: 10%;
    height: 20px;
    background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
    border: 2px solid var(--saveButtonBorder);
    color: var(--saveButtonTextColor);
    padding: var(--saveButtonPadding);
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.Purchase .ProfilePhoto {
  width: 250px;
  height: 250px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  /* UI Properties */
  background: #02041134 0% 0% no-repeat padding-box;
  border: 1px solid #02041134;
  border-radius: 125px;
  /* opacity: 0.2; */
  margin-top: 45px;
  margin-bottom: 36px;
  transform: rotate(0deg);
}

.Purchase #text {
  position: relative;
  bottom: 50%;
  left: 32%;
  font-weight: var(--loginH1fontweight);
  font-family: var(--fontfamily2);
}

.Purchase .profileText {
  text-align: left;
  font: normal normal var(--loginH1fontweight) 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
}

.Purchase .warningOne {
  text-align: justify;
  font: normal normal var(--normalFontWeight) 12px/20px var(--fontfamily2);
  letter-spacing: 0px;
  color: #000000;
}

.Purchase .warningTwo {
  text-align: justify;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: 12px;
  line-height: 20px;
}

/* //sectionprofile */

.Purchase #border {
  text-align: left;
  font: normal normal medium 18px/26px var(--fontfamily2);
  border: 1px solid #041134 !important;
  letter-spacing: 0.07px;
  color: #041134;
  opacity: 1;
  height: 16px;
  margin-top: 4px;
}

.Purchase .commanInfo #border {
  margin-left: 8px;
}

.Purchase .commanInfo {
  display: flex;
  /* margin-left: auto; */
}

.Purchase .commanInfo li {
  list-style-type: none;
  position: relative;
  left: 100px;
}

.Purchase .commanInfo #number small {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 12px/16px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
}

.Purchase .commanInfo #number #tex {
  text-align: left;
  font: normal normal bold 12px/16px var(--fontfamily2);
  letter-spacing: 0.48px;
  color: #041134;
  opacity: 1;
}

#profilephotos {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: grey;
  margin-top: 9px;
}

#profilephotos img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

/* .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
    background: var(--colorRed) !important;
    border: 2px solid var(--colorRed) !important;  } */

.widthForm {
  width: 49%;
}

.widthForms {
  /* width: 95%; */
  width: 100%;
}

#Verifycheckbox {
  text-align: left;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
}

/* .stepSeven{
    
    width: 600px;

} */
.ptitle {
  text-align: center;
  letter-spacing: 0.18px;
  color: #041134;
  opacity: 1;
  font-weight: bold;
}

.psubtitle {
  /* width: 650px; */
  /* height: 21px; */
  margin: 0px 20px;
  text-align: center;
  letter-spacing: NaNpx;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.psubtitle_cash {
  width: 100%;
  /* height: 21px; */
  text-align: center;
  letter-spacing: NaNpx;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.sectionSeven {
  margin-top: 81px;
  margin-bottom: 120px;
}

.Purchase .ReviewLabel {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Purchase .ReviewLabels {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: rgb(220, 53, 69);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Purchase .ReviewLabelss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

/* .download_icon {
  color: rgb(220, 53, 69);
} */

.Purchase a {
  text-decoration: none;
}

/* 
.radiobutton {
    display: inline-block;
    appearance: none;
    border: 1px solid #ccc;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    outline: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.radiobutton:checked:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radiobutton:focus {
    border-color: #999;
}

.radiobutton:checked {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
} */

.Purchase .SaveButtons {
  /* width: var(--facultySaveButton); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* 
.Purchase .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.Purchase .SaveButtons:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Purchase .SaveButtons:hover a,
.Purchase .SaveButtons:hover .material-icons {
  color: #ffffff;
}

.MakeBtn {
  /* width: var(--makebtnwidth); */
  height: var(--PurchaseButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: 11px 10px 11px 10px;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.MakeBtn a {
  text-align: left;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontsizeThrteen);
  width: max-content;
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  margin-left: 3px;
}

/* .MakeBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;

  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  
  background-color: transparent;
  color: #FFFFFF;
  border-radius: 3px;
} */

.MakeBtn:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: 11px 10px 11px 10px;
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.MakeBtn:hover a {
  color: white;
}

.MakeBtn:hover .material-icons {
  color: white;
}

.Purchase .calViewBtn {
  /* width: fit-content; */
  height: 36px;
  /* UI Properties */
  text-align: left;

  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: var(--fontsizetwevele);
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 1px;
  font-weight: var(--boldtextWeight);
}

.Purchase .calViewBtn a {
  text-align: inherit;
  font-weight: bold;
  font-size: 12px;
  width: max-content;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.Purchase .calViewBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: -8%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.Purchase .calViewBtn:hover a,
.Purchase .calViewBtn:hover .material-icons {
  color: var(--colorRed) !important;
}

.Purchase .calViewBtn:hover {
  /* width: 110px; */
  height: 36px;
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Purchase .PrevBtns {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  margin-left: 5px;
  margin-top: 1px;
  height: 33px;
  color: white;
}

.Purchase .PrevBtns:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
}

.subTet {
  text-align: left;
  text-decoration: underline;
  font-family: var(--fontfamily2);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: underline !important;
  letter-spacing: 0.24px;
  color: var(--colorRed);
  opacity: 1;
}

.purchase_dot_chat {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 2px;
}

@media only screen and (max-width: 576px) {
  .PyNowBtn {
    width: 115px;
  }

  .psubtitle_cash {
    width: 100%;
    height: initial !important;
  }

  .widthForm {
    width: 100% !important;
  }

  .Purchase {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }

  .Purchase .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    /* left: 20px !important; */
    height: 36px !important;
    top: 10px !important;
  }

  .widthForms {
    width: 100% !important;
  }

  .display_none {
    display: none;
  }

  .Purchase .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* .display_none_Stepborder {
    display: none;
  } */

  .Purchase .heading {
    flex-direction: column;
  }

  /* .heading .smButton {
    flex-direction: column;
    margin: 0px !important;
  } */

  .heading button {
    margin-top: 5px;
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .Purchase .StepOne .SaveButton,
  .Purchase .sectionTwo .SaveButton,
  .Purchase .sectionThree .SaveButton,
  .Purchase .sectionFour .SaveButton,
  .Purchase .sectionFive .SaveButton,
  .Purchase .sectionSix .SaveButton,
  .Purchase .sectionSix .SaveButtons {
    width: 100%;
    margin-top: 20px;
  }

  .MakeBtn {
    width: 100%;
    margin-top: 20px;
  }

  .Purchase .SaveButton_maa {
    width: 100%;
    padding: var(--MobilePaddingButton) !important;
  }

  .Purchase .StepOne .SaveButton_Prev,
  .Purchase .sectionTwo .SaveButton_Prev,
  .Purchase .sectionThree .SaveButton_Prev,
  .Purchase .sectionFour .SaveButton_Prev,
  .Purchase .sectionFive .SaveButton_Prev,
  .Purchase .sectionSix .SaveButton_Prev {
    width: 100%;
    margin-top: 20px;
  }

  .Purchase .StepOne .box,
  .Purchase .sectionTwo .box,
  .Purchase .sectionThree .box,
  .Purchase .sectionFour .box,
  .Purchase .sectionFive .box,
  .Purchase .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .Purchase .StepOne .box .forms label,
  .Purchase .sectionTwo .box .forms label,
  .Purchase .sectionThree .box .forms label,
  .Purchase .sectionFour .box .forms label,
  .Purchase .sectionFive .box .forms label,
  .Purchase .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--labelfont) !important;
  }

  .Purchase .StepOne .box .forms .Inputs,
  .Purchase .sectionTwo .box .forms .Inputs,
  .Purchase .sectionThree .box .forms .Inputs,
  .Purchase .sectionFour .box .forms .Inputs,
  .Purchase .sectionFive .box .forms .Inputs,
  .Purchase .sectionSix .box .forms .Inputs {
    margin-left: 0px !important;
  }

  .Purchase .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }

  .Purchase .StepOne .box .forms .InputsFile,
  .Purchase .sectionTwo .box .forms .InputsFile,
  .Purchase .sectionThree .box .forms .InputsFile,
  .Purchase .sectionFour .box .forms .InputsFile,
  .Purchase .sectionFive .box .forms .InputsFile,
  .Purchase .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
  }

  .Purchase .sectionTwo .box,
  .Purchase .sectionThree .box,
  .Purchase .sectionFour .box,
  .Purchase .sectionFive .box,
  .Purchase .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .Purchase .subnav .custom-item {
    margin-left: 0px;
    width: 100%;
  }

  .Purchase .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
  }

  .Purchase .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
  }

  .Purchase .sectionSix .box .StepTitle {
    margin-left: 5px;
  }

  .Purchase .ReviewLabel {
    margin-left: 0px !important;
  }

  .Purchase .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .Purchase #radiobuttons {
    flex-direction: column;
  }

  .Purchase .subnav {
    display: none;
  }

  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }

  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .Purchase .subnav {
    display: none;
  }
}

.PyNowBtn {
  background: #c1393d 0% 0% no-repeat padding-box;
  border: 2px solid #c1393d;
  border-radius: 3px;
  opacity: 1;
}

.PyNowBtnLink {
  color: white !important;
  text-decoration: none;
}

.PyNowBtnOP {
  background: #c1393d 0% 0% no-repeat padding-box;
  border: 2px solid #c1393d;
  border-radius: 3px;
  opacity: 0.6;
}

.PyNowBtnLinkOP {
  color: white !important;
  text-decoration: none;
}

.PyNowBtn .material-icons {
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
  opacity: 1;
  color: white;
}

.PyNowBtnOP .material-icons {
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
  opacity: 1;
  color: white;
}

.current_rip {
  font-size: 22px;
  line-height: 1.4 !important;
  margin-top: 10px;
  color: var(--colorRed);
}

.MuiTableContainer-root #Thead,
.MuiTableCell-head {
  border-bottom: 1px solid var(--colorRed) !important;
}

.paidcolor {
  /* width: 78px; */
  height: 25px;
  /* UI Properties */
  background: #dc4c64 0% 0% no-repeat padding-box;
  border: 1px solid #dc4c64;
  border-radius: 3px;
  opacity: 1;
}

.paidcolor a {
  text-align: left;
  font-size: 12px;
  font-family: var(--fontfamily2);
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.paidcolors {
  /* width: 78px; */
  height: 25px;
  /* UI Properties */
  background: #4caf50 0% 0% no-repeat padding-box;
  border: 1px solid #4caf50;
  border-radius: 3px;
  opacity: 1;
}

.paidcolors a {
  text-align: left;
  font-size: 12px;
  font-family: var(--fontfamily2);
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ReViewTableInfo {
  height: 49px;
  background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: normal;
  line-height: 26px;
  color: var(--colorBlue);
  padding: 14px;
}

.ReViewTableInfo_note {
  /* height: 49px; */
  background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: normal;
  line-height: 26px;
  color: var(--colorBlue);
  padding: 14px;
}

.SignatureFile {
  /* margin: 20px;
  width: 95%;
  height: 43px; */
  max-height: 60px;
  margin: 20px;
  object-fit: contain;
}

.SignatureFileChange {
  /* margin: 20px;
  width: 95%;
  height: 43px; */
  max-height: 60px;
  margin: 20px;
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .BatchImage img {
    width: 100px !important;
    height: 100px !important;
    /* width: var(--batchImgWidth); */
    /* height: var(--batchImgHeight); */
    border-radius: var(--batchImgradius);
  }
}