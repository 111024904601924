@import url('../../../../Themes/themes.css');

.form-group.col-md-6 {
    text-align: left;
    /* margin-bottom: 2em; */
    padding: 2em 2em 0em 3em;
}
label {
    font-weight: 500;
}
a.Test_link,a.course_cancel {
    color: white;
    text-decoration: none;
}
#search-btn {
    margin-left: 0em;
    margin-top: -1.6em;
    /* margin-right: -22em; */
    font-size: 2.5em;
}
.card-body.batchupdate
{
    box-shadow: 0px 0px 3px #0000004D;
    border-radius: 8px;
}
img.batchupdate {
    border-radius: 8px;
}
.batchupdate_course_name,.batchupdate_batch_name
{
    color: var(--color);
    text-align: center;
font: var(--font) bold 16px/21px var(--fontfamily2);
letter-spacing: 0.32px;
opacity: 1;
margin-top:1em;
font-family: var(--fontfamily2), Bold;
font-size: var(--subtitlefontsize);
}
.batchupdate_icon
{
    margin-top: 0em;
    margin-bottom: -2em;
    color: var(--color);
    margin-right: 6.5em;


}


/* updateBatch style */


input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=number]::placeholder,
input[type=file]::placeholder {

    font-family: var(--fontfamily2);


}


.updateBatch .CancelButton {


    height: 19px;
    text-align: var(--cancelbuttonAlign);
    font-family: var(--cancelFontFamily);
    font-size: var(--cancelButtonFontSize);
    font-weight: var(--cancelFontWeight);
    color: var(--cancelButtonColor);
    letter-spacing: 0px;
    opacity: 1;
    text-decoration: none;
    margin-right: 30px;
    margin-top: 10px;
}


.updateBatch .SaveButton {

    width: 100%;
    height: var(--saveButtonHeight);
    background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
    border: 2px solid var(--saveButtonBorder);
    color: var(--saveButtonTextColor);
    padding: var(--saveButtonPadding);
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontfamily2);
    text-decoration: none;
}

.updateBatch .SaveButton:hover::after
 {
    content: var(--saveButtonAfterIcon);
    position: absolute;
    top: 3%;
    right: -20px;
    font-size: var(--savebuttonIconsSize);
    font-weight: var(--saveButtonfontweight);
    padding: var(--saveButtonPaddingHover);
    font-family: var(--fontfamily2);
    margin-right: 20px;
    background-color: transparent;
    color: var(--saveButtoniconColor);
    border-radius: 3px;
}

.updateBatch .SaveButton:hover{

    height: var(--saveButtonHeight);
    background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
    border: 2px solid var(--saveButtonHoverBorderColor);
    border-radius: 6px;
    opacity: 1;
    color: var(--saveButtonHoverTextColor);
    padding: var(--saveButtonPaddingHover);
    transform: translateX(20px);
    transition: 0.8s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--fontfamily2);


}



.updateBatch .StepOne .box {
    /* width: 1280px; */
    height: fit-content;
    /* UI Properties */
    background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--fieldTitleBorder);
    border-radius: 8px;
    opacity: 1;
    margin: 30px;

}


.updateBatch .StepOne .box .StepTitle {

    width: fit-content;
    height: 21px;
    /* UI Properties */
    text-align: var(--fieldStepTitlecolorAlign);
    font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
    letter-spacing: 0.32px;
    color: var(--fieldStepTitlecolor);
    opacity: 1;
    position: absolute;
    background: white;
    margin-top: -12px;
    margin-left: 10px;
    padding: 0 10px;
}

.updateBatch .StepOne .box .forms {
    display: flex;
    flex-direction: column;
}

.updateBatch .StepOne .box .forms label {

    height: 19px;
    /* UI Properties */
    text-align: var(--labelalign);
    font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
    letter-spacing: 0.28px;
    color: var(--labelcolor);
    opacity: 1;
    margin-top: 28px;
    margin-left: 20px;

}

.updateBatch .StepOne .box .forms .Inputs:focus-visible {
    background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--focusInputBordercolor);
    border-radius: 6px;
    opacity: 1;
    padding: var(--focusInputpadding);
    outline: none;
    margin-top: 10px;
    color: var(--focusInputcolor);
    font-family: var(--fontfamily2);
}
.form-control{

    background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    opacity: 1;
    /* margin-left: 20px; */
    padding: var(--Inputpadding);
    font-family: var(--fontfamily2);

}


.updateBatch .StepOne .box .forms .Inputs {

    height: 36px;
    /* UI Properties */
    background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    opacity: 1;
    margin-left: 20px;
    padding: var(--Inputpadding);
    margin-top: 10px;
    font-family: var(--fontfamily2);
}


.updateBatch .sectionSix .box .forms .InputsMutiple{

    /* height: 36px; */
    /* UI Properties */
    background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    opacity: 1;
    margin-left: 20px;
    padding: var(--Inputpadding);
    margin-top: 10px;
    font-family: var(--fontfamily2);

}

.updateBatch .StepOne .box .forms .InputsFile {

    height: var(--InputHeight);
    /* UI Properties */
    background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    opacity: 0.8;
    margin-left: 20px;
    padding: var(--InputFilepadding);
    margin-top: 10px;
    font-family: var(--fontfamily2);
}


.updateBatch .error {

    font-size: var(--errortextsize);
    color: var(--errortextcolor);
    /* margin: 15px; */
    text-align: var(--errortextalign);
    margin-top: 5px;
    font-weight: var(--errortextweight);
    margin-bottom: 0;
}



.updateBatch .Title {

    width: fit-content;
    height: 26px;
    /* UI Properties */
    font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
    letter-spacing: 0.2px;
    color: var(--Titlecolor);
    opacity: 1;
    
}

.updateBatch .SaveButton_disabled {
    width: 100%;
    height: var(--saveButtonHeight);
    background: #b74144d4 0% 0% no-repeat padding-box;
    border: 2px solid #b74144d4;
    color: var(--saveButtonTextColor);
    padding: var(--saveButtonPadding);
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontfamily2);
    text-decoration: none;
}

.updateBatch .button{

    background: transparent;
    color: var(--colorRed);
    width: 37px;
    height: 31px;
    border: none;


}
/* .updateBatch .material-icons {
    position: absolute;
    top: 41%;
    left: 3%;
    transform: translateY(-50%);
  } */

@media only screen and (max-width: 576px) {
    .updateBatch .StepOne .box {
           margin: 11px !important;
       }
       .updateBatch .SaveButton {
              margin-bottom: 20px;
              margin-top: 20px;
       }
    }