@import "material-icons/iconfont/material-icons.css";

.HeaderPage .head {
    /* width: 1366px;
height: 30px; */
    /* UI Properties */
    background: var(--headerBack) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.HeaderPage .head #icon {
    width: 18px;
    height: 14px;
    /* UI Properties */
    /* background: var(--colorRed) 0% 0% no-repeat padding-box; */
    /* background: var(--colorRed) 0% 0% no-repeat padding-box; */
    opacity: 1;
    margin-top: 5px;
}

.HeaderPage .head .material-icons {
    font-size: var(--fontSizeTwenty);
    color: var(--colorRed);
}

.HeaderPage .head #number {
    margin-left: 15px;
    margin-top: 6px;
    width: 80px;
    height: 17px;
    text-align: left;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontsizeelveen);
    font-family: var(--fontfamily2);
    letter-spacing: 0.48px;
    color: var(--colorBlue);
    opacity: 1;
}

.HeaderPage .head #border {
    margin-left: 14px;
    height: 25px;
    margin-top: 2px;
    font-weight: 500;
}

.HeaderPage .icons {
    width: 20px;
    text-align: center;
    height: 20px;

    background: var(--colorRed) 0% 0% no-repeat padding-box;
    border: 1px solid var(--colorRed);
    border-radius: 1px;
    opacity: 1;
    color: #ffffff;
    margin-left: 19px !important;
    margin-top: -4px;
}

.HeaderPage .fa-facebook-f:before {
    content: "\f39e";
    font-size: 12px;
}

.HeaderPage .fa-instagram:before {
    content: "\f16d";
    font-size: 12px;
}

.HeaderPage .fa-twitter:before {
    content: "\f099";
    font-size: 12px;
}

.HeaderPage .fa-linkedin:before {
    content: "\f08c";
    font-size: 12px;
}

.HeaderPage .fa-whatsapp:before {
    content: "\f232";
    font-size: 12px;
}

.HeaderPage .fa-youtube:before {
    content: "\f167";
    font-size: 12px;
}

.HeaderPage .brand {
    width: 247.5px !important;
    display: flex;
    height: 80px;
    background: var(--colorRed);
    /* border-radius: 10%; */
    transform: translate(0px, 0px);
    border-top-right-radius: 29px;
}

.HeaderPage .brand img {
    /* width: 58px; */
    height: 58px;
    opacity: 1;
    margin-top: 11px;
    margin-left: 20px;
}

.HeaderPage .bg-danger-style {
    background: var(--colorRed) 0% 0% no-repeat padding-box;
}

.HeaderPage .title {
    width: 86px;
    height: 37px;
    /* UI Properties */
    text-align: center;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizeTwenty);
    font-family: var(--fontfamily2);

    letter-spacing: 2.8px;
    color: #ffffff;
    opacity: 1;
}

.AdmissionForm .commanInfo #number #tex a {
    color: var(--colorBlue);
}

.AdmissionForm .tcttc a {
    color: var(--colorBlue);
}

.HeaderPage .Subtitle {
    /* width: 144px;
    height: 29px; */
    /* UI Properties */
    text-align: center;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizeTwentyTwo);
    font-family: var(--fontfamily2);
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.HeaderPage .contents {
    display: flex;
    flex-direction: column;
    height: 40px;
}

.HeaderPage .content .firstHeader {
    width: 98%;
    height: 40px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
}

.HeaderPage .content .firstHeader ul {
    display: flex;
    justify-content: space-between;
}

.HeaderPage .content .firstHeader ul li {
    list-style-type: none;
    margin: 8px;
    opacity: 1;
    font-size: var(--fontSizeFourteen);
}

.HeaderPage .content .firstHeader ul .border {
    text-align: left;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontsizeFiveteen);
    font-family: var(--fontfamily2);

    border: 1px solid var(--colorBlue) !important;
    letter-spacing: 0.07px;
    color: var(--colorBlue);
    opacity: 1;
    height: 16px;
    margin-top: 12px;
}

.HeaderPage .content .firstHeader ul li .icon {
    width: 20px;
    height: 20px;
    font-size: var(--fontSizeeighteen);
    background: var(--colorRed) 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    border-radius: 50%;
    position: relative;
    top: 0px;
    padding: 1px;
    right: 3px;
    vertical-align: top;
}

.HeaderPage .content .secondHeader {
    width: 100%;
    height: 40px;
    /* UI Properties */
    background: var(--colorBlue) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.HeaderPage .content .secondHeader ul {
    display: flex;
    justify-content: space-evenly;
}

.HeaderPage .content .secondHeader ul li {
    list-style-type: none;
    opacity: 1;
    color: white;
    font-size: 15px;
    margin: 8px;
}

.HeaderPage .content .secondHeader ul .border {
    text-align: left;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizeeighteen);
    font-family: var(--fontfamily2);
    border: 1px solid #ffffff !important;
    letter-spacing: 0.07px;
    color: var(--colorBlue);
    opacity: 1;
    height: 16px;
    margin-top: 12px;
}

.HeaderPage .content .secondHeader ul .buttonStyles {
    height: 26px;
    padding: 1px;
    padding-left: 9px;
    padding-right: 9px;
    background: var(--colorRed) 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 17px;
    opacity: 1;
}

.HeaderPage .content .secondHeader ul .buttonStyle {
    width: 26px;
    height: 26px;
    padding: 1px;
    padding-left: 9px;
    padding-right: 9px;
    /* background: var(--colorRed) 0% 0% no-repeat padding-box; */
    background-image: url("https://www.w3schools.com/howto/img_avatar2.png") !important;
    background-size: cover !important;
    border: 1px solid #ffffff;
    border-radius: 50%;

    opacity: 1;
}

/* .HeaderPage .content .secondHeader ul .buttonStyle #avatarDropdown{
        width: 26px;
        height: 26px;
        border-radius: 50% !important;
  background-image: url("https://www.w3schools.com/howto/img_avatar2.png");
  background-size: cover;
    } */
.HeaderPage .content .secondHeader ul li .icon {
    width: 18px;
    height: 18px;
    font-size: var(--fontSizeeighteen);
    background: var(--unnamed-color-c1393d) 0% 0% no-repeat padding-box;
    background: var(--colorRed) 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    border-radius: 50%;
    position: relative;
    top: 4px;
    right: 5px;
}

/* .HeaderPage .avartar_image{

        width: 50px;
        height: 50px;
        background-color: red;
    } */

.HeaderPage ul li #menulist {
    background: rgb(255 255 255);
    visibility: hidden;
    opacity: 0;
    min-width: 10rem;
    padding: 10px;
    position: absolute;
    transition: all 0.7s ease;
    margin-top: 1.5rem;
    display: none;
    right: 7px;
}

.HeaderPage ul li #menulist p a {
    width: max-content;
    color: var(--colorRed);
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 10px;
}

.HeaderPage ul li #menulist p:hover {
    background-color: var(--bluebrand);
    color: white !important;
    padding: 1px;
}

.HeaderPage ul li #menulist p:hover a {
    color: white;
    /* padding: 8px; */
    width: 100%;
}

.HeaderPage ul li:hover>#menulist,
.HeaderPage ul li #menulist:hover {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    display: block !important;
}

.HeaderPage .content .secondHeader ul li .buttonStyle:hover {
    background-color: none !important;
}

.HeaderPage .content .secondHeader ul li:hover {
    background: var(--colorRed) 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 18px;
    opacity: 1;
    padding-right: 8px;
    padding-left: 8px;
}

.HeaderPage .content .firstHeader ul li:hover {
    background: var(--colorRed) 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 18px;
    opacity: 1;
    color: white;
    padding-right: 8px;
    padding-left: 8px;
}

/* Set the background color and font color of the dropdown toggle */

/* Style the dropdown menu */
.dropdown-menu {
    /* background-color: #fff; */
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 0;
    display: none;
    visibility: hidden;
    margin-top: 3px;
    /* width: 200px; */
}

.dropdown-toggle:hover .dropdown-menu {
    visibility: visible;
    display: block !important;
}

.dropdown-link:hover+.dropdown-menu,
.dropdown-menu:hover {
    visibility: visible;
    display: block !important;
}

/* Style the menu items */
.dropdown-menu li a {
    display: block;
    padding: 10px;
    color: var(--colorBlue);
    font-family: var(--fontfamily2);
    font-size: var(--fontSizeFourteen);
    text-decoration: none;
}

.HeaderPage .content .secondHeader ul li .dropdown-menu li {
    color: var(--colorRed);
    background-color: white !important;
    text-decoration: none;
    display: block !important;
    font-size: 13px;
    margin: 0px;
}

/* Style the hover state of the menu items */
.HeaderPage .content .secondHeader ul li .dropdown-menu li:hover {
    width: 96%;
    background: var(--bluebrand) !important;
    margin: 3px;
    border-radius: 0;
}

.HeaderPage .content .secondHeader ul li .dropdown-menu li:hover a {
    color: rgb(255, 255, 255) !important;
    /* background: #adadb138; */
    padding: 5px;
    border-radius: 2px;
}

/* //mobile nav  */

.mobile_nav .mobile_logo {
    /* width: 43px; */
    height: 43px;
    opacity: 1;
}

.navbar-toggler {
    padding: 0px 5px 0px 5px !important;

    border: none !important;
}

.navbar-toggler:hover {
    border: none !important;
    color: var(--colorRed);
}

.navbarIcon {
    color: var(--colorRed) !important;
}

.mobile_nav .border {
    height: 15px !important;
    border: var(--bs-border-width) var(--bs-border-style) #b3b8bd !important;
    margin-top: 20px;
}

/* Style the dropdown-toggle to make it interactive */

.mobile_nav .Icons {
    width: 20px;
    height: 17px;
    color: var(--colorRed);
    opacity: 1;
    margin-top: 17px;
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
    --bs-offcanvas-width: 100% !important;
}

.sidebar_logo {
    width: 100%;
    display: flex;
}

.btn-close {
    color: white !important;
}

.sidebar_logo img {
    width: 70%;
}

.offcanvas-header {
    background: var(--colorRed);
    border-radius: 1px;
}

.offcanvas-body {
    background-color: #041133 !important;
    color: white !important;
}

/* 
.offcanvas-body .navbar-nav .nav-item ,
.offcanvas-body .navbar-nav .dropdown-toggle{

    padding-left: 20px;
    background-color: #041133;
} */

.offcanvas-body .navbar-nav .nav-link.active {
    color: white !important;
}

.offcanvas-body .navbar-nav .nav-link {
    color: white !important;
}

.offcanvas-body .dropdown-item {
    color: rgb(2, 2, 2) !important;
}

.offcanvas .dropdown-menu[data-bs-popper] {
    width: 100% !important;
}

.offcanvas .dropdown-menu {
    visibility: visible;
}

/* // laptop 1024 screen  */

@media screen and (max-width: 1024px) and (min-width: 992px) and (orientation: landscape) {
    .HeaderPage {
        display: block !important;
    }

    .mobile_svv {
        display: none !important;
    }

    .HeaderPage .content {
        display: block !important;
    }

    .HeaderPage .content .firstHeader {
        width: 94%;
        margin-left: 44px;
    }

    .HeaderPage .content .secondHeader ul li {
        margin-right: 15px;
        margin-left: 5px;
        font-size: 12px;
        margin-top: 9px;
    }

    .HeaderPage .content .secondHeader ul {
        display: flex;
        justify-content: center;
        /* margin-left: 43px; */
    }

    .HeaderPage .content .secondHeader ul li:hover {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .HeaderPage .content .secondHeader ul .buttonStyle {
        margin-left: 11px;
    }

    .ListViewForm .SaveButton {
        padding: var(--MobilePaddingButton) !important;
    }

    .BathDet .input-group .Inputs {
        width: 80%;
    }

    .BathDet .input-group .Inputsss {
        width: 88% !important;
    }

    .BathDet .Titles {
        width: 63% !important;
    }

    .mobile_nav {
        display: block !important;
    }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {

    /* styles here */
    .HeaderPage .brand {
        width: 100%;
    }

    .dropdown-toggle::after {
        display: inline-block;

        vertical-align: 0.255em;
        content: none;
        border: none !important;
        transform: rotate(0deg);
        transition: transform 0.2s;
    }

    .HeaderPage {
        display: none !important;
    }

    .HeaderPage .content {
        display: none !important;
    }

    .dropdown_span {
        vertical-align: middle;
        margin-left: 82% !important;
    }

    .dropdown_spansss {
        vertical-align: middle;
        margin-left: 77% !important;
    }

    #socialIcons {
        margin-left: 4px !important;
    }

    .mobile_nav {
        display: block !important;
        padding: 0;
    }

    .navbar-brand {
        margin-left: 10px;
    }
}

@media screen and (min-width: 1441px) {
    .HeaderPage .brand {
        width: 100% !important;
    }
}

@media only screen and (max-width: 576px) {
    .HeaderPage .content {
        display: none;
    }

    .MobileRespo {
        display: none !important;
    }

    .dropdown-toggle::after {
        display: inline-block;
        /* margin-left: 18.255em; */
        vertical-align: 0.255em;
        content: none;
        border: none !important;
        transform: rotate(0deg);
        transition: transform 0.2s;
    }

    .mobile_nav {
        display: block !important;
        padding: 0;
    }

    .navbar-brand {
        margin-left: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {}

#socialIcons {
    cursor: pointer;
    /* width: 20px; */
    height: 20px;
    /* UI Properties */
    /* background: #1877F2 0% 0% no-repeat padding-box; */
    opacity: 1;
    margin-left: 13px;
}

.HeaderPage ul li #menulist .dropdown #SideList {
    background: rgb(255 255 255);
    visibility: visible;
    opacity: 0;
    min-width: 10rem;
    box-shadow: 2px 2px 2px #d1cccc;
    padding: 10px;
    position: absolute;
    transition: all 0.7s ease;
    margin-top: -1.5rem;
    display: block;
    right: 100%;
}

.HeaderPage ul li #menulist .dropdown #SideList li a {
    color: var(--colorRed);
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 0px !important;
}

.HeaderPage ul li #menulist .dropdown #SideList li:hover {
    width: 94%;
    background: #041133 !important;
    margin: 3px;
    border-radius: 0;
    padding-right: 0px !important;
    padding-left: 0px !important;
    color: white !important;
}

.HeaderPage ul li #menulist .dropdown #SideList li:hover a {
    color: white;
    /* padding: 8px; */

    text-decoration: none;
    font-size: var(--fontsizeThrteen);
    width: 100%;
}

/* .HeaderPage ul li #menulist .dropdown #SideList:hover li a {
    color:var(--colorRed) ;
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 0px !important;

} */

.HeaderPage ul li #menulist .dropdown a {
    color: var(--colorRed);
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 10px;
}

.HeaderPage ul li #menulist .dropdown:hover {
    background-color: var(--colorBlue);
    color: white !important;
    padding: 1px;
}

.HeaderPage ul li #menulist .dropdown:hover a {
    color: white;
    /* padding: 8px; */
    width: 100%;
}

.HeaderPage ul li #menulist .dropdown:hover #SideList {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    position: absolute;
    z-index: 99;
    display: block !important;
}

.HeaderPage ul li #menulist .dropdowns #SideLists {
    background: rgb(255 255 255);
    visibility: visible;
    opacity: 0;
    min-width: 10rem;
    box-shadow: 2px 2px 2px #d1cccc;
    padding: 10px;
    position: absolute;
    transition: all 0.7s ease;
    margin-top: -0.5rem;
    display: block;
    right: 90%;
}

.HeaderPage ul li #menulist .dropdowns #SideLists li a {
    color: var(--colorRed);
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 0px !important;
}

.HeaderPage ul li #menulist .dropdowns #SideLists li:hover {
    width: 94%;
    background: #041133 !important;
    margin: 3px;
    border-radius: 0;
    padding-right: 0px !important;
    padding-left: 0px !important;
    color: white !important;
}

.HeaderPage ul li #menulist .dropdowns #SideLists li:hover a {
    color: white;
    /* padding: 8px; */

    text-decoration: none;
    font-size: var(--fontsizeThrteen);
    width: 100%;
}

/* .HeaderPage ul li #menulist .dropdowns #SideLists:hover li a {
    color:var(--colorRed) ;
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 0px !important;

} */

.HeaderPage ul li #menulist .dropdowns a {
    color: var(--colorRed);
    text-decoration: none;
    display: block !important;
    font-size: var(--fontsizeThrteen);
    margin: 10px;
}

.HeaderPage ul li #menulist .dropdowns:hover {
    background-color: var(--colorBlue);
    color: white !important;
    padding: 1px;
}

.HeaderPage ul li #menulist .dropdowns:hover a {
    color: white;
    /* padding: 8px; */
    width: 100%;
}

.HeaderPage ul li #menulist .dropdowns:hover #SideLists {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    position: absolute;
    z-index: 999;
    display: block !important;
}

.nn_upload {
    display: none;
}

.nn_upload_file {
    color: #041134;
    margin-top: 16px;
    font-size: 16px;
    /* margin-left: 32px; new code */
    font-family: var(--fontfamily2);
}

.nn_upload_width {
    width: 100px !important;
}

/* .vv_upload_width {

    width: 96%;
} */

.mobile_svv {
    display: flex;
    justify-content: space-between;
    padding: 3px;
}

.dropdown-toggle::after {
    display: inline-block;

    vertical-align: 0.255em;
    content: "";
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.dropdown_span {
    vertical-align: middle;
    margin-left: 69%;
}

.dropdown_spansss {
    vertical-align: middle;
    margin-left: 58%;
}

.dropdown_op {
    opacity: 0 !important;
}

.dropdown-toggle.dropdown_span::after {
    transform: rotate(180deg);
    /* Rotate the icon when the dropdown is open */
}

.HeaderPage .mobileview_Screen {
    width: 100%;
    height: 21px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.headerwel {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 40px; */
}

.flex-grow-1 {
    flex-grow: 1;
}

.tool_eve {
    font-size: 10px;
}

@media screen and (width: 576px) {
    .AdmissionForm {
        padding-bottom: 10px !important;
    }
}