/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */
@import url("../../../Themes/themes.css");

.container.loginpage {
    position: fixed;
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    color: var(--textcolorwhite);
    width: 100%;
    padding: var(--formpadding);
}

.card-body.loginpage {
    margin-top: -1em;
}

.card.loginpage {
    transform: translateX(-62%);
    /* transform: translate(-13em, -5em); */
    width: var(--regsisterformWidth);
    height: var(--regsisterformHeight);
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--reg_boxshadow);
    border: 1px solid var(--reg_boxborder);
    border-radius: 8px;
    opacity: 1;
}

#wrapper_Login .eyes_logins_s {
    position: relative;
    left: 36%;
    bottom: 27px;
    font-size: 20px;
    color: var(--bluebrand);
}

.emailVerfiy {
    text-align: left;
    /* font: normal normal bold 24px/24px Source Sans Pro; */
    font-family: var(--fontfamily2);
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #041134;
    opacity: 1;
    margin-top: 10px;
}

.emailVerfiysss {
    text-align: left;
    /* font: normal normal bold 24px/24px Source Sans Pro; */
    font-family: var(--fontfamily2);
    font-size: 22px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #041134;
    opacity: 1;
    margin-top: 10px;
}

.emailVerfiy_timer {
    text-align: right;
    /* font: normal normal bold 24px/24px Source Sans Pro; */
    font-family: var(--fontfamily2);
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;
    letter-spacing: 0px;
    opacity: 1;

    color: var(--colorRed);
    opacity: 1;
}

.emailVerfiy_timer .material-icons-outlined {
    vertical-align: text-bottom;
    font-size: 27px;
    color: var(--colorRed);
    margin-right: 5px;
}

.registerfield:focus-visible,
.Phone_number_fields:focus-visible {
    background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--bluebrand) !important;
    border-radius: 6px;
    opacity: 1;
    outline: none;
    box-shadow: none;
    color: var(--focusInputcolor);
    font-family: var(--fontfamily2);
}

.email_text {
    text-align: left;
    /* font: normal normal bold 14px/20px Source Sans Pro; */
    font-family: var(--fontfamily2);
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
    margin-top: 15px;
}

.code_text_Email {
    text-align: left;
    /* font: normal normal normal 14px/20px Source Sans Pro; */
    font-family: var(--fontfamily2);
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #041134;
    opacity: 1;
}

input.form-control.loginpage {
    margin-left: -3em;
    width: var(--regsisterFormInput);
    top: 369px;
    left: 523px;
    /* width: 320px; */
    height: var(--regformInputheight);
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    border: 1px solid var(--reg_boxborderGrey);
    border-radius: 6px;
    opacity: 1;
    font: normal normal var(--fontfamily2);
}

.loginpage_heading {
    margin-bottom: 0em;
    width: 12em;
    /* text-align: center; */
    margin-left: -1.5em;
    top: 257px;
    left: 586px;
    /* width: 194px; */
    height: 35px;
    /* UI Properties */
    text-align: center;
    font: normal normal var(--boldtextWeight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
}

p.subtitle.loginpage {
    top: 302px;
    margin-left: -1.5em;
    width: 255px;
    height: 37px;
    /* UI Properties */
    text-align: center;
    font: normal normal var(--normalFontWeight) var(--regsisterFontsize) var(--fontfamily2);
    letter-spacing: var(--regsisterletterspacing);
    color: var(--loginBluecolor);
    opacity: 1;
}

button.btn.btn-danger.loginpage {
    width: 312px;
    /* height: 768px; */
    /* background: #000000 0% 0% no-repeat padding-box; */
    /* border: 1px solid #707070; */
    /* opacity: 0.19; */
    margin-left: -3em;
    color: var(--bgwhite);
    background-color: var(--color);
    margin-top: 1.5em;
    margin-bottom: 1.5em !important;
}

.copyright.loginpage {
    height: 17px;
    text-align: center;
    font: normal normal var(--normalFontWeight) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
    margin-top: -3.5em;
}

.login.loginpage {
    color: var(--color);
    text-align: left;
    font: normal normal var(--regcopyrightsweght) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    opacity: 1;
    text-decoration: none;
}

section video {
    /* width: 1366px; */
    /* height: 854px !important; */
    /* width: 1512px !important;
    height: 740px !important; */
    min-width: 100%;
}

input#mob {
    font-family: var(--fontfamily2);
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    border: 1px solid var(--reg_boxborder);
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder input[type="file"]::placeholder {
    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;
}

input#fname_register,
input#password {
    width: 150px;
    height: 36px;
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    border: 1px solid var(--reg_boxborder);
    border-radius: 6px;
    opacity: 1;
    font-family: var(--fontfamily2);
}

input#lname_register,
input#cpassword {
    margin-left: -0.5em;
    width: 152px;
    height: 36px;
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    border: 1px solid var(--reg_boxborder);
    border-radius: 6px;
    opacity: 1;
    font-family: var(--fontfamily2);
}

label.form-check-label.policyregister {
    width: 324px;
    font-size: 14px;
    margin-left: -3.5em;
    margin-top: -1.5em;
    display: block !important;
}

input#flexCheckChecked {
    margin-left: -4em;
}

.login.loginpage {}

.registeralready {
    font-size: var(--contentfontsize);
    text-align: center;
    font: var(--font) var(--contentfontsize) / 20px Source Sans Pro;
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
    border-radius: 6px;
}

.policyregister {
    font-size: var(--contentfontsize);
    /* text-align: left; */
    font: var(--font) var(--contentfontsize) / 20px Source Sans Pro;
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
}

.policyregister>a {
    color: var(--color);
}

input#privacypolicy {
    margin-left: -3em;
    margin-right: 16em;
}

/* Media Query for Large screens */

@media (max-width: 1440px) {
    .card.loginpage {
        margin-top: -3em !important;
        margin-bottom: 0em !important;
    }
}

@media (min-width: 1441px) {
    .card.loginpage {
        margin-bottom: 27em;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 768px) and (max-width: 1280px) {
    .card.loginpage {
        margin-left: 15em;
        margin-top: -3em;
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 427px) and (max-width: 767px) {
    .card.loginpage {
        margin-left: 15em;
        /* margin-left: 24em; */

        margin-top: -3em;
        width: 340px !important;
        font-size: 10px;
    }
}

@media (min-width: 320px) and (max-width: 426px) {
    .card.loginpage {
        margin-left: 13em;
        height: 550px !important;
        width: 326px;
    }

    img.annaias_logo {
        width: 50px;
        height: 50px;
    }

    .card-body.loginpage,
    p.subtitle.loginpage,
    .registeralready,
    button.btn.btn-danger.loginpage.btn-block.col-12.fa-lg.mb-3,
    .login.loginpage {
        font-size: 10px;
    }

    .registerfield {
        font-size: 13px !important;
    }

    .Phone_number_fields {
        font-size: 0.7rem !important;
    }

    input#fname_register,
    input#password,
    input.form-control.loginpage,
    label.form-check-label.policyregister,
    button.btn.btn-danger.loginpage {
        margin-left: 0em;
    }

    .loginpage_heading {
        margin-left: 0em;
    }

    p.subtitle.loginpage {
        margin-left: 2.5em;
    }

    label.form-check-label.policyregister {
        margin-top: -1.8em;
    }

    input#privacypolicy {
        margin-left: -8em;
    }

    input.form-control.loginpage,
    button.btn.btn-danger.loginpage {
        width: 293px;
    }

    input#fname_register,
    input#lname_register,
    input#cpassword,
    input#password {
        width: 140px;
    }
}

/* // for phone number input  */

.iti {
    display: block !important;
}