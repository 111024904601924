.alert-popup {
    position: fixed;
    top: 40px; /* Adjust this value to center vertically */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  .alert-icon {
    margin-right: 10px;
  }
  