/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  width: 100%;
}
html,
body {
  overflow-x: hidden;
}

.Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

/* 
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=number]::placeholder,
input[type=file]::placeholder, */

.Inputs::placeholder,
.Inputs_Phone::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.FProfile .iti__flag-container {
  left: 20px !important;
  height: 36px;
  top: 10px;
}

.FProfile {
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
}
.FProfile .subnav {
  width: 100%;
  height: 21px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px var(--blackBoxShadow);
  opacity: 1;
}
.FProfile .subnav ul {
  display: flex;
  padding: 0;
}

.FProfile .subnav .custom-item:active {
  color: var(--subnavActiveItem) !important;
}

.FProfile .subnav .custom-item {
  list-style-type: none;
  padding-left: 10px;
  margin-left: 20px;
  width: 31px;
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize)
    var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.FProfile .subnav .material-icons {
  width: 5px;
  height: 8px;
  font-size: var(--subnavIconsSize);
  margin-top: 1px;
}

.FProfile .Title {
  width: fit-content;
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize)
    var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 80px; */
  margin-top: 20px;
}

.FProfile .progressTitle {
  margin-left: 0px;
  margin-top: 30px;
  margin-right: 0px;
  margin-bottom: 30px;
  /* width: 1166px; */
  height: 80px;
  opacity: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* //active card */
.FProfile .progressTitle .Card {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.FProfile .progressTitle .Card .circle {
  width: 40px;
  height: 40px;
  background: var(--progressCardCircleColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--colorRed); */
  opacity: 1;
  border-radius: 50%;
  color: var(--progressCardCircleTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.FProfile .progressTitle .Card .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  margin-left: 18%;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.FProfile .progressTitle .Card .Stepborders {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressActivestepborder);
  opacity: 1;
  position: absolute;
  margin-left: 18%;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressActivestepborder);
}

.FProfile .progressTitle .Card .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--progressCardTitleFS);
  font-family: var(--progressCardTitle);

  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
  margin: 2px;
}

.FProfile .progressTitle .Card .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--labelweight);
  letter-spacing: 0.24px;
  color: var(--colorBlue);
  opacity: 1;
  margin: 3px;
}

/* //inactive card */

.FProfile .progressTitle .Cards {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left: 48px;
}

.FProfile .progressTitle .Cards .circle {
  width: 40px;
  height: 40px;
  /* UI Properties */
  cursor: pointer;
  background: var(--progressCardCircleInactiveColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--progressCardBorder);
  opacity: 0.2;
  border-radius: 50%;
  color: var(--progressCardCircleInactiveTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.FProfile .progressTitle .Cards .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  /* transform: matrix(0, 1, -1, 0, 0, 0); */
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  /* line-height: 16px; */
  /* left: 250px; */
  margin-left: 20%;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.FProfile .progressTitle .Cards .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 1;
  margin: 2px;
  width: max-content;
}

.FProfile .progressTitle .Cards .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--progressCardInactiveWeight);
  letter-spacing: 0.24px;
  color: var(--colorBlue);
  opacity: 1;
  margin: 3px;
}

.FProfile .StepOne .CancelButton,
.FProfile .sectionThree .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.FProfile .StepOne .SaveButton,
.FProfile .sectionThree .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .SaveButton:hover::after,
.FProfile .sectionTwo .SaveButton:hover::after,
.FProfile .sectionThree .SaveButton:hover::after,
.FProfile .sectionFour .SaveButton:hover::after,
.FProfile .sectionFive .SaveButton:hover::after,
.FProfile .sectionSix .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;

  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.FProfile .StepOne .SaveButton:hover,
.FProfile .sectionTwo .SaveButton:hover,
.FProfile .sectionThree .SaveButton:hover,
.FProfile .sectionFour .SaveButton:hover,
.FProfile .sectionFive .SaveButton:hover,
.FProfile .sectionSix .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  /* padding: var(--saveButtonPaddingHover); */
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .SaveButton a,
.FProfile .sectionTwo .SaveButton a,
.FProfile .sectionThree .SaveButton a,
.FProfile .sectionFour .SaveButton a,
.FProfile .sectionFive .SaveButton a,
.FProfile .sectionSix .SaveButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  width: max-content;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.FProfile .StepOne .SaveButton:hover a,
.FProfile .sectionTwo .SaveButton:hover a,
.FProfile .sectionThree .SaveButton:hover a,
.FProfile .sectionFour .SaveButton:hover a,
.FProfile .sectionFive .SaveButton:hover a,
.FProfile .sectionSix .SaveButton:hover a {
  color: #ffffff;
}

.FProfile .StepOne .SaveButton_Prev,
.FProfile .sectionTwo .SaveButton_Prev,
.FProfile .sectionThree .SaveButton_Prev,
.FProfile .sectionFour .SaveButton_Prev,
.FProfile .sectionFive .SaveButton_Prev,
.FProfile .sectionSix .SaveButton_Prev {
  /* width: var(--facultySavebuttonPrev); */
  height: var(--saveButtonHeight);
  /* UI Properties */
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .SaveButton_Prev:hover::before,
.FProfile .sectionTwo .SaveButton_Prev:hover::before,
.FProfile .sectionThree .SaveButton_Prev:hover::before,
.FProfile .sectionFour .SaveButton_Prev:hover::before,
.FProfile .sectionFive .SaveButton_Prev:hover::before,
.FProfile .sectionSix .SaveButton_Prev:hover::before {
  content: var(--saveButtonBeforIcon);
  position: absolute;

  left: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-left: 4px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.FProfile .StepOne .SaveButton_Prev a,
.FProfile .sectionTwo .SaveButton_Prev a,
.FProfile .sectionThree .SaveButton_Prev a,
.FProfile .sectionFour .SaveButton_Prev a,
.FProfile .sectionFive .SaveButton_Prev a,
.FProfile .sectionSix .SaveButton_Prev a {
  text-align: center;
  text-decoration: none;
  width: max-content;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.FProfile .StepOne .SaveButton_Prev:hover a,
.FProfile .sectionTwo .SaveButton_Prev:hover a,
.FProfile .sectionThree .SaveButton_Prev:hover a,
.FProfile .sectionFour .SaveButton_Prev:hover a,
.FProfile .sectionFive .SaveButton_Prev:hover a,
.FProfile .sectionSix .SaveButton_Prev:hover a {
  color: #ffffff;
}

.FProfile .StepOne .SaveButton_Prev:hover,
.FProfile .sectionTwo .SaveButton_Prev:hover,
.FProfile .sectionThree .SaveButton_Prev:hover,
.FProfile .sectionFour .SaveButton_Prev:hover,
.FProfile .sectionFive .SaveButton_Prev:hover,
.FProfile .sectionSix .SaveButton_Prev:hover {
  height: 40px;
  /* UI Properties */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);

  padding: var(--saveButtonPaddingHover);
  transform: translateX(12px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FProfile .radioGroup .formCheck {
  /* margin-bottom: 20px; */
  margin-left: 19px;
}

.FProfile .radioGroup .formCheck .checkbox {
  width: var(--inputCheckboxwidth);
  height: var(--inputCheckboxheight);
  /* UI Properties */
  background: var(--inputcheckboxBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inputcheckboxBordercolor);
  opacity: 0.5;
}

.FProfile .radioGroup .formCheck .checkbox-label {
  height: 19px;
  /* UI Properties */
  text-align: var(--checkboxlabelalign);
  font: normal normal var(--checkboxlabelweight) var(--checkboxlabelSize)
    var(--fontfamily2) !important;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-right: 17px;
  vertical-align: super;
}

.FProfile .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--colorWhite);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .box .forms .Inputs::placeholder,
.FProfile .sectionTwo .box .forms .Inputs::placeholder,
.FProfile .sectionThree .box .forms .Inputs::placeholder,
.FProfile .sectionFour .box .forms .Inputs::placeholder,
.FProfile .sectionFive .box .forms .Inputs::placeholder,
.FProfile .sectionSix .box .forms .Inputs::placeholder,
.FProfile .sectionSeven .box .forms .Inputs::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.FProfile .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .box .forms .InputsFile,
.FProfile .sectionTwo .box .forms .InputsFile,
.FProfile .sectionThree .box .forms .InputsFile,
.FProfile .sectionFour .box .forms .InputsFile,
.FProfile .sectionFive .box .forms .InputsFile,
.FProfile .sectionSix .box .forms .InputsFile,
.FProfile .sectionPhoto .box .forms .InputsFile {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionTwo .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionTwo .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}
/* ---------------------sectionTwo------------------------------- */
.FProfile .sectionTwo .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionTwo .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionTwo .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* ----------------------sectionThree------------------------------ */
.FProfile .sectionThree .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionThree .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionThree .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* ----------------------sectionFour------------------------------ */
.FProfile .sectionFour .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionFour .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionFour .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* ------------------------sectionFive---------------------------- */
.FProfile .sectionFive .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionFive .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionFive .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* -----------------------sectionSix----------------------------- */
.FProfile .sectionSix .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionSix .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionSix .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* ------------------------sectionSeven---------------------------- */
.FProfile .sectionSeven .box .forms .Inputs::-webkit-outer-spin-button,
.FProfile .sectionSeven .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FProfile .sectionSeven .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}
/* ------------------------End---------------------------- */
.FProfile .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionTwo .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* ///section three */

.FProfile .sectionThree .box {
  /* width: 1280px; */
  height: 220px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.FProfile .sectionThree .box .forms .Inputs_Phone {
  height: var(--InputHeight);
  width: 98%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px !important;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.FProfile .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */

  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionThree .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* //section four 
 */

.FProfile .sectionFour .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 6px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionFour .box .final {
  width: 169px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: var(--colorWhite);
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionFour .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.FProfile .sectionFour .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //sectio five */

.FProfile .sectionFive .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .sectionFive .box .AddMore,
.FProfile .sectionTwo .box .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer;
  margin-top: 16px;
}

.FProfile .sectionFive .box .AddMore .material-icons,
.FProfile .sectionTwo .box .AddMore .material-icons {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.FProfile .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
}

.FProfile .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionFive .box .forms .InputsFocus {
  height: 36px;
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionFive .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: var(--colorWhite);
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionFive .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.FProfile .sectionFive .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FProfile .sectionTwo .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.FProfile .sectionTwo .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  /* padding: var(--saveButtonPadding); */
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //section Six */

.FProfile .sectionSix .box {
  /* width: 1280px; */
  height: fit-content;

  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.FProfile .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.FProfile .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  line-height: var(--LineHeight);
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.FProfile .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.FProfile .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.FProfile .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 25px;
  margin-left: 20px;
}

.FProfile .sectionSix .box .forms .InputsFocus {
  height: 36px;
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.FProfile .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

.FProfile .sectionSix .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */

  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizesixteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: var(--colorWhite);
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.FProfile .sectionSix .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.FProfile .sectionSix .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FProfile .sectionSix .Checke {
  position: relative;
  bottom: 28px;
}

.BatchImage img {
  width: var(--batchImgWidth);
  height: var(--batchImgHeight);
  border-radius: var(--batchImgradius);
}

.BatchImage .title {
  /* height: 21px; */
  text-align: var(--batchTextalign);
  font: normal normal var(--batchtextweight) var(--batchTitlesize)
    var(--fontfamily2);
  letter-spacing: var(--batchtextletterspacing);
  color: var(--batchtextcolor);
  opacity: 1;
}

.BatchImage .subtitle {
  /* height: 16px; */
  text-align: var(--batchTextalign);
  font-size: var(--batchsubsize);
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
}

.IconsImage {
  list-style-type: none;
  color: var(--iconsTextcolor);
  display: flex;
  align-items: flex-start;
}

.IconsImage .title {
  text-align: var(--iconstextalign);
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: var(--iconstextopacity);
  margin: 1px;
  font-size: var(--iconstextsize);
  font-family: var(--fontfamily2);
}

.IconsImage .subtitle {
  text-align: left;
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: 1;
  margin: 0;
  font-size: var(--iconssubtextsize);
  font-family: var(--fontfamily2);
}

.FProfile .errors {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
}

.FProfile .sectionTwo .box .forms .Inputs:focus-visible,
.FProfile .sectionThree .box .forms .Inputs:focus-visible,
.FProfile .sectionFour .box .forms .Inputs:focus-visible,
.FProfile .sectionFive .box .forms .Inputs:focus-visible,
.FProfile .sectionSix .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  font-family: var(--fontfamily2);
  color: black;
}

.FProfile
  .sectionTwo
  .box
  .forms
  .Inputs::placeholder
  .FProfile
  .StepOne
  .box
  .forms
  .Inputs::placeholder
  .FProfile
  .sectionThree
  .box
  .forms
  .Inputs::placeholder
  .FProfile
  .sectionFour
  .box
  .forms
  .Inputs::placeholder
  .FProfile
  .sectionFive
  .box
  .forms
  .Inputs::placeholder
  .FProfile
  .sectionSix
  .box
  .forms
  .Inputs::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.display_none {
  display: "";
}

/* //small devies */
/* @media only screen and (min-width: 576px) and (max-width: 768px) {

    .display_none{

        display: none;
    }

    .display_none_Stepborder{

        display: none;
    }
    
 } */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet devices */
  /* .display_none_Stepborder {
    display: "";
  } */

  .FProfile #radiobuttons {
    flex-direction: column;
  }

  .FProfile .progressTitle .Card .cTitle,
  .FProfile .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .FProfile .progressTitle .Card .cSubtitle,
  .FProfile .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }

  .FProfile .progressTitle .Card .Stepborders {
    width: 32px;
    height: 0px;
    border: 1px solid var(--progressActivestepborder);
    opacity: 1;
    position: absolute;
    margin-left: 21%;
    margin-top: 18px;
    border-bottom: 1px solid var(--progressActivestepborder);
  }

  .FProfile .progressTitle .Card .Stepborder,
  .FProfile .progressTitle .Cards .Stepborder {
    width: 24px !important;

    margin-left: 21% !important;
  }

  .FProfile .sectionThree .box .forms label {
    height: 28px;
  }

  .FProfile .StepOne .SaveButton_Prev,
  .FProfile .sectionTwo .SaveButton_Prev,
  .FProfile .sectionThree .SaveButton_Prev,
  .FProfile .sectionFour .SaveButton_Prev,
  .FProfile .sectionFive .SaveButton_Prev,
  .FProfile .sectionSix .SaveButton_Prev {
    width: 100% !important;
    font-size: 11px !important;
  }

  .IconsImage .title {
    font-size: 11px !important;
  }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Faculty {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }
  .Faculty .CancelButtons {
    margin-right: 10px;
  }
  .Faculty .dflexbetww,
  .FProfile .dflexbetww {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  #SearchFilterInputs {
    width: 80%;
  }
  .BathDet .filterBoxOne .form-check-label {
    color: white;
  }

  .FProfile .progressTitle .Card .cTitle,
  .FProfile .progressTitle .Cards .cTitle,
  .FProfile .progressTitle .Card .cSubtitle,
  .FProfile .progressTitle .Cards .cSubtitle {
    display: none;
  }

  .FProfile .sectionFour .CancelButton {
    margin-right: 2px;
    font-size: 14px;
  }
  .FProfile .sectionFive .box .forms label {
    /* font-size: var(--fontsizeThrteen); */
  }
  .FProfile .nn_upload_width {
    width: 73% !important;
  }

  /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
  /* styles here */
}

.card .BatchImage {
  text-align: start !important;
}

.IconsImage .material-icons {
  font-size: 22px;
  line-height: 1.4 !important;
}

/* .FProfile .SaveButtons {

  height: 20px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.FProfile .SaveButtons a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  width: max-content;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.FProfile .SaveButtons:hover a {
  color: #ffffff;
}

.FProfile .ProfilePhoto {
  width: 250px;
  height: 250px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  /* UI Properties */
  background: var(--colorLightBlack) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorLightBlack);
  border-radius: 125px;
  /* opacity: 0.2; */
  margin-top: 45px;
  margin-bottom: 36px;
  transform: rotate(0deg);
}

.FProfile #text {
  position: relative;
  bottom: 50%;
  left: 32%;
  font-weight: var(--loginH1fontweight);
  font-family: var(--fontfamily2);
}

.FProfile .profileText {
  text-align: left;
  font-weight: var(--loginH1fontweight);
  font-size: var(--fontSizesixteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
}

.FProfile .warningOne {
  text-align: justify;
  font-weight: var(--normalFontWeight);
  font-size: var(--fontsizetwevele);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlack);
}

.FProfile .warningTwo {
  text-align: justify;
  letter-spacing: 0px;
  color: var(--colorBlack);
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: 12px;
  line-height: 20px;
}

/* //sectionprofile */

.FProfile #border {
  text-align: left;
  font-size: var(--comanInfoborder);
  font-family: var(--fontfamily2);
  border: 1px solid var(--comanInfobordercolor) !important;
  letter-spacing: 0.07px;
  color: var(--colorBlue);
  opacity: 1;
  height: 16px;
  margin-top: 4px;
}

.FProfile .commanInfo #border {
  margin-left: 8px;
}

.FProfile .commanInfo {
  display: flex;
  /* margin-left: auto; */
}

.FProfile .commanInfo li {
  list-style-type: none;
  position: relative;
  left: 100px;
}

.FProfile .commanInfo #number small {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--commanInfofontSize);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--commanInfosmall);
  opacity: 1;
}

.FProfile .commanInfo #number #tex {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--commanInfofontSize);
  font-family: var(--fontfamily2);
  letter-spacing: 0.48px;
  color: var(--commanInfotext);
  opacity: 1;
}

#profilephotos {
  width: var(--profilePhotoWH);
  height: var(--profilePhotoWH);
  border-radius: var(--borderRadiusHalf);
  background: var(--colorGrey);
  margin-top: 9px;
}

#profilephotos img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

/* .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
    background: var(--colorRed) !important;
    border: 2px solid var(--colorRed) !important;  } */

.widthForm {
  width: var(--halfcolumnReview);
}

.widthForms {
  width: var(--halfColumn);
}

#Verifycheckbox {
  text-align: left;
  font: normal normal medium 16px/21px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
}
/* .stepSeven{
    
    width: 600px;

} */
.ptitle {
  text-align: center;
  letter-spacing: 0.18px;
  color: var(--colorBlue);
  opacity: 1;
  font-weight: bold;
}
.psubtitle {
  /* width: 650px; */
  /* height: 21px; */
  margin: 0px 20px;
  text-align: center;
  letter-spacing: NaNpx;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}
.sectionSeven {
  margin-top: 81px;
  margin-bottom: 120px;
}
.FProfile .ReviewLabel {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}
.FProfile .ReviewLabels {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}
.FProfile .ReviewLabelss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}
/* .download_icon {
  color: var(--colorRed);
} */

.FProfile a {
  text-decoration: none;
}

.FProfile .SaveButtons {
  /* width: var(--facultySaveButton); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.FProfile .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.FProfile .SaveButtons:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}
.FProfile #radiobuttonss .form-check-inline {
  display: inline-block;
  margin-right: 0rem !important;
}
.FProfile #radiobuttons .form-check-inline {
  display: inline-block;
  margin-right: 1rem !important;
}
.FProfile textarea {
  height: var(--InputTextarea) !important ;
}

.FProfile .InputsMutiple {
  height: var(--TextareaHeight);
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  line-height: var(--LineHeight);
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.FProfile .StepOne .box .AddMore,
.FProfile .StepOne .box .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer;
  margin-top: 16px;
}

.FProfile .StepOne .box .AddMore .material-icons,
.FProfile .StepOne .box .AddMore .material-icons {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

#SpecificCheckbox .form-check-inline {
  display: inline-block;
  margin-right: -2rem;
}
#SpecificCheckbox .form-check-inline .checkbox-label {
  padding-right: 12px;
}

#SpecificCheckbox .checkbox {
  width: 18px;
  height: 18px;
  vertical-align: text-top;
  margin-right: 0px !important;
}

.FProfile .StepOne .SaveButton a {
  color: white;
  text-decoration: none;
}
.FProfile .StepOne .SaveButton:hover a,
.FProfile .StepOne .SaveButton:hover a .material-icons {
  color: white;
}

/* #subjectdata span{
  
  height: 36px;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
    line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);

} */

#hio {
  color: #041134;
  opacity: 0.6;
}
.InputsFile {
  width: 97%;
}

.FProfile .nn_upload_width {
  width: 78%;
}

@media only screen and (max-width: 576px) {
  .widthForm {
    width: 100% !important;
  }
  .FProfile,
  .ModelExamForm {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }
  .col_end_progress {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .FProfile .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    height: 36px !important;
    top: 10px !important;
  }
  .col_progress {
    display: flex;
    padding: 0px;
    justify-content: center;
  }
  .widthForms {
    width: 100% !important;
  }

  .display_none {
    display: none;
  }

  .FProfile .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }
  .FProfile .InputsMutiple {
    margin-left: 0px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* .display_none_Stepborder {
    display: none;
  } */

  /* .heading {
    flex-direction: column;
  } */
  /* 
  .heading .smButton {
    flex-direction: column;
    margin: 0px !important;
  } */

  .heading button {
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .FProfile .StepOne .SaveButton,
  .FProfile .sectionTwo .SaveButton,
  .FProfile .sectionThree .SaveButton,
  .FProfile .sectionFour .SaveButton,
  .FProfile .sectionFive .SaveButton,
  .FProfile .sectionSix .SaveButton {
    width: 100%;
    margin-top: 20px;
  }

  .FProfile .StepOne .SaveButton_Prev,
  .FProfile .sectionTwo .SaveButton_Prev,
  .FProfile .sectionThree .SaveButton_Prev,
  .FProfile .sectionFour .SaveButton_Prev,
  .FProfile .sectionFive .SaveButton_Prev,
  .FProfile .sectionSix .SaveButton_Prev {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #Verifycheckbox {
    margin-top: 10px;
  }

  .FProfile .StepOne .box,
  .FProfile .sectionTwo .box,
  .FProfile .sectionThree .box,
  .FProfile .sectionFour .box,
  .FProfile .sectionFive .box,
  .FProfile .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .FProfile .StepOne .box .forms label,
  .FProfile .sectionTwo .box .forms label,
  .FProfile .sectionThree .box .forms label,
  .FProfile .sectionFour .box .forms label,
  .FProfile .sectionFive .box .forms label,
  .FProfile .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--fontsizeThrteen) !important;
  }
  .FProfile .sectionFive .box .AddMore,
  .FProfile .sectionTwo .box .AddMore {
    font-size: var(--fontSizeFourteen) !important;
  }

  .FProfile .StepOne .box .forms .Inputs,
  .FProfile .sectionTwo .box .forms .Inputs,
  .FProfile .sectionThree .box .forms .Inputs,
  .FProfile .sectionFour .box .forms .Inputs,
  .FProfile .sectionFive .box .forms .Inputs,
  .FProfile .sectionSix .box .forms .Inputs {
    margin-left: 0px !important;
  }
  .FProfile .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }
  .FProfile .StepOne .box .forms .InputsFile,
  .FProfile .sectionTwo .box .forms .InputsFile,
  .FProfile .sectionThree .box .forms .InputsFile,
  .FProfile .sectionFour .box .forms .InputsFile,
  .FProfile .sectionFive .box .forms .InputsFile,
  .FProfile .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
  }

  .FProfile .sectionTwo .box,
  .FProfile .sectionThree .box,
  .FProfile .sectionFour .box,
  .FProfile .sectionFive .box,
  .FProfile .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .FProfile .subnav .custom-item {
    margin-left: 0px;
    width: 100%;
  }

  .FProfile .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
    display: none;
  }

  .FProfile .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
    display: none;
  }
  .FProfile .progressTitle .Cards .cTitle {
    display: none;
  }
  .FProfile .progressTitle .Cards .cSubtitle {
    display: none;
  }

  .FProfile .sectionSix .box .StepTitle {
    margin-left: -19px;
  }

  .FProfile .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .FProfile #radiobuttons {
    flex-direction: column;
    margin-left: 0px !important;
  }

  .FProfile .subnav {
    display: none;
  }
  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }
  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }

  .FProfile .SaveButtons {
    padding: var(--MobilePaddingButton) !important;
  }
  .FProfile .sectionFour .CancelButton,
  .FProfile .sectionFive .CancelButton,
  .FProfile .StepOne .CancelButton,
  .FProfile .sectionThree .CancelButton {
    margin-bottom: 20px !important;
  }
  .FProfile .ReviewLabel {
    margin-left: 0px !important;
  }
  .FProfile .StepOne .box .StepTitle {
    margin-left: 0px;
  }
  .FProfile .Mobileform-check-inline {
    padding-left: 0px;
  }
  .FProfile .nn_upload_width {
    width: 100% !important;
  }
  .FProfile .sectionThree .box .StepTitle {
    font-size: var(--fontSizeFourteen) !important ;
  }
  .checkbox-label {
    margin-right: 5px !important;
  }
  #SpecificCheckbox {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .FProfile .subnav {
    display: none;
  }
  .FProfile .progressTitle .Card .cTitle,
  .FProfile .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .FProfile .progressTitle .Card .cSubtitle,
  .FProfile .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }
}
