/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  width: 100%;
}

html,
body {
  overflow-x: hidden;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.AdmissionForm_section {
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
  margin-top: 20px;
  margin-bottom: 100px;
}

.AdmissionForm input[type="text"]::placeholder,
.AdmissionForm input[type="email"]::placeholder,
.AdmissionForm input[type="password"]::placeholder,
.AdmissionForm input[type="number"]::placeholder,
.AdmissionForm input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.AdmissionForm .Inputs::placeholder,
.AdmissionForm .Inputs_Phone::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.AdmissionForm .subnav {
  /* width: 100%; */
  height: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.AdmissionForm .subnav ul {
  display: flex;
  padding: 0;
  margin-left: -20px;
  margin-bottom: 0;
}

.AdmissionForm .subnav .custom-item:active {
  color: var(--subnavActiveItem) !important;
}

.AdmissionForm .subnav .custom-item {
  list-style-type: none;
  /* padding-left: 10px;
    margin-left: 20px;
    width: 31px; */
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize)
    var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.AdmissionForm .subnav .custom-itemss {
  list-style-type: none;
  /* pointer-events: none; */
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize)
    var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.AdmissionForm .subnav .custom-item a {
  list-style-type: none;
  padding-left: 10px;
  margin-left: 20px;
  width: 31px;
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize)
    var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.AdmissionForm .subnav .custom-itemss a {
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize)
    var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  pointer-events: none;
  margin-top: 2px;
  position: relative;
  right: 10px;
}

.AdmissionForm .subnav .custom-items {
  list-style-type: none;
}

.AdmissionForm .subnav .custom-items .material-icons {
  font-size: var(--subnavIconsSize);
  position: relative;
  right: 20px;
  top: 1px;
}

.AdmissionForm #number .material-icons {
  font-size: 17px;
  margin-top: 1px;
}

.AdmissionForm .Title {
  width: fit-content;
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize)
    var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 72px; */
  margin-top: 20px;
}

.AdmissionForm .progressTitle {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;
  /* height: 80px; */
  opacity: 1;
  /* text-align: center; */
  /* display: flex;
    align-items: center;
    justify-content: center; */
}

/* //active card */

.AdmissionForm .progressTitle .Card {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* justify-content: center; */
}

.AdmissionForm .progressTitle .Card .circle {
  width: 40px;
  height: 40px;
  background: var(--progressCardCircleColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--colorRed); */
  opacity: 1;
  border-radius: 50%;
  color: var(--progressCardCircleTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
}

.AdmissionForm .progressTitle .card_op {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.AdmissionForm .progressTitle .Card_border .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 2px solid var(--progressStepBorder);
  opacity: 0.2;
  margin-left: 18px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.AdmissionForm .progressTitle .Card .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  margin-left: 15%;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.AdmissionForm .progressTitle .Card_border .Stepborders {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 2px solid var(--progressActivestepborder);
  opacity: 0.2;
  margin-left: 18px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressActivestepborder);
}

.AdmissionForm .progressTitle .Card .Stepborders {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressActivestepborder);
  opacity: 1;
  position: absolute;
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressActivestepborder);
}

.AdmissionForm .progressTitle .Card .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--progressCardTitleFS)
    var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
  margin: 2px;
}

.AdmissionForm .progressTitle .Card .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--labelweight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

/* //inactive card */

.AdmissionForm .progressTitle .Cards {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.AdmissionForm .progressTitle .Cards .circle {
  width: 40px;
  height: 40px;
  /* UI Properties */
  background: var(--progressCardCircleInactiveColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--progressCardBorder);
  opacity: 0.2;
  border-radius: 50%;
  color: var(--progressCardCircleInactiveTextColor);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0;
}

.AdmissionForm .progressTitle .Cards .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  /* transform: matrix(0, 1, -1, 0, 0, 0); */
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  /* line-height: 16px; */
  /* left: 250px; */
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.AdmissionForm .progressTitle .Cards .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin: 2px;
  width: max-content;
}

.AdmissionForm .progressTitle .Cards .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--progressCardInactiveWeight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

.AdmissionForm .StepOne .CancelButton,
.AdmissionForm .sectionThree .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.AdmissionForm .StepOne .SaveButton,
.AdmissionForm .sectionThree .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.AdmissionForm .StepOne .SaveButton:hover::after,
.AdmissionForm .sectionTwo .SaveButton:hover::after,
.AdmissionForm .sectionThree .SaveButton:hover::after,
.AdmissionForm .sectionFour .SaveButton:hover::after,
.AdmissionForm .sectionFive .SaveButton:hover::after,
.AdmissionForm .sectionSix .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%;  */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.AdmissionForm .StepOne .SaveButton a,
.AdmissionForm .sectionTwo .SaveButton a,
.AdmissionForm .sectionThree .SaveButton a,
.AdmissionForm .sectionFour .SaveButton a,
.AdmissionForm .sectionFive .SaveButton a,
.AdmissionForm .sectionSix .SaveButton a,
.AdmissionForm .sectionSix .SaveButton_cash a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.AdmissionForm .StepOne .SaveButton:hover a,
.AdmissionForm .sectionTwo .SaveButton:hover a,
.AdmissionForm .sectionThree .SaveButton:hover a,
.AdmissionForm .sectionFour .SaveButton:hover a,
.AdmissionForm .sectionFive .SaveButton:hover a,
.AdmissionForm .sectionSix .SaveButton:hover a,
.AdmissionForm .sectionSix .SaveButton_cash:hover a {
  color: white;
}

.AdmissionForm .StepOne .SaveButton:hover,
.AdmissionForm .sectionTwo .SaveButton:hover,
.AdmissionForm .sectionThree .SaveButton:hover,
.AdmissionForm .sectionFour .SaveButton:hover,
.AdmissionForm .sectionFive .SaveButton:hover,
.AdmissionForm .sectionSix .SaveButton:hover,
.AdmissionForm .sectionSix .SaveButton_cash:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.AdmissionForm .StepOne .SaveButton_Prev,
.AdmissionForm .sectionTwo .SaveButton_Prev,
.AdmissionForm .sectionThree .SaveButton_Prev,
.AdmissionForm .sectionFour .SaveButton_Prev,
.AdmissionForm .sectionFive .SaveButton_Prev,
.AdmissionForm .sectionSix .SaveButton_Prev {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  /* UI Properties */
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.AdmissionForm .StepOne .SaveButton_Prev:hover::before,
.AdmissionForm .sectionTwo .SaveButton_Prev:hover::before,
.AdmissionForm .sectionThree .SaveButton_Prev:hover::before,
.AdmissionForm .sectionFour .SaveButton_Prev:hover::before,
.AdmissionForm .sectionFive .SaveButton_Prev:hover::before,
.AdmissionForm .sectionSix .SaveButton_Prev:hover::before {
  content: var(--saveButtonBeforIcon);
  position: absolute;
  /* top: 3%; */
  left: 9px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  /* padding: var(--saveButtonPaddingHover); */
  font-family: var(--fontfamily2);
  /* margin-left: 30px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.AdmissionForm .StepOne .SaveButton_Prev:hover,
.AdmissionForm .sectionTwo .SaveButton_Prev:hover,
.AdmissionForm .sectionThree .SaveButton_Prev:hover,
.AdmissionForm .sectionFour .SaveButton_Prev:hover,
.AdmissionForm .sectionFive .SaveButton_Prev:hover,
.AdmissionForm .sectionSix .SaveButton_Prev:hover {
  height: 40px;
  /* UI Properties */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);

  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdmissionForm .StepOne .SaveButton_Prev a,
.AdmissionForm .sectionTwo .SaveButton_Prev a,
.AdmissionForm .sectionThree .SaveButton_Prev a,
.AdmissionForm .sectionFour .SaveButton_Prev a,
.AdmissionForm .sectionFive .SaveButton_Prev a,
.AdmissionForm .sectionSix .SaveButton_Prev a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.AdmissionForm .StepOne .SaveButton_Prev:hover a,
.AdmissionForm .sectionTwo .SaveButton_Prev:hover a,
.AdmissionForm .sectionThree .SaveButton_Prev:hover a,
.AdmissionForm .sectionFour .SaveButton_Prev:hover a,
.AdmissionForm .sectionFive .SaveButton_Prev:hover a,
.AdmissionForm .sectionSix .SaveButton_Prev:hover a {
  color: white;
}

.AdmissionForm .radioGroup .formCheck {
  margin-bottom: 20px;
  margin-left: 19px;
}

.AdmissionForm .radioGroup .formCheck .checkbox {
  width: var(--inputCheckboxwidth);
  height: var(--inputCheckboxheight);
  /* UI Properties */
  background: var(--inputcheckboxBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inputcheckboxBordercolor);
  opacity: 0.5;
}

.AdmissionForm .radioGroup .formCheck .checkbox-label {
  height: 19px;
  /* UI Properties */
  text-align: var(--checkboxlabelalign);
  font: normal normal var(--checkboxlabelweight) var(--checkboxlabelSize)
    var(--fontfamily2) !important;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
  margin-right: 17px;
  vertical-align: super;
}

.AdmissionForm .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.AdmissionForm .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .StepOne .box .forms .InputsFile,
.AdmissionForm .sectionTwo .box .forms .InputsFile,
.AdmissionForm .sectionThree .box .forms .InputsFile,
.AdmissionForm .sectionFour .box .forms .InputsFile,
.AdmissionForm .sectionFive .box .forms .InputsFile,
.AdmissionForm .sectionSix .box .forms .InputsFile,
.AdmissionForm .sectionPhoto .box .forms .InputsFile {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionTwo .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.AdmissionForm .sectionTwo .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionTwo .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* ///section three */

.AdmissionForm .sectionThree .box {
  /* width: 1280px; */
  height: 220px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .iti__flag-container,
.ModelExamForm .iti__flag-container {
  left: 20px !important;
  height: 36px;
  top: 10px;
}

.AdmissionForm .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionThree .box .forms .Inputs_Phone {
  height: var(--InputHeight);
  /* UI Properties */
  width: 97%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--countrycodePadding);
  line-height: var(--LineHeight);
  margin-top: 10px !important;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */

  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionThree .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

.AdmissionForm .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* //section four 
 */

.AdmissionForm .sectionFour .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 6px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.AdmissionForm .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionFour .box .final {
  width: 169px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionFour .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.AdmissionForm .sectionFour .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //sectio five */

.AdmissionForm .sectionFive .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .sectionFive .box .AddMore,
.AdmissionForm .sectionTwo .box .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer !important;
  margin-top: 16px;
  font-size: 14px;
}

.AdmissionForm .sectionFive .box .AddMore .material-icons,
.AdmissionForm .sectionTwo .box .AddMore .material-icons {
  border-radius: 3px;
  cursor: pointer !important;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.AdmissionForm .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.AdmissionForm .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

.AdmissionForm .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.AdmissionForm .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

/* ---------------sectionTwo--------------------- */
.AdmissionForm .sectionTwo .box .forms .Inputs::-webkit-outer-spin-button,
.AdmissionForm .sectionTwo .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.AdmissionForm .sectionTwo .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------------SectionThree-------------------- */
/* Chrome, Safari, Edge, Opera */
.AdmissionForm .SectionThree .box .forms .Inputs::-webkit-outer-spin-button,
.AdmissionForm .SectionThree .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.AdmissionForm .SectionThree .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------------SectionFour-------------------- */
/* Chrome, Safari, Edge, Opera */
.AdmissionForm .SectionFour .box .forms .Inputs::-webkit-outer-spin-button,
.AdmissionForm .SectionFour .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.AdmissionForm .SectionFour .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------sectionFive--------------------- */
/* Chrome, Safari, Edge, Opera */
.AdmissionForm .sectionFive .box .forms .Inputs::-webkit-outer-spin-button,
.AdmissionForm .sectionFive .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.AdmissionForm .sectionFive .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------sectionSix---------------- */
.AdmissionForm .sectionSix .box .forms .Inputs::-webkit-outer-spin-button,
.AdmissionForm .sectionSix .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.AdmissionForm .sectionSix .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------- */
.AdmissionForm .sectionFive .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionFive .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.AdmissionForm .sectionFive .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdmissionForm .sectionTwo .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.AdmissionForm .sectionTwo .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //section Six */

.AdmissionForm .sectionSix .box {
  /* width: 1280px; */
  height: fit-content;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.AdmissionForm .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.AdmissionForm .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.AdmissionForm .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  margin-bottom: 30px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
  margin-bottom: 10px;
}

.AdmissionForm .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.AdmissionForm .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.AdmissionForm .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin-top: 25px;
  margin-left: 20px;
}

.AdmissionForm .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.AdmissionForm .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: var(--LineHeight);
}

.AdmissionForm .sectionSix .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.AdmissionForm .sectionSix .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.AdmissionForm .sectionSix .SaveButton {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdmissionForm .sectionSix .SaveButton_cash {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdmissionForm .sectionSix .Checke {
  position: relative;
  bottom: 28px;
}

.BatchImage img {
  width: var(--batchImgWidth);
  height: var(--batchImgHeight);
  border-radius: var(--batchImgradius);
}

.BatchImage .title {
  /* height: 21px; */
  text-align: var(--batchTextalign);
  font: normal normal var(--batchtextweight) var(--batchTitlesize)
    var(--fontfamily2);
  letter-spacing: var(--batchtextletterspacing);
  color: var(--batchtextcolor);
  opacity: 1;
}

.AdmissionForm .BatchImage .subtitle {
  /* height: 16px; */
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  text-align: var(--batchTextalign);
  font-size: var(--batchsubsize);
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
}

.IconsImage {
  list-style-type: none;
  color: var(--iconsTextcolor);
  display: flex;
  align-items: flex-start;
}

.IconsImage .title {
  text-align: var(--iconstextalign);
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: var(--iconstextopacity);
  margin: 1px;
  font-size: var(--iconstextsize);
  font-family: var(--fontfamily2);
}

.IconsImage .subtitle {
  text-align: left;
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: 1;
  margin: 0;
  font-size: var(--iconssubtextsize);
  font-family: var(--fontfamily2);
}

.AdmissionForm .errors {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);

  margin-top: 10px;
  margin-left: 20px;
  margin-top: 5px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
}

.AdmissionForm .sectionTwo .box .forms .Inputs:focus-visible,
.AdmissionForm .sectionThree .box .forms .Inputs:focus-visible,
.AdmissionForm .sectionThree .box .forms .Inputs_Phone:focus-visible,
.AdmissionForm .sectionFour .box .forms .Inputs:focus-visible,
.AdmissionForm .sectionFive .box .forms .Inputs:focus-visible,
.AdmissionForm .sectionSix .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.AdmissionForm
  .sectionTwo
  .box
  .forms
  .Inputs::placeholder
  .AdmissionForm
  .StepOne
  .box
  .forms
  .Inputs::placeholder
  .AdmissionForm
  .sectionThree
  .box
  .forms
  .Inputs::placeholder
  .AdmissionForm
  .sectionFour
  .box
  .forms
  .Inputs::placeholder
  .AdmissionForm
  .sectionFive
  .box
  .forms
  .Inputs::placeholder
  .AdmissionForm
  .sectionSix
  .box
  .forms
  .Inputs::placeholder {
  text-align: center;
  font: normal 14px/19px !important;
  font-family: var(--fontfamily2) !important;
  letter-spacing: 2px !important;
  outline: none;
  color: #041134 !important;
  opacity: 0.6;
  font-family: var(--fontfamily2);
}

.display_none {
  display: "";
}

.card .BatchImage {
  text-align: start !important;
}

.IconsImage .material-icons {
  font-size: 22px;
  line-height: 1.4 !important;
  margin-top: 10px;
}

.AdmissionForm .SaveButtons {
  /* width: 10%; */
  height: 20px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdmissionForm .ProfilePhoto {
  width: 250px;
  height: 250px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  /* UI Properties */
  background: #02041134 0% 0% no-repeat padding-box;
  border: 1px solid #02041134;
  border-radius: 125px;
  /* opacity: 0.2; */
  margin-top: 45px;
  margin-bottom: 36px;
  transform: rotate(0deg);
}

.AdmissionForm #text {
  position: relative;
  bottom: 50%;
  left: 32%;
  font-weight: var(--loginH1fontweight);
  font-family: var(--fontfamily2);
}

.AdmissionForm .profileText {
  text-align: left;
  font: normal normal var(--loginH1fontweight) 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
}

.AdmissionForm .warningOne {
  text-align: justify;
  font: normal normal var(--normalFontWeight) 12px/20px var(--fontfamily2);
  letter-spacing: 0px;
  color: #000000;
}

.AdmissionForm .warningTwo {
  text-align: justify;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: 12px;
  line-height: 20px;
}

/* //sectionprofile */

.AdmissionForm #border {
  text-align: left;
  font: normal normal medium var(--fontSizeeighteen) var(--fontfamily2);
  border: 1px solid #041134 !important;
  letter-spacing: 0.07px;
  color: #041134;
  opacity: 1;
  /* height: 16px;
    margin-top: 4px; */
  height: 20px;
  margin-top: 0px;
}

.AdmissionForm #borderss_ss {
  text-align: left;
  font: normal normal medium var(--fontSizeeighteen) var(--fontfamily2);
  border: 1px solid #041134 !important;
  letter-spacing: 0.07px;
  color: #041134;
  opacity: 1;
  /* height: 16px;
    margin-top: 4px; */
  height: 17px;
  margin-top: 2px;
}

.AdmissionForm .commanInfo #border {
  margin-left: 8px;
}

.AdmissionForm .commanInfo {
  display: flex;
  /* margin-left: auto; */
}

.AdmissionForm .commanInfo li {
  list-style-type: none;
  position: relative;
  left: 1%;
}

.AdmissionForm .commanInfo #number small {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal var(--boldtextWeight) 12px/16px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  position: relative;
  top: -3px;
}

.AdmissionForm .commanInfo #number #tex {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--fontsizetwevele)
    var(--fontfamily2);
  letter-spacing: 0.48px;
  color: #041134;
  opacity: 1;
}

#profilephotos {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: grey;
  margin-top: 9px;
}

#profilephotos img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

#BatchImages img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0%;
}

/* .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
    background: var(--colorRed) !important;
    border: 2px solid var(--colorRed) !important;  } */

.widthForm {
  width: 49%;
}

.widthForms {
  width: 95%;
}

#Verifycheckbox {
  text-align: left;
  font: normal normal medium var(--fontSizesixteen) var(--fontfamily2);
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
}

/* .stepSeven{
    
    width: 600px;

} */
.ptitle {
  text-align: center;
  letter-spacing: 0.18px;
  color: #041134;
  opacity: 1;
  font-weight: bold;
}

.psubtitle {
  /* width: 650px; */
  margin: 0px 20px;
  /* height: 21px; */
  text-align: center;
  letter-spacing: NaNpx;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.sectionSeven {
  margin-top: 81px;
  margin-bottom: 120px;
}

.AdmissionForm .ReviewLabel {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.AdmissionForm .ReviewLabels {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: rgb(220, 53, 69);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.AdmissionForm .ReviewLabelss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

.download_icon {
  color: var(--colorRed);
  cursor: pointer;
}

.AdmissionForm a {
  text-decoration: none;
}

/* 
.radiobutton {
    display: inline-block;
    appearance: none;
    border: 1px solid #ccc;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    outline: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.radiobutton:checked:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radiobutton:focus {
    border-color: #999;
}

.radiobutton:checked {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
} */

.AdmissionForm .SaveButtons {
  /* width: var(--facultySaveButton); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.AdmissionForm .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.AdmissionForm .SaveButtons:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.AdmissionForm .SaveButtons a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.AdmissionForm .SaveButtons:hover a {
  color: white;
}

.form-check-input:disabled {
  opacity: 1 !important;
}

#Subject {
  margin-left: 16px;
  margin-top: 6px;
  /* width: 81%; */
}

#impo {
  vertical-align: text-top;
  font-size: 15px;
  color: var(--bluebrand) !important;
}

#hio {
  color: #041134;
  opacity: 0.6;
}

.AdmissionForm .ReviewLabelsss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize)
    var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 8px;
  word-wrap: break-word;
}

.AdmissionForm .tit_listview {
  width: max-content;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize)
    var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  margin-left: 31px;
  margin-top: 7px;
}

.AdmissionForm .listviewSaveButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.AdmissionForm .listviewSaveButton {
  /* width: 192px; */
  height: 36px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.AdmissionForm .listviewSaveButton:hover {
  height: 36px;
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AdmissionForm .listviewSaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: -4%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.AdmissionForm .listviewSaveButton:hover a,
.AdmissionForm .listviewSaveButton:hover .material-icons {
  color: #ffffff;
}

.Payment_labelInfo {
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  text-align: right;
  letter-spacing: 0.32px;
  color: #041134;
  font-family: var(--fontfamily2);
  margin-bottom: 2.7em;
}

.Payment_labelInfos {
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 2.6em;
  text-align: left;
  padding-top: 2px;
}

.AdmissionForm .BatchImage .subtitle_testpdf {
  /* height: 16px; */
  color: var(--colorRed) !important;
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  text-align: var(--batchTextalign);
  font-size: var(--batchsubsize);
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
  text-decoration: underline;
}

/* AIASA-000104 - v1 issues solved by lalitha */
.batch_Select {
  align-self: center;
  color: var(--colorRed);
  opacity: 1;
  font-weight: bold;
}

#subject,
#ExamDetailsRes {
  margin-left: 16px;
  margin-top: 6px;
  /* width: 81%; */
}

#PreDates_mock,
#department,
#CPaneMem,
#CStaffM {
  margin-left: 16px;
  margin-top: 6px;
  /* width: 81%; */
}

.nn_upload {
  display: none;
}

.nn_upload_file {
  color: #041134;
  margin-top: 16px;
  font-size: 16px;
  font-family: var(--fontfamily2);
}

.nn_upload_width {
  width: 79% !important;
}

.Event .nn_upload_width,
.BathDet .nn_upload_width {
  width: 78% !important;
}

/* .vv_upload_width {

    width: 80% !important;
} */

.batch_Err {
  margin-bottom: 0px;
  text-align: center;
  font-size: 16px;
  color: #041134;
  font-family: var(--fontfamily2);
}

/* //radio button  */

.checkbox_radio,
.radioInput {
  width: 18px !important;
  height: 18px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  opacity: 0.9;
  vertical-align: middle;
  /* appearance: none; */
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 6px;
  /*New code  (Test model Option question paper)*/
}

.checkbox_radio,
.radioInput1 {
  width: 18px !important;
  height: 18px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  opacity: 0.2;
  vertical-align: middle;
  /* appearance: none; */
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 6px;
  /*New code  (Test model Option question paper)*/
}

.radioInput_test {
  width: 18px !important;
  height: 18px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  opacity: 0.9;
  vertical-align: middle;
  /* appearance: none; */
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 6px;
}

.checkbox_radio:checked,
.radioInput:checked {
  width: 18px !important;
  height: 18px !important;
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  opacity: 1;
  margin-right: 10px;
}

.checkbox_radio:checked,
.radioInput1:checked {
  width: 18px !important;
  height: 18px !important;
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  opacity: 1;
  margin-right: 10px;
}

.checkbox_batch {
  width: 28px;
  height: 28px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 3px;
  /* appearance: none; */
  opacity: 0.7;
}

.checkbox_batch:checked {
  width: 28px;
  height: 28px;
  background-color: #2378e9;
  /* Set your desired background color */
  border: 1px solid #2378e9;
  border-radius: 3px;
  opacity: 1;
}

/* 
.checkbox_batch:checked::before {
    content: "\2713";
    font-size: 13px;
    font-family: var(--fontfamily2);
    font-weight: bold;
    color: white;
    position: relative;
    left: 3px;
    bottom: 4px;
} */

/* date picker */

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url("../img/datepicker.png") no-repeat;
  width: 22px;
  height: 22px;
}

/* // select option hover color chnage  */

select option:checked {
  background-color: var(--bluebrand);
  color: white;
}

select option:hover {
  background-color: var(--bluebrand) !important;
  color: white !important;
}

/* select dropdown icon  */
select {
  appearance: none;
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 8 4.94'%3e%3cpath id='ic_keyboard_arrow_down_24px' d='M6.94,7.84,10,10.893,13.06,7.84l.94.94-4,4-4-4Z' transform='translate(-6 -7.84)' fill='%232378e9'/%3e%3c/svg%3e")
    no-repeat !important;
  /* url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4.94' viewBox='0 0 8 4.94'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")  */
  background-position-x: 98% !important;
  background-position-y: center !important;
  padding-right: 24px;
  /* Adjust the padding to accommodate the icon */
}

/* // after content  check icons  */

#UGDegree,
#UGMajor,
#UGClassName,
#PGDegree,
#PGMajor,
#PGClassName,
#IPDegree,
#IPMajor,
#IPClassName,
#DDegree,
#DMajor,
#DClassName,
#PCMajor,
#PCDegree,
#PCClassName,
#OtClassName,
#PHDDegree,
#PHDMajor,
#PHDClassName,
#Cast,
#name,
#CityPresent,
#CityPermanent,
#CourseCate,
#ExamName,
#AppCast,
#CompetitiveExamName,
#Designation,
#Department,
#CategoryAias,
#CourseAias,
#class_name,
#subject,
#designation,
#exp_designation,
#kComputer,
#assign_to {
  margin-left: 16px !important;
  margin-top: 10px;
  /* width: 80%; */
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  opacity: 0.7;
}

.form-check-input:checked {
  background-color: var(--bluebrand);
  border-color: var(--bluebrand);
}

.AdmissionForm .form-check-label,
.FProfile .form-check-label {
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  line-height: 19px;
  opacity: 1;
  vertical-align: super;
  color: var(--colorBlue);
}

.HiddenInput {
  display: none;
}

.col_progress {
  display: flex;
  padding: 0px;
}

.col_end_progress {
  display: flex;
  justify-content: end;
  padding: 0;
}

.verified_upload {
  font-size: var(--fontSizeFourteen) !important;
  font-family: "Material Icons";
  position: relative;
  top: 21px !important;
  left: 3px;
  color: var(--bluebrand) !important;
}

/* .FProfile .nn_upload_width {
    width: 34% !important;
} */
.download_icon {
  color: var(--colorRed) !important;
}

.eyes_login {
  color: var(--bluebrand);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper .Inputs {
  width: 100%;
}

.react-datepicker-wrapper_span {
  position: relative;
  top: 16px;
  cursor: pointer !important;
  right: 13px;
  color: var(--bluebrand);
  opacity: 1;
  font-size: 22px;
}

.filterIocnBoxInput {
  position: relative;
  top: 10px;
  right: 28px;
  color: var(--bluebrand);
  opacity: 1;
  font-size: 22px;
}

.EventreactDatepickerIocn {
  position: relative;
  top: 16px;
  right: 58px;
  color: var(--bluebrand);
  opacity: 1;
  font-size: 22px;
}

.PrevButton_date {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  opacity: 1;
  color: white;
}

.PrevButton_date:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  opacity: 1;
  color: white;
}

.BathDet #example th .PrevButton_date .material-icons {
  color: white !important;
  opacity: 1 !important;
}

.react-datepicker_Select {
  appearance: none;
  background-position-x: 92% !important;
  background-position-y: center !important;
  padding-left: 11px;
}

.react-datepicker_Select:focus-visible {
  border: none !important;
}

.stepOneCursor {
  cursor: pointer;
  text-decoration: underline;
}

.monthDatepicker .react-datepicker_Select {
  appearance: none;
  background-position-x: 92% !important;
  background-position-y: center !important;
  padding-left: 11px;
  width: 150px !important;
  text-align: center !important;
}

.monthDatepicker .react-datepicker_Select:focus-visible {
  border: none !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--bluebrand) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--bluebrand) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--bluebrand) !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--bluebrand) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: 0px !important;
  transform: translate(50px, 0px) !important;
}

.ProMobile {
  margin-left: 20px;
}

.error_small_screen {
  display: none;
}

/* ------- mobile media queries  */

/* // mobile screen */

@media only screen and (max-width: 576px) {
  .widthForm {
    width: 100% !important;
  }

  #carouselExampleIndicators {
    margin-top: 20px !important;
  }

  .col_progress {
    display: flex;
    padding: 0px;
    justify-content: center;
  }

  .AdmissionForm .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    left: 2px !important;
    height: 36px !important;
    top: 19px !important;
  }

  .nn_upload_file {
    font-size: 12px !important;
    margin-left: 10px;
  }

  .error_large_screen {
    display: none !important;
  }

  .error_small_screen {
    display: block;
  }

  .AdmissionForm .subnav .custom-itemss {
    pointer-events: none;
  }

  .widthForm {
    width: 100% !important;
  }

  .nn_upload_widthHall {
    width: 31%;
  }

  #carouselExampleIndicators {
    margin-top: 20px !important;
  }

  .col_progress {
    display: flex;
    padding: 0px;
    justify-content: center;
  }

  .AdmissionForm .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    left: 2px !important;
    height: 36px !important;
    top: 19px !important;
  }

  .nn_upload_file {
    font-size: 12px !important;
    margin-left: 10px;
  }

  .error_large_screen {
    display: none !important;
  }

  .error_small_screen {
    display: block;
  }

  .AdmissionForm .subnav .custom-itemss {
    pointer-events: none;
  }

  .AdmissionForm .scpCheckLine {
    margin-top: 14px !important;
    margin-left: 2px !important;
  }

  .AdmissionForm .nn_upload_width {
    width: 100% !important;
  }

  .ModelExamForm .react-datepicker-wrapper_span {
    position: relative;
    top: 15px;
    right: 26px;
    cursor: pointer !important;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  .react-datepicker-wrapper_span {
    position: relative;
    top: 26px;
    right: 26px;
    cursor: pointer !important;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  /* Swastic Admission Payment New Code */
  .Admission_Payment_data {
    right: 31px !important;
  }

  .FProfile .react-datepicker-wrapper_span {
    position: relative;
    top: 17px;
    right: 26px;
    cursor: pointer !important;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  .EventreactDatepickerIocn {
    position: relative;
    top: 8px;
    right: 13px !important;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  .react-datepicker-wrapper .Inputs {
    width: 108% !important;
  }

  .col_end_progress {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .widthForms {
    width: 100% !important;
  }

  .display_none {
    display: none;
  }

  .AdmissionForm .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* 
    .display_none_Stepborder {

        display: none;
    } */

  .FProfile .progressTitle .Card .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 20% !important;
  }

  .FProfile .progressTitle .Cards .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 17% !important;
  }

  .FProfile .progressTitle .Card .Stepborders {
    width: var(--progressstepborderMobileWidth) !important;
    /* margin-left: 20% !important; */
    margin-left: 18% !important;
  }

  .AdmissionForm .progressTitle .Card .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 15% !important;
  }

  .AdmissionForm .progressTitle .Cards .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 15% !important;
  }

  .AdmissionForm .progressTitle .Card .Stepborders {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 15% !important;
  }

  .heading {
    flex-direction: row;
  }

  .heading .smButton {
    flex-direction: row;
    margin-top: 0px !important;
  }

  .mt_mobile {
    margin-top: 20px;
  }

  .AdmissionForm #mobileview_Screen .commanInfo li {
    list-style-type: none;
    position: relative;
    left: 5%;
    width: 90%;
  }

  /* .heading a {

        margin: 10px;
    } */

  .heading button {
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .AdmissionForm .StepOne .SaveButton,
  .AdmissionForm .sectionTwo .SaveButton,
  .AdmissionForm .sectionThree .SaveButton,
  .AdmissionForm .sectionFour .SaveButton,
  .AdmissionForm .sectionFive .SaveButton,
  .AdmissionForm .sectionSix .SaveButton {
    width: 100% !important;
    margin-top: 20px;
  }

  .AdmissionForm .StepOne .SaveButton_Prev,
  .AdmissionForm .sectionTwo .SaveButton_Prev,
  .AdmissionForm .sectionThree .SaveButton_Prev,
  .AdmissionForm .sectionFour .SaveButton_Prev,
  .AdmissionForm .sectionFive .SaveButton_Prev,
  .AdmissionForm .sectionSix .SaveButton_Prev {
    width: 100%;
    margin-top: 20px;
  }

  .AdmissionForm .StepOne .CancelButton,
  .AdmissionForm .sectionThree .CancelButton,
  .AdmissionForm .sectionFour .CancelButton,
  .AdmissionForm .sectionFive .CancelButton {
    margin-bottom: 20px;
  }

  .checkbox_radio,
  .radioInput {
    margin-left: 5px !important;
  }

  .checkbox_radio,
  .radioInput1 {
    margin-left: 5px !important;
  }

  .AdmissionForm .errors,
  .FProfile .errors,
  .ModelExamForm .errors,
  .Purchase .errors {
    margin-left: 0px !important;
  }

  .mt-sm-4 {
    margin-top: 20px;
  }

  .Payment_labelInfo,
  .Payment_labelInfos {
    font-size: var(--fontSizeFourteen) !important;
    margin-bottom: 1.9em !important;
  }

  .payment_fees_mobile {
    margin-bottom: 0.1em !important;
  }

  .AdmissionForm .PymentCoun {
    margin-bottom: 6.2em !important;
  }

  .AdmissionForm button.btn.payment.payment_coupen {
    margin-bottom: 1.1em !important;
    margin-top: -1em !important;
  }

  .AdmissionForm h6.payment_fees_name {
    font-size: var(--fontsizetwevele);
    margin-bottom: 2.2em;
  }

  .DashboardButton {
    width: 100% !important;
  }

  .AdmissionForm h6.payment_fees_name_mobile {
    margin-bottom: 3.7em !important;
    padding-top: 0px !important;
  }

  .AdmissionForm h6#remain_fee {
    margin-top: 0px !important;
  }

  .user_answer {
    /* width: 82px; */
    /* height: 19px; */
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    color: var(--colorRed);
    opacity: 1;
  }

  .AdmissionForm .d-flex.coupon_box {
    display: block !important;
    margin-bottom: 12px;
    /* padding-bottom: 24px; */
  }

  .AdmissionForm .payment_fees {
    font-size: var(--fontsizetwevele);
    margin-bottom: 1.4em;
  }

  .AdmissionForm .Payment_labelInfo {
    margin-bottom: 2.3em !important;
  }

  /* .AdmissionForm .SaveButtons a{

        font-size: var(--fontsizeThrteen);
    }
    .AdmissionForm .StepOne .SaveButton_Prev a, .AdmissionForm .sectionTwo .SaveButton_Prev a, .AdmissionForm .sectionThree .SaveButton_Prev a, .AdmissionForm .sectionFour .SaveButton_Prev a, .AdmissionForm .sectionFive .SaveButton_Prev a, .AdmissionForm .sectionSix .SaveButton_Prev a{

        font-size: var(--fontsizeThrteen);
    } */

  .AdmissionForm .StepOne .box,
  .AdmissionForm .sectionTwo .box,
  .AdmissionForm .sectionThree .box,
  .AdmissionForm .sectionFour .box,
  .AdmissionForm .sectionFive .box,
  .AdmissionForm .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .AdmissionForm .StepOne .box .forms label,
  .AdmissionForm .sectionTwo .box .forms label,
  .AdmissionForm .sectionThree .box .forms label,
  .AdmissionForm .sectionFour .box .forms label,
  .AdmissionForm .sectionFive .box .forms label,
  .AdmissionForm .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--labelfont) !important;
    margin-top: 20px !important;
  }

  .AdmissionForm .StepOne .box .forms .Inputs,
  .AdmissionForm .sectionTwo .box .forms .Inputs,
  .AdmissionForm .sectionThree .box .forms .Inputs,
  .AdmissionForm .sectionThree .box .forms .Inputs_Phone,
  .AdmissionForm .sectionFour .box .forms .Inputs,
  .AdmissionForm .sectionFive .box .forms .Inputs,
  .AdmissionForm .sectionSix .box .forms .Inputs {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .AdmissionForm .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }

  .AdmissionForm .StepOne .box .forms .InputsFile,
  .AdmissionForm .sectionTwo .box .forms .InputsFile,
  .AdmissionForm .sectionThree .box .forms .InputsFile,
  .AdmissionForm .sectionFour .box .forms .InputsFile,
  .AdmissionForm .sectionFive .box .forms .InputsFile,
  .AdmissionForm .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .AdmissionForm .sectionTwo .box,
  .AdmissionForm .sectionThree .box,
  .AdmissionForm .sectionFour .box,
  .AdmissionForm .sectionFive .box,
  .AdmissionForm .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .AdmissionForm .subnav .custom-item {
    margin-left: 0px;
    /* width: 100%; */
  }

  .AdmissionForm .SaveButtons {
    width: 100% !important;
    padding: var(--MobilePaddingButton) !important;
  }

  .AdmissionForm .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
    display: none;
  }

  .AdmissionForm .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
    display: none;
  }

  .AdmissionForm .progressTitle .Cards .cTitle,
  .AdmissionForm .progressTitle .Cards .cSubtitle {
    display: none;
  }

  .AdmissionForm .sectionSix .box .StepTitle {
    margin-left: 5px;
  }

  .AdmissionForm .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .AdmissionForm .ReviewLabels {
    margin-left: 0px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .AdmissionForm #radiobuttons {
    flex-direction: column;
  }

  .register_app_val {
    margin-left: 0px !important;
  }

  .AdmissionForm .ReviewLabel {
    margin-left: 0px !important;
  }

  .AdmissionForm .listviewSaveButton {
    padding: var(--MobilePaddingButton) !important;
  }

  /* .AdmissionForm .subnav {

        display: none;
    } */
  .AdmissionForm #mobileview {
    display: none !important;
  }

  .AdmissionForm #mobileview_Screen {
    display: block !important;
  }

  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }

  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }

  .AdmissionForm_section {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }

  .AdmissionForm .tit_listview {
    font-size: var(--fontSizeFourteen);
    line-height: 24px;
  }

  .AdmissionForm .sectionSix .CancelButton {
    margin-top: 11px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .AdmissionForm #mobileview {
    display: block !important;
  }

  .AdmissionForm .commanInfo li {
    left: 0% !important;
  }

  .AdmissionForm #number .material-icons {
    font-size: 10px !important;
    margin-top: 5px;
  }

  .AdmissionForm .commanInfo #number #tex {
    font-size: 9px !important;
  }

  .AdmissionForm .commanInfo #number small {
    font-size: 9px;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  /* .AdmissionForm .subnav {

        display: none;
    } */
  /* .AdmissionForm #mobileview{

        display: none !important;
    } */

  /* .AdmissionForm #mobileview_Screen{
        display: none !important;
       } */
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet devices */
  .display_none_Stepborder {
    display: "";
  }

  .AdmissionForm #radiobuttons {
    flex-direction: column;
  }

  .AdmissionForm .progressTitle .Card .cTitle,
  .AdmissionForm .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .AdmissionForm .progressTitle .Card .cSubtitle,
  .AdmissionForm .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }

  .AdmissionForm .progressTitle .Card .Stepborders {
    width: 32px;
    height: 0px;
    border: 1px solid var(--progressActivestepborder);
    opacity: 1;
    position: absolute;
    margin-left: 105px;
    margin-top: 18px;
    border-bottom: 1px solid var(--progressActivestepborder);
  }

  .AdmissionForm .progressTitle .Card .Stepborder,
  .AdmissionForm .progressTitle .Cards .Stepborder {
    width: 24px !important;

    margin-left: 102px !important;
  }

  .AdmissionForm .progressTitle .card_op {
    display: block;
    justify-content: center;
  }

  .AdmissionForm .sectionThree .box .forms label {
    height: 28px;
  }

  .AdmissionForm .StepOne .SaveButton_Prev,
  .AdmissionForm .sectionTwo .SaveButton_Prev,
  .AdmissionForm .sectionThree .SaveButton_Prev,
  .AdmissionForm .sectionFour .SaveButton_Prev,
  .AdmissionForm .sectionFive .SaveButton_Prev,
  .AdmissionForm .sectionSix .SaveButton_Prev {
    width: 100% !important;
    font-size: 11px !important;
    padding: 11px 10px 10px 11px;
  }

  .IconsImage .title {
    font-size: 11px !important;
  }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .AdmissionForm #mobileview_Screen {
    display: block !important;
  }

  .AdmissionForm #mobileview {
    display: none !important;
  }

  .error_large_screen {
    display: none !important;
  }

  .error_small_screen {
    display: block;
  }

  .AdmissionForm .progressTitle .Card .cTitle,
  .AdmissionForm .progressTitle .Cards .cTitle,
  .AdmissionForm .progressTitle .Card .cSubtitle,
  .AdmissionForm .progressTitle .Cards .cSubtitle {
    display: none;
  }

  .AdmissionForm .nn_upload_width {
    width: 73% !important;
  }

  .form-check-inline {
    margin-right: 0px !important;
  }

  .form-check {
    padding-right: 0.5em;
  }

  .AdmissionForm .dflexbetww,
  .ModelExamForm .dflexbetww {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .flex_colum {
    flex-direction: column;
  }

  .Purchase .MobileHide {
    margin-bottom: 10px;
  }
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
  .AdmissionForm #mobileview_Screen {
    display: none !important;
  }
}

.AdmissionForm .scpCheckLine {
  margin-top: 14px;
  margin-left: 20px;
}

/* 
 #example th:last-child,
#example  td:last-child {
    position: sticky;
    right: -35px;
    background-color: white;
}
#example th:last-child{
right: 0px;

} 
#example  td:hover td:last-child{
    background-color: var(--bluebrand);
} */

#example td:last-child {
  position: sticky;
  right: -13px;
  background-color: white;
  border-bottom: 1px solid #dee2e6;
  z-index: 1;
}

#example th:last-child {
  position: sticky;
  right: -13px;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid var(--colorRed);
}

#example .ColorTH {
  position: sticky;
  right: -13px;
  background-color: var(--colorBlue) !important;
  z-index: 1;
  border-bottom: 1px solid var(--colorRed);
}

table#example tbody tr:hover th:last-child,
table#example tbody tr:hover td:last-child {
  background: var(--bluebrand);
}

table#example tbody tr#Subthead:hover th:last-child,
table#example tbody tr#Subthead:hover td:last-child {
  background: transparent !important;
}

/* table#example tbody tr:hover th:last-child,
table#example tbody tr:hover tr:not([id="subhead"]) td:last-child{
    background: var(--bluebrand);
    color: white;
} */

.oldstdmsg {
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: 600;
  vertical-align: text-top;
  margin-left: 6px;
  color: var(--colorBlue);
  opacity: 0.5;
}

.oldstdmsg_hide {
  visibility: hidden;
}

.LablePrivacy {
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  line-height: 19px;
  opacity: 1;
  vertical-align: super;
  color: var(--bluebrand);
  /* text-decoration: underline; */
}

.LablePrivacy:hover {
  font-size: var(--fontSizeFourteen);
  color: var(--colorRed);
}

.alert_info {
  /* margin-right: 20px; */
  /* height: 75px; */
  background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  padding: var(--alertPadding);
}

.LablePrivacyText {
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  line-height: 19px;
  opacity: 1;
  vertical-align: super;
  color: var(--colorBlue);
}

.LablePrivacyText:hover {
  font-size: var(--fontSizeFourteen);
  color: var(--colorBlue);
}

/* .alert_info{
margin-right: 20px;

background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
border: 1px solid  var(--colorBlue);
border-radius: 8px;
opacity: 1;
padding: var(--alertPadding);
} */
.alert_warning {
  border: 1px solid var(--unnamed-color-c1393d);
  background: var(--alertwarningcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 8px;
  margin: 20px;
  opacity: 1;
  padding: var(--alertPadding);
}

.alert_warning .material-icons {
  vertical-align: text-top;
  font-size: 18px;
  color: var(--colorRed);
}

.ReviewLabel_note {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--colorBlue);
  margin-top: 20px;
  /* margin-left: 20px;
margin-right: 20px; */
}

.UploadMarkSheetLabel_note {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--colorBlue);
  /* margin-left: 20px;
margin-right: 20px; */
}

.ReviewLabel_notes {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: medium;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--colorBlue);
  padding: 15px;
}

.ReviewLabel_warning {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--warningRed);
}

.waning_message {
  color: var(--warningRed);
}

@keyframes blinkss {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blinkss 2s infinite;
}

/* @keyframes blinkss {
    0% { opacity: 1.0; }
 50% { opacity: 0.0; }
 100% { opacity: 1.0; }
}

.blinkee_ee {
    animation: blinkss 2s infinite;
} */

.disabledButton {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.disabledButton a {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

@media only screen and (max-width: 576px) {
  .checkbox_radio:checked,
  .radioInput1:checked,
  .radioInput:checked {
    background: var(--bluebrand) 0 0 no-repeat padding-box;
    border: 1px solid var(--bluebrand);
    height: 18px !important;
    margin-right: 6px;
    opacity: 1;
    width: 18px !important;
  }
}
