/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

.ForgetForm {

    background-image: var(--bgimage) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* height: 100vh; */
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 768px;
    /* UI Properties */
    background: var(--bgcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--bgborder);
    opacity: 0.9;
}

#wrapper_Forget .box {
    text-align: center;
    padding: var(--forgetboxpadding);
    width: var(--resetpasswordemailWidth);
    height: var(--resetpasswordemailHeight);
    position: relative;
    margin: auto;
    top: 100px;
    background: var(--forgetbackground) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--forgetbox-shadow);
    border: 1px solid var(--forgetborder);
    border-radius: 8px;
    opacity: 1;
}

#wrapper_Forget .box h1 {
    margin-top: 10px;
    text-align: center;
    font: normal normal var(--boldtextWeight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;

}

#wrapper_Forget .box .reg {
    /* width: 109px; */
    height: var(--regtextheight);
    text-align: var(--regtextalign);
    font: normal normal var(--normalFontWeight) var(--regtextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
    /* margin-top: 42px;
    margin-left: 90px; */

}

#wrapper_Forget .box .SignupText {

    /* margin-top: 30px; */

    margin: 30px;


}

#wrapper_Forget .box .reg .rega {
    /* width: 68px; */
    height: var(--loginregatextheight);
    text-align: var(--loginregatextalign);
    text-decoration: underline;
    font: normal normal var(--loginregatextweight) var(--loginregatextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginRedColor);
    opacity: 1;
    margin-top: 30px;
    margin-left: 0px !important;

}



#wrapper_Forget .box p {

    text-align: var(--login_p_textalign);
    font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
    letter-spacing: var(--login_p_letter_spacing);
    color: var(--login_p_textcolor);
    opacity: 1;
    margin-top: 10px;

}

#wrapper_Forget .box a {
    width: 91px;
    height: 17px;
    text-align: var(--login_p_textalign);
    font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
    letter-spacing: var(--login_p_letter_spacing);
    color: var(--login_p_textcolor);
    opacity: 1;
    margin-top: 17px;
    margin-left: 116px;


}

#wrapper_Forget .box .BackLogin a {

    text-align: left;
    font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--bluebrand);
    opacity: 1;
    margin-top: 30px;
    margin-left: 0px !important;

}

#wrapper_Forget .box .copyRights {
    /* width: 135px; */
    height: 17px;

    text-align: var(--login_copyrightstextalign);
    font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--login_copyrightscolor);
    opacity: 1;
    margin-top: 30px;
    margin-left: 35px;
}

#wrapper_Forget .box .BackLogin {

    text-align: left;
    font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginRedColor);
    opacity: 1;
    margin-top: 39px;
    margin-left: 130px;
}



#wrapper_Forget #img {

    margin-top: 30px;
    width: var(--loginLogoWidthHeight);
    height: var(--loginLogoWidthHeight);
    opacity: 1
}

.remainingtime {
    margin-top: 0em;
    width: 118%;
    margin-left: -1.5em;
    text-align: center;
}



@media only screen and (max-width: 576px) {
    #wrapper_Forget img {
        height: 100%;
    }

    #wrapper_Forget .box {
        text-align: center;
        padding: var(--forgetboxpadding);
        width: var(--resetpasswordemailWidth);
        height: var(resetpasswordemailHeight);
        position: relative;
        margin: auto;
        top: 100px;
        background: var(--forgetbackground) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px var(--forgetbox-shadow);
        border: 1px solid var(--forgetborder);
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Forget .box .SignupText {
        margin: 20px;

    }


    #wrapper_Forget .box .copyRights {
        /* width: 135px; */
        height: 17px;
        text-align: var(--login_copyrightstextalign);
        font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--login_copyrightscolor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 31px !important;
    }

    #wrapper_Forget .box .BackLogin {


        text-align: left;
        font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 110px !important;
    }




    #wrapper_Forget .box a {
        width: 91px;
        height: 17px;
        text-align: var(--login_p_textalign);
        font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
        letter-spacing: var(--login_p_letter_spacing);
        color: var(--login_p_textcolor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 70px;


    }
    #wrapper_Forget .box h1 {
       
        font: normal normal var(--boldtextWeight) var(--mqH1Fontsize) var(--fontfamily2);
      
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
    img {
        height: 100%;
    }

    #wrapper_Forget .box {
        text-align: center;
        padding: var(--forgetboxpadding);
        width: var(--resetpasswordemailWidth);
        height: var(resetpasswordemailHeight);
        position: relative;
        margin: auto;
        top: 100px;
        background: var(--forgetbackground) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px var(--forgetbox-shadow);
        border: 1px solid var(--forgetborder);
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Forget .box .SignupText {
        margin: 20px;

    }

    #wrapper_Forget .box .copyRights {
        /* width: 135px; */
        height: 17px;
        text-align: var(--login_copyrightstextalign);
        font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--login_copyrightscolor);
        opacity: 1;
        margin-top: 30px;
        /* margin-left:101px !important; */
    }

    #wrapper_Forget .box .BackLogin {


        text-align: left;
        font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 110px !important;
    }


    #wrapper_Forget .box a {
        width: 91px;
        height: 17px;
        text-align: var(--login_p_textalign);
        font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
        letter-spacing: var(--login_p_letter_spacing);
        color: var(--login_p_textcolor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 70px;

    }

}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
    /* styles here */
}

.videoWrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.videoWrapper video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    z-index: -100;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

#wrapper_Forget .box small {

    color: #760909ed;
    font: normal normal 600 12px/17px var(--fontfamily2);
    font-weight: 700;
}

#wrapper_Forget #boxs{

    height: var(--sucesspasswordresetwidth) !important;

    top: 140px !important;
}

