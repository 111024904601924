.Event input[type="text"]::placeholder,
.Event input[type="email"]::placeholder,
.Event input[type="password"]::placeholder,
.Event input[type="number"]::placeholder,
.Event input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: 14px;
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.Event .MuiInputBase-root {
  margin-left: 6px !important;
  width: 94%;
}

.Event {
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
}

.Event .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.Event .CancelButtons {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 16px;
}

.Event .SaveButton a {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorWhite);
  opacity: 1;
}

.Event .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
}

.Event .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.Event .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Event .TestSaveButton a {
  text-align: left;
  text-decoration: none;
  font-weight: var(--boldtextWeight);
  font-size: 14px;
  font-family: var(--fontfamily2);
  width: max-content;
  letter-spacing: 0.28px;
  color: var(--colorWhite);
  opacity: 1;
}

.Event .TestSaveButton {
  /* width: var(--TestsavebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
}

.Event .TestSaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.Event .TestSaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Event .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.Event .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.Event .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.Event .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.Event .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.Event .textareaInput:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 2px solid var(--colorRed) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.Event .StepOne .box .forms .Inputs {
  width: 94%;
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Event .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Event .StepOne .box .forms .InputsFile {
  width: 81%;
  height: var(--InputHeight);
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 5px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Event .error {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 8px;
}

.Event [type="text"]::placeholder,
.Event input[type="email"]::placeholder,
.Event input[type="password"]::placeholder,
.Event input[type="number"]::placeholder,
.Event input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.Event .button {
  background: transparent;
  color: var(--colorRed);
  width: 37px;
  height: 31px;
  border: none;
}

#img {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 576px) {
  .Event .StepOne .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .Event .SaveButton {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 14px;
    width: 100%;
  }

  .Event .TestSaveButton {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 14px;
    width: 100%;
  }
}

.Event .form-check-inline {
  display: inline-block;
  margin-right: 0rem !important;
}

.checkbox-label {
  margin-left: 0px !important;
}

.Event .borderTabs {
  width: 1280px !important;
  height: 1px !important;
  opacity: 1 !important;
  margin-left: 17px;
  background-color: #8080807a;
}

/* #listViewTabs .nav-link.active, #listViewTabs .nav-item.show .nav-link {

background-color: transparent;
border: none;
border-bottom: 2px solid var(--bluebrand);opacity: 1 !important;
color: var(--bluebrand) !important;
}
#listViewTabs .nav-item.hover .nav-link{

    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--bluebrand);opacity: 1 !important;

} */
/* #listViewTabs .nav-link.active .nav-link:hover{
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--colorRed);opacity: 1 !important;

} */
/* #listViewTabs .nav-link:hover{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--colorRed);
    opacity: 1 !important; */
/* } */
.Event .textareaInput,
.Event .StepOne .box .forms label,
.Event .StepOne .box .forms .Inputs {
  margin-left: 6px !important;
}

.Event table thead th .thead {
  display: flex !important;
}

.material-icons {
  cursor: pointer;
}

.css-159ulw2-MuiPaper-root {
  box-shadow: none !important;
  justify-content: flex-start !important;
}

.Event #example #th {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: 14px;
  font-family: var(--fontfamily2);
  white-space: nowrap;
  /* position: relative; */
  letter-spacing: 0px;
  color: var(--colorTh);
  /* opacity: 0.5; */
}

.Event #example #td {
  font-family: var(--fontfamily2);
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  font-size: 14px;
  width: 10%;
}

.Event #example th .material-icons {
  opacity: 0.6 !important;
  color: var(--colorBlue) !important;
  cursor: pointer !important;
  font-size: 18px;
}

.Event .pag span {
  text-align: left;
  font: normal normal normal 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
}

.Event .pag select {
  background: var(--colorRed) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--colorRed) !important;
  width: 4% !important;
  appearance: auto;
  height: fit-content !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: white !important;
}

.Event .pag select option {
  background-color: none;
}

.Event .pag .pagenumber {
  text-align: center;
  font: normal normal normal 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 2px;
}

.Event .pag .prev_button {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  position: relative;
  bottom: 2px;
}

.Event .pag .prev_button .material-icons {
  color: white;
  vertical-align: text-bottom;
  font-family: "Material Icons";
  font-size: 18px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font: normal normal medium 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.4;
}

@media (max-width: 767px) {

  /* .table-responsive {
      max-width: 356px;
      overflow-y: auto;
    } */
  .Event .pag select {
    width: 15% !important;
  }
}

.Event .Inputs {
  height: 36px;
  width: 80%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Event .Inputs:focus {
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.5;
  border: 1px solid var(--colorBlue) !important;
  outline: none;
}

.Event .input-group-text {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: none;
  border-right: 1px solid var(--InputBorderColor);
  border-top: 1px solid var(--InputBorderColor);
  border-bottom: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Event .SaveButton:hover .material-icons {
  color: var(--colorRed) !important;
}

.Event .SaveButton:hover a {
  color: var(--colorRed);
}

.Event .TestSaveButton:hover .material-icons {
  color: var(--colorRed) !important;
}

.Event .TestSaveButton:hover a {
  color: var(--colorWhite);
  width: max-content;
}

.Event .Title {
  width: 100%;
  height: 26px;
  /* UI Properties */
  font-size: var(--titlefontSize);
  font-weight: var(--titlefontWeight);
  font-family: var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  margin-left: 23px;
  margin-top: 20px;
}

.Event .Titles {
  width: 55%;
  height: 26px;
  font-size: var(--titlefontSize);
  font-weight: var(--titlefontWeight);
  font-family: var(--fontfamily2);

  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.Event .Test_Titles {
  width: 71%;
  height: 26px;
  font-size: var(--titlefontSize);
  font-weight: var(--titlefontWeight);
  font-family: var(--fontfamily2);

  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.Event .ListViewBtn {
  width: fit-content;
  height: 36px;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: var(--boldtextWeight);
}

.Event .ListViewBtn a {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

.Event .ListViewBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -8%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.Event .ListViewBtn:hover a,
.Event .ListViewBtn:hover .material-icons {
  color: var(--colorRed) !important;
}

.Event .ListViewBtn:hover {
  width: 214px;
  height: 36px;
  /* height: var(--saveButtonHeight); */
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Event .PinkText {
  text-align: center;
  font-family: var(--fontfamily2);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
}

.Event .ReviewLabel {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Event .ReviewLabels {
  text-align: left;
  font-size: var(--reviewlabelsize);
  font-family: var(--fontfamily2);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Event .ReviewLabelss {
  text-align: left;
  font-size: var(--reviewlabelsize);
  font-family: var(--fontfamily2);
  font-weight: var(--boldtextWeight);

  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

.hide {
  display: none;
}

.Event .filterBoxs {
  width: var(--fliterBoxWidth);
  height: fit-content;
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--colorWhite);
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;
  /* top: 70%;
  left: 66%; */
}

.Event .PinkText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorPink);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.Event .GreenText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: 12px/16px;
  opacity: 1;
  border-radius: 2px;
}

.Event .GreyText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorSemantic);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: 12px/16px;
  opacity: 1;
  border-radius: 2px;
}

.Event .clearFilter {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);

  letter-spacing: 0px;
  color: var(--bluebrand);
  opacity: 1;
}

.Event .FilterButton {
  bottom: 8px;
  width: 135px;
  height: -moz-fit-content;
  height: 40px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* 
.Event  .FilterButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 0%;
  right: -15px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */
.Event .FilterButton:hover .material-icons {
  color: var(--colorRed) !important;
}

.Event .FilterButton:hover {
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(1px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Event .filterBoxOne {
  width: var(--fliterBoxWidth);
  height: 332px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 6px;
  opacity: 1;
  z-index: 999;
  position: absolute;
  /* top: 67%;
  left: 67%; */
}

.Event .filterBoxTwo {
  width: var(--fliterBoxWidth);
  max-height: 332px;
  /* UI Properties */
  overflow: scroll;
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 6px;
  opacity: 1;

  position: absolute;
  /* top: 66%;
    left: 34%; */
  z-index: 999;
  overflow-y: scroll;
  overflow-x: clip;
}

.Event .form-check-label,
.Event .form-label {
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  line-height: 19px;
  opacity: 1;
  vertical-align: bottom;
  color: var(--colorBlue);
}

.Event .FilterInputs {
  height: 36px;
  width: 100%;
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  border: 1px solid #0411341f;
  border-radius: 6px;
  opacity: 0.9;
  /* UI Properties */
  /* background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px; */
  /* opacity: 1; */
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  margin-top: 2px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.Event .textareaInput {
  height: 100%;
  width: 94%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* 
.reset-scroll {
  scroll-behavior: auto;
} */
.Event #subject,
#staff,
#testType,
#appBatch,
#STSubject,
#STtopic {
  margin-left: 8px;
  margin-top: 10px;
  width: 93%;
}

.Event .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer !important;
  margin-top: 16px;
  font-size: 14px;
}

.Event .material-icons {
  border-radius: 3px;
  cursor: pointer !important;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
}