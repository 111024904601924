.testDetails_container {
  margin: 15px 7px;
}
.testDetailsBox {
  margin: 7px;
}
.floatedcontainer_header {
  font-size: 12px;
  top: -8px;
}
@media (min-width: 320px) {
  /*xs smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /*sm portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 768px) {
  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .testDetailsBox {
    min-height: 140px;
  }
}
@media (min-width: 992px) {
  /*lg tablet, landscape iPad, lo-res laptops ands desktops */
  .testDetailsBox {
    min-height: 185px;
  }
  .floatedcontainer_header {
    font-size: 14px;
    top: "-10px";
  }
}
@media (min-width: 1025px) {
  /*xl big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /*xxl hi-res laptops and desktops */
}
