/* -----------New Style Added ---------------*/
.DashboardButton,
.warning-button {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardButton:hover::after {
  /* New Changes */
  /* content: var(--saveButtonAfterIcon); */
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.DashboardButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.DashboardButton Link {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.DashboardButton:hover a {
  color: white;
}

.DashboardButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.DashboardCancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: #c0272d;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
}

.DashboardCancelButton:hover {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: #c0272d;
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
}

/* -----------New Style Added ---------------*/
.warning-button:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: var(--saveButtonColor);
  border-radius: 3px;
}

/* -----------New Style Added ---------------*/
.warning-button a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

/* -----------New Style Added ---------------*/
.warning-button:hover a {
  color: var(--saveButtonColor);
}

/* -----------New Style Added ---------------*/
.warning-button:hover {
  height: var(--saveButtonHeight);
  background: white 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonColor);
  /*New change*/
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Inputss {
  height: 36px;
  width: 100%;

  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Inputss::placeholder {
  text-align: left;
  font-size: var(--fontSizeFourteen) !important;
  font-family: var(--fontfamily2);
  line-height: 19px !important;
  font-weight: 500;
  letter-spacing: 0px;
  color: var(--colorBlue) !important;
  opacity: 0.4 !important;
}

.InputssFile {
  height: 36px;
  width: 80%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.UploadExamSheetFile {
  height: 36px;
  width: 80%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  /* margin-left: 20px; */
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.errors_main {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 5px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
}

/* 
  .Inputss::placeholder {
    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;
} */

/* input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=number]::placeholder,
input[type=file]::placeholder {

    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;


} */
.Inputss:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 2px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.modelPaymentDue {
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.2px;
  color: #041134;
  opacity: 1;
}

.paymentLabel {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
}

.PaymentSp {
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 10px;
  /* margin-left: 20px; */
  display: block;
}

.color_red {
  color: var(--colorRed) !important;
}

.alert_skyblue {
  background: #d9edf7 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
}

.alert_Red {
  border: 1px solid var(--unnamed-color-c1393d);
  background: #f2dede 0% 0% no-repeat padding-box;
  border: 1px solid var(--warningRed);
  border-radius: 8px;
  opacity: 1;
}

.DueButton {
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: 9px 9px 9px 9px;
  /* border-radius: 6px; */
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DueButton_swal {
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: 9px 9px 9px 9px;
  /* border-radius: 6px; */
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--DueButton_swal_btn_width);
}

.DueButton_swal a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.DueButton_swal:hover a,
.DueButton_swal:hover .material-icons {
  color: white;
}

.DueButton_swal:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* .DueButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;

  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);

  background-color: transparent;
  color: white;
  border-radius: 3px;
} */

.DueButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.DueButton:hover a,
.DueButton:hover .material-icons {
  color: white;
}

.DueButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.modelSwalText {
  text-align: left;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
}

.ModelBodyPO {
  min-height: 240px;
}

/* -- New Style Added ViewExplaination (Start)---- */
.view-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  font-family: var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--labelcolor);
}

.view-paragraph {
  font-size: 14px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.14px;
  text-align: left;
  color: var(--labelcolor);
}

.fullscreen-paragraph {
  font-size: 14px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.14px;
  text-align: left;
  color: var(--labelcolor);
}

/* -- New Style Added  Raise A Query---- */
.raise-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.28px;
  font-family: var(--fontfamily2);
  color: var(--labelcolor);
  opacity: 0.5;
}

.raise-input {
  /* height: 100%; */
  width: 150%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;

  padding: 5px 10px 34px 10px;
  line-height: var(--InputHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* --------Mobile view------ */
@media screen and (max-width: 576px) {
  .raise-input {
    /* height: 100%; */
    width: 100%;
    background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    opacity: 0.8;
    padding: 5px 10px 34px 10px;
    line-height: var(--InputHeight);
    margin-top: 10px;
    font-family: var(--fontfamily2);
  }

  .InputsFiles {
    border: 1px solid var(--InputBorderColor);
    border-radius: 6px;
    font-family: var(--fontfamily2);
    height: var(--InputHeight);
    width: 100% !important;
    margin-top: 10px;
    opacity: 0.8;
    padding: var(--InputFilepadding);
  }
}

.raise-input-small {
  height: 23px;
  width: 45%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px 0px 0px 6px;
  opacity: 0.8;
  letter-spacing: 0px;
  padding: var(--LineHeight);
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  font-weight: medium;
  font-family: var(--fontfamily2);
}

.raise-input-small-2 {
  height: 23px;
  width: 194%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 0px 6px 6px 0px;
  opacity: 0.8;
  letter-spacing: 0px;
  padding: var(--LineHeight);
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  font-weight: medium;
  font-family: var(--fontfamily2);
}

.line {
  display: inline-block;
  margin-left: -3px;
}

.lines-1 {
  display: inline-block;
}

.cancel-button {
  text-align: right;
  font-family: var(--cancelFontFamily);
  font-size: 14px;
  font-weight: medium;
  line-height: 19px;
  color: var(--saveButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  /* New Add */
  text-decoration: none;
  margin-right: 30px;
}

.InputsFiles {
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  font-family: var(--fontfamily2);
  height: var(--InputHeight);
  /* width: 150%; */
  width: 147%;
  /* new code*/
  margin-top: 10px;
  opacity: 0.8;
  padding: var(--InputFilepadding);
}

.InputsFiles .vv_upload_width {
  width: 96%;
}


/* ------------New Style Added-------------- */
.time-query {
  text-align: left;
  font-style: italic;
  font-weight: medium;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.24px;
  color: #041134;
  /* opacity: 0.5; */
}

.view-bold {
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-top: -5px;
}

.view-bold-1 {
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  /* margin-top: -5px; */
}

.view-bold-2 {
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-bottom: -20px;
}

.time-query-second {
  text-align: left;
  font-style: italic;
  font-weight: medium;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 0.5;
  margin-top: -5px;
}

.raise-title-second {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.28px;
  font-family: var(--fontfamily2);
  color: var(--labelcolor);
  opacity: 0.5;
  margin-top: -5px;
}

.material-icon {
  color: #c1393d;
  opacity: 1;
}

.line-view {
  border: 1px solid #c1393d;
  opacity: 1;
}

.inline-container {
  display: inline-block;
  /* margin-bottom: 20px; */
}

.warning-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #c1393d;
  opacity: 1;
}

.full-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  /* color: #c1393d; */
  color: var(--labelcolor);
  opacity: 1;
}

.TitlemarginAuto {
  margin-left: auto !important;
}

.warning-name {
  text-align: center;
  font-weight: medium;
  font-size: 24px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
}

.warning-paragraph {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.16px;
  color: #041134;
  opacity: 1;
  margin-bottom: -1px;
}

.warning-header {
  border-bottom: 2px solid #c1393d !important;
}

.warning-footer {
  border-top: 2px solid #c1393d !important;
  justify-content: center;
}

.warning-count {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-family: var(--fontfamily2);
  font-weight: medium;
  letter-spacing: 0.48px;
  color: #c1393d;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: -10px;
}

/* -----New Style Added (Payment Success)------- */
.payment-button {
  /* width: var(--savebuttonWidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-button:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 0.5;
  color: white;
  /*New change*/
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.payment-button:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.payment-button a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  width: max-content;
  font-weight: bold;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

.payment-button :hover a {
  color: white;
  display: none;
}

.AddMargin {
  margin-bottom: 150px;
}

.nn_upload_widthHall {
  width: 63%;
}