.container.reg
{
    position: fixed;
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    color: var(--textcolorwhite);
    width: 100%;
    padding: var(--formpadding);
}
.card-body.reg{
    margin-top: -1em;

}
.card.reg
{ 
    right: 231px;

    /* transform: translate(7em, 5em); */
    width: 412px;
    height: 330px;
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--reg_boxshadow);
    border: 1px solid var(--reg_boxborder);
    border-radius: 8px;
    opacity: 1;

}

.reg_heading
{
    margin-bottom: 0em;

    top: 257px;
left: 586px;
/* width: 194px; */
height: 35px;
/* UI Properties */
text-align: center;
font: normal normal var(--boldtextWeight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
opacity: 1;
}

p.subtitle.reg
{
    top: 302px;
left: 575px;
/* width: 216px; */
height: 37px;
/* UI Properties */
text-align: center;
font: normal normal var(--normalFontWeight) var(--regsisterFontsize) var(--fontfamily2);
letter-spacing: 0.6px;
color:var(--loginBluecolor);
opacity: 1;

}
.login.reg {
    color: var(--color);
    text-align: left;
    font: normal normal var(--regcopyrightsweght) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    opacity: 1;
    text-decoration: none;
}

.copyright.reg
{
    height: 17px;
    text-align: center;
    font: normal normal var(--regcopyrightsweght) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    color:var(--loginBluecolor);
    opacity: 1;
    margin-top: 2em;
}

section video {
    /* width: 1366px;
    height: 768px; */
    min-width: 100%;
}