@import url("../src/Themes/themes.css");

/* Customize the scrollbar thumb (handle) */
.table-responsive::-webkit-scrollbar {
  /* width: 8px !important; */
}

.table-responsive::-webkit-scrollbar-thumb {
  background: var(--bluebrand) !important;
  border-radius: 50px !important;
  -webkit-appearance: none !important;
  height: 1px !important;
  /* width: 50% !important; */
}

.viewTestInstruction li::marker {
  color: #c1393d;
  font-size: 20px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: transparent !important;
  /* Set the scrollbar track to be transparent */
}

table {
  width: 100%;
  box-sizing: border-box;
  /* Include padding and border in the width calculation */
}

/* Customize the scrollbar track (background) */
::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width as needed */
}

/* Customize the scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background: var(--bluebrand);
  border-radius: 50px;
  border-width: 2px;
}

/* Customize the scrollbar thumb when hovering */
::-webkit-scrollbar-thumb:hover {
  background: var(--bluebrand);
}

/* Customize the scrollbar track when scrolling */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Change the color of the track while scrolling */
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
  /* Change the color of the corner */
}

body {
  scrollbar-width: 2px;
  scrollbar-color: var(--bluebrand) #f1f1f1;
  font-family: var(--fontfamily2);
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-password-saved {
  display: none;
}

/* -------Container - Header (Row) -------- */
/* -------View Student and Upload Answer sheet (Admit - Test Model descriptive) */

.global_container fieldset {
  border: 1px solid #041134;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
}

.global_container legend {
  float: none;
  font-size: 14px;
  font-weight: bold;
  color: #c1393d;
  padding: 0 5px;
  width: auto;
}

.global_container .confirmLabel {
  font-size: 14px;
  color: #041134;
  opacity: 0.5;
  font-weight: 800;
}

.global_container .confirmValue {
  color: #041134;
  font-size: 14px;
  font-weight: 800;
}

/* ------- */
/* -------Header of container --(Below Fieldset row comes-- */
.HeadContainer {
  padding: 20px !important;
}

.HeadContainer .TitleHeadContainer {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
  padding: 12px 0px;
}

.ViewContainerListBtn {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  /* flex-grow: 1; */
}

.BacktoPreviousButtonIndex {
  color: #c0272d !important;
  text-decoration: none;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}

.HeadlistButton {
  margin-top: 10px;
}

.HeadCenterListBtn {
  flex-direction: row;
  text-align: center;
}

.input-font-size {
  font-size: 14px;
}

#tableListHeader th {
  background-color: #041134;
  color: #fff;
  font-size: 14px;
}

#tableListHeader thead {
  border: none;
}

#tableListHeader td {
  font-size: 14px;
}

#tableListHeader tbody tr:hover td {
  background-color: #2378e9;
  color: white !important;
}

#tableListHeader tbody tr:hover .material-icons {
  color: white !important;
}

#tableListHeader #th .textHead_th {
  opacity: 1;
  color: #fff;
}

.input-font-size-sm {
  font-size: 15px;
}

.primary-color {
  color: #c0272d;
}

/* Title */
.BathHeader {
  padding: 15px !important;
}

.BathHeader .TitleHeader {
  font: var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  margin-bottom: 0;
  padding: 12px 0px;
}

.ModalHeader {
  font: var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  margin-bottom: 0;
  padding: 12px 0px;
}

.List-Button li:last-child {
  margin-right: 0;
  /* Removes margin from the last child */
}

/* .ListBtn {
  display: flex;
  justify-content: end;
} */

/* .paginatonDiv, */
.List-Button {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: end;
}

.FilterListButton {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

/* modal body height */
.ModalBodyHeight {
  min-height: 200px;
}

.ListBtnItems {
  display: flex;
  margin-top: 4px;
  margin-right: 10px;
}

.form-check-input {
  width: 18px;
  height: 18px;
  /* margin-top: 0px; */
  opacity: 0.7;
  border: 1px solid #041134;
}

.form-check-input:checked {
  background-color: var(--bluebrand);
  border-color: var(--bluebrand);
}

.form-check-input:disabled {
  opacity: 1 !important;
}

#date-pick-icon {
  top: 7px;
  right: 7px;
}

.listButton {
  margin-top: 10px;
}

.secondaryButton {
  color: #c0272d !important;
  text-decoration: none;
  margin-right: 10px;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}

.table>thead {
  text-wrap: nowrap;
}

.material-icons {
  cursor: pointer;
}

/* -----Mobile responsive */

@media (max-width: 576px) {
  .BathHeader .TitleHeader {
    margin-top: 10px;
    padding: 10px 0px;
  }

  .ListBtn {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .List-Button {
    display: block;
    padding: 0 !important;
  }

  .listButton {
    margin: 0px 0px 10px 0px;
  }

  .secondaryButton {
    font-size: var(--fontsizetwevele);
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .ListBtnItems {
    flex-direction: column;
    margin: 0;
  }

  #tableListHeader th {
    font-size: 12px;
  }

  .global_container legend {
    font-size: 12px;
  }

  #tableListHeader td {
    font-size: 12px;
  }

  #tableListHeader .material-icons {
    font-size: 16px;
  }
}

@media (max-width: 768px) {

  .global_container .confirmValue,
  .global_container .confirmLabel,
  .BacktoPreviousButton,
  #tableListHeader th .assignBtn_head,
  .input-font-size,
  .input-font-size-sm {
    font-size: 12px;
  }

  .HeadContainer .TitleHeadContainer {
    font-size: 12px;
    font-weight: var(--titlefontWeight);
    color: var(--Titlecolor);
    margin-top: 10px;
    padding: 10px 0px;
  }

  .BacktoPreviousButtonIndex {
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .ViewContainerListBtn {
    margin: 0;
    list-style-type: none;

    padding: 0 !important;
  }

  .HeadlistButton {
    margin: 0px 0px 10px 0px;
  }
}

/* ------Nav Item -------- */
.course-content-nav {
  border-bottom: 1px solid #c1393d;
}

.course-content-nav .nav .nav-item .nav-link {
  font-size: 12px;
  cursor: pointer;
  color: #041134;
  font-weight: 500;
}

.course-content-nav .nav .nav-item .nav-link.active {
  color: #c1393d;
  border-bottom: 1px solid #c1393d;
}

/* //create */

.global_container .forms_column {
  display: flex;
  flex-direction: column;
}

.global_container .create_Label {
  font-size: var(--labelfont);
  font-weight: var(--labelweight);
  color: var(--labelcolor);
}

.global_container #react_select_label_input {
  margin: 10px 0px 10px 0px;
}

.global_container .Input_type {
  background: var(--InputBGcolor);
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin: 10px 0px 0px 0px;
}

.global_container .Input_type:focus-visible {
  border: 1px solid var(--bluebrand) !important;
  outline: none;
}

.global_container .Input_type::placeholder {
  font-size: var(--fontSizeFourteen);
  outline: none;
  color: #041134;
  opacity: 0.4;
}

/* // table filterBox */

.global_container .filterBoxs_table_filter {
  width: fit-content;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  z-index: 99;
  position: absolute;
  padding: 10px;
}

.global_container .clearFilter_table {
  font-size: var(--fontSizeFourteen);
  font-weight: 500;
  color: var(--bluebrand);
}

.global_container .FilterButton_table_filter {
  bottom: 8px;
  height: 36px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global_container .FilterButton_table_filter:hover a,
.global_container .FilterButton_table_filter:hover .material-icons {
  color: white;
}

.global_container .FilterButton_table_filter:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
}

.global_container .hr_global {
  color: var(--colorBlue);
}

.global_container .form_check_input_global {
  width: 18px;
  height: 18px;
  opacity: 0.7;
}

.global_container .form_check_input_global:checked {
  background-color: var(--bluebrand);
  border-color: var(--bluebrand);
}

.global_container .form_check_label_global {
  vertical-align: top;
  color: var(--colorBlue);
  margin-left: 7px;
}

.global_container .RedText_global {
  background-color: var(--colorLightRed);
}

.global_container .YellowText_global {
  background-color: var(--colorYellow);
}

.global_container .GreenText_global {
  background-color: var(--colorGreen);
}

.global_container .filterText_global {
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele);
  border-radius: 2px;
  padding: 1px 7px 1px 8px;
}

.global_container .allclear_global {
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  color: var(--colorRed);
  position: relative;
  top: 3px;
  text-decoration: none;
}

/* // chipdata  and filter active icon */
.global_container #paperil_global ul {
  display: flex;
  justify-content: flex-start;
  box-shadow: none;
}

.global_container .active_icons_filter_red {
  background-color: var(--colorRed);
}

.global_container .active_icons_filter_blue {
  background-color: var(--colorBlue);
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .global_container .filterBoxs_table_filter {
    right: 18%;
  }
}

@media screen and (max-width: 576px) {
  .global_container .filterBoxs_table_filter {
    right: 18%;
  }
}