@font-face {
  font-family: "Noto Sans Tamil";
  src: url("./font/font/Noto_Sans_Tamil_Regular/Noto_Sans_Tamil_Regular/Noto-Sans-Tamil-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --tamilfont: "Noto Sans Tamil";
}

u {
  text-underline-offset: 7px;
}

.MJXc-display {
  display: inline !important;
}

.question_labels_tamil>div,
.answer_label_viewQ_tamil>div,
.answer_label_viewQa>div,
.answer_label_viewQ_english>div,
.answer_labela>div {
  display: inline;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --------Text Not Select----- */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  /* ----------------------------- */
  /* pointer-events: none; */
}

code {
  font-family: "Roboto";
}

/* -------Container - Header (Row) -------- */
/* -------View Student and Upload Answer sheet (Admit - Test Model descriptive) */

.global_container fieldset {
  border: 1px solid #041134;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
}

.global_container legend {
  float: none;
  font-size: 14px;
  font-weight: bold;
  color: #c1393d;
  padding: 0 5px;
  width: auto;
}

.global_container .confirm_label {
  font-size: 14px;
  color: #041134;
  opacity: 0.5;
  font-weight: 800;
}

.global_container .confirm_value {
  color: #041134;
  font-size: 14px;
  font-weight: 800;
}

/* ------- */
/* -------Header of container --(Below Fieldset row comes-- */
.HeadContainer {
  padding: 20px !important;
}

.HeadContainer .TitleHeadContainer {
  font-weight: var(--titlefontWeight);
  font-size: var(--titlefontSize);
  margin-bottom: 0;
  padding: 12px 0px;
}

.ViewContainerListBtn {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  /* flex-grow: 1; */
}

.BacktoPreviousButtonIndex {
  color: #c0272d !important;
  text-decoration: none;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}

.assignBtn_head {
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #c0272d;
  text-decoration: none;
  color: #fff;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  min-width: 130px;
}

.assignBtn_head:hover {
  color: #fff;
  background-color: var(--bluebrand);
}

.HeadlistButton {
  margin-top: 10px;
}

.HeadCenterListBtn {
  flex-direction: row;
  text-align: center;
}

.input-font-size {
  font-size: 14px;
}

/* CustomTable CSS background blue */
.table-responsive {
  overflow-x: auto !important;
  /* max-width: 100% !important; */
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on touch devices */
  overscroll-behavior-x: contain;
}

.table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.table>thead>tr>th {
  background-color: #041134 !important;
}

.table>thead>tr>th>span {
  color: #ffffff !important;
  opacity: unset !important;
  /* font-size: 14px !important; */
}

.table th:first-child,
.table td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
  padding: 0.5rem 20px;
}

.table th:last-child,
.table td:last-child {
  position: sticky !important;
  right: 0 !important;
  z-index: 1 !important;
  padding: 0.5rem 20px;
}

#studentListHeader thead tr {
  background-color: #041134 !important;
  /* --bs-table-bg: #041134; */
}

.studentListHeader .table>thead {
  --bs-table-bg: #041134;
  --bs-table-color: #ffffff;
  font-size: 14px;
  border: none;
}

#studentListHeader tbody tr:hover td {
  background-color: #2378e9;
  color: white !important;
}

#studentListHeader #th .text_th {
  opacity: unset;
  color: #fff;
}

.input-font-size-sm {
  font-size: 15px;
}

/* For WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2378e9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2378e9;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2378e9 #f1f1f1;
}

/* -----Mobile responsive */
@media (max-width: 768px) {

  .global_container .confirm_value,
  .global_container .confirm_label,
  .BacktoPreviousButton,
  .assignBtn_head,
  .input-font-size,
  .input-font-size-sm {
    font-size: 12px;
  }

  .HeadContainer .TitleHeadContainer {
    font-size: 12px;
    font-weight: var(--titlefontWeight);
    color: var(--Titlecolor);
    margin-top: 10px;
    padding: 10px 0px;
  }

  .BacktoPreviousButtonIndex {
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .ViewContainerListBtn {
    margin: 0;
    list-style-type: none;

    padding: 0 !important;
  }

  .HeadlistButton {
    margin: 0px 0px 10px 0px;
  }
}