/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */
@import url('../../../Themes/themes.css');

.container.newpass
{
    position: fixed;
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    color: var(--textcolorwhite);
    width: 100%;
    padding: var(--formpadding);
}

.card-body.newpass{
    margin-top: -1em;

}

.card.newpass
{ 
    
    transform: translate(10em, 0em);
    width: 360px;
    height: 531px;
    background: var(--bgwhite) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--reg_boxshadow);
    border: 1px solid var(--reg_boxborder);
    border-radius: 8px;
    opacity: 1;

}


.newpass_heading
{
    margin-bottom: 0em;

    top: 257px;
left: 586px;
/* width: 194px; */
height: 35px;
/* UI Properties */
text-align: center;
font: normal normal var(--boldtextWeight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
opacity: 1;
}

p.subtitle.newpass
{
    top: 302px;
left: 575px;
width: 216px;
height: 37px;
/* UI Properties */
text-align: center;
font: normal normal var(--normalFontWeight) var(--regsisterFontsize) var(--fontfamily2);
letter-spacing: var(--regsisterletterspacing);
color: var(--loginBluecolor);
opacity: 1;

}

button.btn.btn-danger.newpass{
    width: 312px;
    /* height: 768px; */
    /* background: #000000 0% 0% no-repeat padding-box; */
    /* border: 1px solid #707070; */
    /* opacity: 0.19; */
    margin-left: -3em;
    color:var(--bgwhite);
   

}

.copyright.newpass
{
    height: 17px;
    text-align: center;
    font: normal normal var(--normalFontWeight) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
    margin-top: -2em;
}

.login.newpass {
    color: var(--color);
    text-align: left;
    font: normal normal var(--regcopyrightsweght) var(--regcopyrights) var(--fontfamily2);
    letter-spacing: 0px;
    opacity: 1;
    text-decoration: none;
}
/* section video {
  
    height: 100vh;
    overflow: hidden;
    position: relative;
    min-width: 100% !important;
    min-width: 100%;
} */


.videoWrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.videoWrapper video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
#boxs{

    width: var(--newpassboxwidth) !important;
    height: var(--newpassboxheight) !important;
    top: 50px !important;
}

/* .PasswordForm input[type=text]::placeholder,
.PasswordForm input[type=email]::placeholder,
.PasswordForm input[type=password]::placeholder,
.PasswordForm input[type=number]::placeholder
.PasswordForm input[type=file]::placeholder{

    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;
} */
.PasswordForm .NewpInput::placeholder{
    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;
  
  }


.NewpInput:focus-visible {
    background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
    /* border: 2px solid var(--focusInputBordercolor); */
    border: 2px solid var(--bluebrand) !important;
    border-radius: 6px;
    opacity: 1;
    outline: none;
    color: var(--focusInputcolor);
    font-family: var(--fontfamily2);

}

.NewpInput {
    width: var(--loginInputBoxWidth);
    height: var(--loginInputBoxHeight);
    padding: var(--loginInputBoxPadding);
    background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--loginInputBoxborder);
    border-radius: 6px;
    opacity: 1;
    margin-top: 15px;
    font-family: var(--fontfamily2);
}

.PasswordForm .eyes_login {
    position: absolute;
    right: 27px;
    top: 258px;
    font-size: 20px;
    color: var(--bluebrand);
    z-index: 99;
}
.PasswordForm .eyes_logins {
    position: absolute;
    right: 27px;
    top: 308px;
    font-size: 20px;
    color: var(--bluebrand);
    z-index: 99;
}
/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 768px) {

    .PasswordForm .eyes_logins {
       
        top: 258px;
       
    }
    
    .PasswordForm .eyes_login {
       
        top: 309px;
       
    }

}
@media only screen and (max-width: 576px) {


    .PasswordForm .eyes_login {
        top: 254px;
    }
    .PasswordForm .eyes_logins {
       
        top: 305px;
       
    }
}