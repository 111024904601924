:root {
  --formfieldbackground: #d3d3d39c;
  --fontsize: 16px;
  --fontfamily: Source Sans Pro;
  --formbg: linear-gradient(to right, #781414 0, #e01919 100%);

  --color: #C0272D;
  --footerbgcolor: #041134;
  --titlefontsize: 24px;
  --footerfontfamily: Source Sans Pro;
  --font: normal normal;
  --subtitlefontsize: 16px;
  --contentfontsize: 14px;

  /* //all over label */
  --labelfont: 14px;
  --labelalign: left;
  --labelweight: bold;
  --labelcolor: #041134;

  /* //allover font family  */

  --fontfamily2: Roboto;

  /* //subnav  */

  --subnavActiveItem: #C0272D;
  --subnavItemColor: #041134;
  --subnavFontFamily: Roboto;
  --subnavFontsize: 12px/17px;
  --subnavFontWeight: 600;
  --subnavTextAlign: left;
  --subnavIconsSize: 18px;

  /* //title */

  --titlefontSize: 20px/24px;
  --titlefontWeight: bold;
  --Titlecolor: #041134;


  /* //progresscard */
  --progressCardCircleColor: #C0272D;
  --progressCardCircleTextColor: white;
  --progressCardCircleInactiveColor: #FFFFFF;
  --progressCardCircleInactiveTextColor: #524d4d;
  --progressCardBorder: #041134;
  --progressStepBorder: #041134;
  --progressstepborderWidth: 68px;
  --progressstepborderMobileWidth: 12px;

  --progressActivestepborder: #C0272D;
  --progressCardTitle: Roboto;
  --progressCardSubTitle: Roboto;
  --progressCardFontsize: 12px;
  --progressCardTitleFS: 14px;

  --progressCardInactiveWeight: normal;

  /* //buttons */
  --cancelButtonFontSize: 15px;
  --cancelFontFamily: Roboto;
  --cancelFontWeight: medium;
  --cancelFontWeight: medium;
  --cancelbuttonAlign: left;
  /* --cancelButtonColor: #C0272D; */
  --saveButtonColor: #C0272D;
  --saveButtonBorder: #C0272D;
  --saveButtonTextColor: white;
  --saveButtonPadding: 11px 36px 10px 36px;
  --savebuttonWidth: 320px;
  --saveButtonHeight: 40px;
  /* //hover | after */
  --saveButtonHoverColor: white;
  --saveButtonHoverBorderColor: #C0272D;
  --saveButtonHoverTextColor: #C0272D;
  --saveButtonAfterIcon: "→";
  --saveButtonBeforIcon: "←";
  --savebuttonIconsSize: 17px;
  --saveButtonfontweight: bold;
  --saveButtoniconColor: #C0272D;
  --saveButtonPaddingHover: 11px 36px 10px 36px;


  /* formTitle */

  --FieldTitleBoxColor: white;
  --fieldTitleBorder: #041134;
  --fieldStepTitleSize: 16px/21px;
  --fieldStepTitleWeight: Bold;
  --fieldStepTitlecolor: #C0272D;
  --fieldStepTitlecolorAlign: left;
  --fieldStepTitlePadding: 0 10px;


  /* //input */

  --focusInputBordercolor: #041134;
  --focusInputBackgroundColor: white;
  --focusInputcolor: #041134;
  --focusInputpadding: 8px 8px 9px 10px;

  --InputBGcolossr: #c5c2c2;
  --InputBGcolor: #ffffff;
  --InputBorderColor: #77041134;
  --Inputpadding: 8px 8px 9px 10px;
  --InputFilepadding: 2px;
  --InputHeight: 36px;

  --LineHeight: 18px;

  /* //checkbox */

  --inputCheckboxwidth: 18px;
  --inputCheckboxheight: 18px;
  --inputcheckboxBordercolor: #041134;
  --inputcheckboxBackgroundColor: white;

  --checkboxlabelSize: 14px/19px;
  --checkboxlabelweight: medium;
  --checkboxlabelalign: left;

  /* //addmore icons */

  --addmoreTextcolor: #C1393D;
  --addmoreFontWeight: 300;
  --addmoreiconssize: 20px;
  --addmoreiconsweight: 200;

  /* //batch details */

  --batchImgWidth: 130px;
  --batchImgHeight: 130px;
  --batchImgradius: 20px;

  --batchTitlesize: 16px/21px;
  --batchtextcolor: #C0272D;
  --batchtextweight: bold;
  --batchTextalign: left;
  --batchtextletterspacing: 0.32px;
  --batchSubtext: #041134;
  --batchsubsize: 14px;
  --batchsubletterspacing: 0.24px;

  --iconsTextcolor: #C0272D;
  --iconstextsize: 14px;
  --iconssubtextsize: 16px;
  --iconstextalign: left;
  --iconstextopacity: 0.5;
  --iconsTitlecolor: #041134;

  /* //error */

  --errortextcolor: #C0272D;
  --errortextsize: 13px;
  --errortextalign: left;
  --errortextweight: bold;

  /* review information in admission form section */
  --refontsize: 14px/19px;

  --regsisterappvalfontsize: 16px/21px;
  --regsisterappvalfontweight: bold;
  --regsisterappvaltextalign: left;
  --regsisterappvaltextcolor: #041134;




  /* media queries  */

  --mqprogressCardTitleFontsize: 16px;
  --mqprogressCardSubFontsize: 12px;
  --mqH1Fontsize: 20px/20px;



  /* //login form  */

  --normalFontWeight: normal;
  --loginRedColor: #C0272D;
  --loginWhiteColor: white;
  --loginBluecolor: #041134;
  --loginsmalltextcolor: #760909ed;
  --loginsmalltextsize: 12px/17px;
  --loginsmalltextweight: 600;

  --H1Fontsize: 24px/24px;


  --reviewlabelsize: 16px/21px;

  --loginLogoWidthHeight: 98px;


  --loginbox: #FFFFFF;
  --loginboxshadow: #0000001A;
  --loginboxborder: #04113480;
  --loginboxWidth: 360px;
  --loginboxHeight: 560px;
  --loginboxPadding: 20px;


  --loginH1fontweight: bold;
  --loginH1Textalign: center;
  --loginH1Color: #041134;

  --regtextsize: 14px/20px;
  --regtextalign: center;
  --regtextheight: 20px;

  --loginregatextsize: 12px/17px;
  --loginregatextweight: 600;
  --loginregatextheight: 17px;
  --loginregatextalign: left;

  --login_p_textsize: 12px/20px;
  --login_p_textalign: center;
  --login_p_textcolor: #041134;
  --login_p_letter_spacing: 0.6px;

  --loginInputBoxWidth: 320px;
  --loginInputBoxHeight: 36px;
  --loginInputBoxPadding: 8px 8px 10px 9px;
  --loginInputBoxcolor: #FFFFFF;
  --loginInputBoxborder: #04113480;


  --loginCheckInputBoxWidthHeight: 18px;
  --loginCheckInputBoxcolor: #FFFFFF;
  --loginCheckInputBoxborder: #04113480;
  --loginCheckInputBoxSize: 14px/20px;

  --loginCheckbox_inlinewidth: 98px;
  --loginCheckbox_inlineheight: 20px;
  --loginCheckbox_inlinecolor: #041134;

  --login_a_size: 12px/17px;
  --login_a_weight: 600;

  --login_a_decoration: underline;

  --login_copyrightssize: 12px/17px;

  --login_copyrightscolor: #041134;
  --login_copyrightstextalign: center;

  /* //button */
  --loginbuttonWidth: 320px;
  --loginButtonHeight: 40px;
  --loginbuttoncolorsize: 14px/20px;
  --loginbuttoncolorPadding: 11px 36px 10px 36px;

  /* //forget password */


  --bgimage: url('../img/img1.png');
  --bgcolor: #000000;
  --bgborder: #707070;
  --forgetboxpadding: 8px 8px 8px 8px;
  --forgetboxwidth: 360px;
  --forgetboxheight: 500px;
  --forgetbackground: #FFFFFF;


  --newpassboxwidth: 360px;
  --newpassboxheight: 540px;


  --forgetbox-shadow: #0000001A;
  --forgetborder: #04113480;

  --backloginFontsize: 12px/17px;
  --backloginFontWeight: 600;


  --resetpasswordemailWidth: 360px;
  --resetpasswordemailHeight: 394px;

  --sucesspasswordresetwidth: 344px;

  --regsisterformsWidth: 360px;
  --regsisterformsHeight: 690px;


  /* //register */

  --bgwhite: white;
  --textcolorwhite: #f1f1f1;
  --reg_boxshadow: #0000001a;
  --reg_boxborder: #04113480;
  --reg_boxborderGrey: #9B999A;
  --formpadding: 20px;
  --formwidth: 312px;
  --formHeight: 36px;

  --regsisterformWidth: 360px;
  --regsisterformHeight: 670px;


  --regsisterFormInput: 312px;
  --regformInputheight: 36px;

  --regsisterFontsize: 12px/20px;
  --regsisterletterspacing: 0.6px;

  --regcopyrights: 12px/17px;
  --regcopyrightsweght: 600;

  --listButtonWidth: 200px;
  --listButtonHeight: 40px;


  /* mock interview */

  --mockinterviewavebuttonWidth: 350px;

  /* counselling */
  --CounsellingsavebuttonWidth: 350px;
  --Counsellingsavebutton2Width: 420px;

  --facultySaveButton: 360px;
  --facultySavebuttonPrev: 250px;

  --TextareaHeight: 99px;
  --InputTextarea: 110px;
  --halfcolumnReview: 49%;
  --halfColumn: 95%;

  --profilePhotoWH: 230px;
  --borderRadiusHalf: 50%;

  --commanInfofontSize: 12px;
  --commanInfosmall: #C0272D;
  --commanInfotext: #041134;
  --comanInfoborder: 18px;
  --comanInfobordercolor: #041134;

  --textareaPlaceHolder: 14px;
  --textareaPlaceHolderColor: #041134;
  --modelbtnwidth: 177px;
  --modelbtnwidthHover: 180px;
  --modelbtnHeight: 40px;

  --fliterBoxWidth: 240px;
  --fontsizeelveen: 11px;
  --fontsizetwevele: 12px;
  --fontsizeThrteen: 13px;
  --fontsizeFiveteen: 15px;
  --fontSizesixteen: 16px;
  --ListViewBtnHover: 190px;
  --fontSizeeighteen: 18px;
  --ListViewBtnHeight: 36px;
  --fontSizeTwenty: 20px;
  --fontSizeTwentyTwo: 22px;
  --fontSizeFourteen: 14px;
  --colorRed: #C0272D;
  --warningRed: #C1393D;
  --colorYellow: #e4a11b;
  --colorWhite: white;
  --blackBoxShadow: #00000029;
  --colorBlue: #041134;
  --boldtextWeight: bold;
  --colorSemantic: #9E9E9E;
  --colorGreen: #4CAF50;

  --colorPink: #E91E63;
  --colorLightRed: #dc4c64;
  --colorGrey: grey;
  --colorBlack: #000000;
  --colorLightBlack: #02041134;
  --borderActive: #8080804d;
  --colorTh: #041134;
  --colorFocusInpus: #77041134;
  --colorLightBlue: #0411341f;
  --disabledColor: #b74144d4;
  --purchaseButtonWidthLong: 203px;
  --purchaseButtonWidth: 155px;
  --PurchaseButtonHeight: 36px;
  --makebtnwidth: 156px;
  --headerBack: #CCCFD6;
  --modelExamWidth: 233px;
  --batchbtnwidth: 216px;
  --modelexamHeight: 40px;
  --TestsavebuttonWidth: 130px;



  /* -- new brand  */

  --cancelButtonColor: #C0272D;
  --bluebrand: #2378E9;
  --paddingScreen: 43px;

  --paddingMobileScreen: 20px;

  --MobilePaddingButton: 11px 10px 10px 11px;

  --countrycodePadding: 7px 8px 7px 60px;

  --alertPadding: 14px 28px 14px 28px;

  --alertColorInfo: #D9EDF7;

  --alertwarningcolor: #F2DEDE;

  --DueButton_swal_btn_width: 150px;

  --fontsizeTwentyOne: 21px;

}