@import url('../../../Themes/themes.css');


label.form-label.text-muted.register_app {
    width: 100%;
    text-align: left;
    font: normal normal bold var(--refontsize) Roboto;
    letter-spacing: 0.28px;
    color: #041134;
    opacity: 0.5;
    font-family: Roboto, Bold;
    margin-right: 20em;

}
label.form-label.text-muted.register_app.oneline {
    margin-left: 1.8em;
    width: 100%;
}
.register_app_val
{
    text-align: var(--regsisterappvaltextalign);
    font: normal normal var(--regsisterappvalfontweight) var(--regsisterappvalfontsize) Roboto !important;
    letter-spacing: 0.32px;
    color:var(--regsisterappvaltextcolor);
    opacity: 1;
    margin-left: 1.5em;
    word-wrap: break-word;
}

.btn.register_app
{
    background-color:var(--color);
    color:white;
    text-align: center;
font: normal normal bold 14px/19px Roboto;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.register_app_cancel
{
    color: var(--color);
text-align: left;
font: normal normal medium 14px/19px Roboto;
letter-spacing: 0px;
opacity: 1;
text-decoration: none;

}

.register_app_sub_title
{
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0.32px;
    color:  var(--color);
    opacity: 1;
    
}
.flex4 {
    /* margin-top: 30px; */
    margin: auto;
    width: 100%;
    padding: 2em 2em 2em 2em !important;
    border-radius: 5px;


}
.inner_flex4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding: 6px;
    border-radius: 11px;
    border: 1px solid #0000004D;
}

/* .box {
    margin: 28px;
    width: 96%;
    height: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #041134;
    border-radius: 8px;

} */
/* .box > h6 {
    position: absolute;
    background: white;
    height: 20px;
    margin-top: -12px;
    margin-left: 36px;
    padding: 0 10px;
} */
.iconregister
{
    color: var(--color);
    margin-left: 6em;

}
.flex_title_register
{
    text-align: left;
    font: normal normal medium 14px/26px Roboto !important;
    letter-spacing: 0px;
    color: #041134;
    opacity: 0.5;
}
.flex_sub_register
{
    text-align: left;
font: normal normal medium 16px/26px Roboto;
letter-spacing: 0px;
color: #041134;
opacity: 1;
}
/* 
.download_icon
{
    color:  !important;
    margin-left: 0.2em;

} */

@media screen and (min-device-width: 1025px) and (max-device-width: 1440px) { 
    label.form-label.text-muted.register_app
    {
        margin-right: 49.5em;

    }
    .iconregister
    {
        margin-left: 14em;

    }
    .box
    {
        /* width: 90%; */
    }
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 91%;

    }

}

@media screen and (max-device-width: 1441px)
{
    label.form-label.text-muted.register_app
    {
        margin-left: 1.5em;

    }
    .iconregister
    {
        margin-left: 4em;

    }
    .box
    {
        /* width: 90%; */
    }
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 91%;

    }

}

@media screen and (min-device-width: 1442px) and  (max-device-width: 2559px)
{
    label.form-label.text-muted.register_app
    {
        margin-left: -1em;

    }
    .iconregister
    {
        margin-left: 5em;

    }
    /* .box
    {
        width: 90%;
    } */
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 91%;

    }

}


@media screen and (min-device-width: 2560px)
{
    label.form-label.text-muted.register_app
    {
        margin-right: 49.5em;

    }
    .iconregister
    {
        margin-left: 14em;

    }
    
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 91%;

    }

}

@media screen and (min-device-width:768px ) and (max-device-width: 1024px) { 
    label.form-label.text-muted.register_app
    {
        margin-left: 1.7em;

    }
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 98%;

    }
   
    .iconregister
    {
        margin-left: 1em !important;
    }
}

@media screen and  (min-device-width:425px) and (max-device-width:767px)  { 
    label.form-label.text-muted.register_app
    {
        margin-left: 1.7em;

    }
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 150%;

    }
  
    .iconregister
    {
        margin-top: 2em;
        margin-left: -1em;

    }
    .inner_flex4 {
        flex-direction: column;
            }

}

@media screen and  (max-device-width:375px)  { 
    label.form-label.text-muted.register_app
    {
        margin-left: 1.6em;

    }
   
    .iconregister
    {
        margin-top: 1em;
        margin-left: -1em;

    }
    .d-flex.justify-content-between.m-4.register_app
    {
        width: 208%;

    }
    .inner_flex4 {
flex-direction: column;
    }

}