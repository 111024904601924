@import url('../../../Themes/themes.css');

footer .footer_link,
.footer_subscribe,
.footer_bottom_link,
.footer_rights,
.link-secondary {

    pointer-events: none;
}

.footer_logo  , #socialIcons , .footer_rights{

    cursor: pointer !important;
    pointer-events: all;
}


footer.text-center.text-lg-start {
    background-color: var(--footerbgcolor) !important;
    opacity: 1;
    padding-left: 20px;
    padding-right: 20px;
}

.footer_head_title {
    font-size: var(--titlefontsize);
    font-family: var(--footerfontfamily), bold;
    margin-top: 30px;
}

.footer_sub_heading {

    text-align: left;
    font-size: var(--fontSizeTwfontSizeeighteenenty);
    font-family: var(--fontfamily2);
    letter-spacing: 0.65px;
    color: #FFFFFF;
    opacity: 1;
    width: 100%;
    height: 25px;
    text-align: left;



}

.footer_email {
    width: 320px;
    height: 36px;
    /* margin-left: 6em; */

}

.footer_subscribe {
    width: 90%;
    color: white;
    height: 40px;
    background: var(--color) 0% 0% no-repeat padding-box !important;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    opacity: 1;
    /* padding: var(--saveButtonPadding); */
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizeFourteen);
    font-family: var(--fontfamily2);
    letter-spacing: 0px;
    margin-top: 37px;
}

.footer_subscribe:hover::after {
    content: "→";
    position: absolute;
    top: 15%;
    right: 15px;
}

.footer_subscribe:hover {
    /* width: 90%; */
    background: var(--bluebrand) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--bluebrand) !important;
    border-radius: 20px !important;
    opacity: 1;
    color: white !important;
    transform: translateX(1px);
    transition: 0.8s;
    /* padding: var(--saveButtonPadding); */
    text-align: start;
}

.footer_logo {
    width: 192px;
    height: 192px;
}

.MobileBorder {

    display: none;

}

.footer_link_category {
    width: 100px;
    height: 21px;
    /* UI Properties */
    text-align: left;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizesixteen);
    font-family: var(--fontfamily2);
    letter-spacing: 0.38px;
    color: #FFFFFF;
    opacity: 1;
}

.footer_link {
    width: 80px;
    height: 19px;
    /* UI Properties */
    text-align: left;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontSizeFourteen);
    font-family: var(--fontfamily2);
    text-decoration: none;
    letter-spacing: 0.34px;
    color: #FFFFFF;
    opacity: 1;


}

.footer_category_list {

    width: 13%;
}

.footer_list {

    text-align: initial;
}


.footer_bottom_link>a {
    list-style-type: none;
    color: white;
}

.footer_bottom_ul {
    justify-content: space-around;
    list-style-type: none;
    margin-top: 1.5em;
    margin-bottom: 1.5em !important
}

h5.footer_link_category {
    width: 11%;
}

.footer_icon {
    color: white;
    /* width: 278px; */
    height: 24px;
}

.footer_rights {
    color: white;
    margin-left: 0.5em;

}

.logo_div {
    margin-right: 5em !important;
}

.EMailForm {
    width: 100%;
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9B999A;
    border-radius: 6px;
    opacity: 1;
    margin-top: 40px;
}

#Border_Footer {

    /* width: 97%; */
    height: 0px;
    /* UI Properties */
    border: 1px solid #FFFFFF;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px;

}

.footer_copyrights {

    text-align: right;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0.34px;
    color: #FFFFFF;
    opacity: 1;
}




@media only screen and (max-width: 576px) {

    .footer_head_title {

        text-align: left;
        font: normal normal bold 20px/26px Roboto;
        letter-spacing: 0.72px;
        color: #FFFFFF;
        opacity: 1;
    }

    .footer_sub_heading {


        text-align: left;
        font: normal normal normal 16px/18px Roboto;
        letter-spacing: 0.58px;
        color: #FFFFFF;
        opacity: 1;
    }

    .EMailForm {

        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #9B999A;
        border-radius: 6px;
        opacity: 1;
    }

    .footer_subscribe {

        /* UI Properties */
        width: 100%;
        background: var(--colorRed) 0% 0% no-repeat padding-box;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        opacity: 1;
        margin-top: 15px;


    }

    /* 
    .footer_logo {
        display: none;
    } */

    .MobileBorder {

        display: block !important;

    }

    .footer_category_list {

        width: 100% !important;
    }

    h5.footer_link_category {
        width: 100%;
    }

    li.footer_bottom_link {

        text-align: initial;
        margin-top: 13px;
    }

    .MobileIocns {
        display: flex;
        justify-content: center;
    }

    .footer_bottom_ul {

        flex-direction: column;
    }

    .footer_copyrights {

        text-align: center;
        font: normal normal normal 14px/30px Roboto;
        letter-spacing: 0.34px;
        color: #FFFFFF;
        opacity: 1;

    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /* 
    .footer_category_list {

        width: 19% !important;

    } */
    .footer_category_list {
        width: 16% !important;
    }

    .MobileBorder {

        display: block;
    }

    h5.footer_link_category {
        width: 100%;
    }

    li.footer_bottom_link {

        text-align: initial;
    }

    .footer_bottom_ul {

        flex-direction: column;
    }
}

/* @media screen and (min-width: 1024px)  {
    .footer_category_list{

        width: 16% !important;
    }
    #LogoCont{
        display: none;
    }
    .footer_link {
       
        letter-spacing: -0.66px; }
} */

#socialIcons {
    /* width: 20px; */
    height: 20px;
    cursor: pointer;
    /* UI Properties */
    /* background: #1877F2 0% 0% no-repeat padding-box; */
    opacity: 1;
    margin-left: 13px;
}