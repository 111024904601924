/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  width: 100%;
}

html,
body {
  overflow-x: hidden;
}

.ModelExamForm input[type="text"]::placeholder,
.ModelExamForm input[type="email"]::placeholder,
.ModelExamForm input[type="password"]::placeholder,
.ModelExamForm input[type="number"]::placeholder,
.ModelExamForm input[type="file"]::placeholder,
.ModelExamForm input[type="tel"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.ModelExamForm .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.ModelExamForm .subnav {
  width: 100%;
  height: 21px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.ModelExamForm .subnav ul {
  display: flex;
  padding: 0;
}

.ModelExamForm .subnav .custom-item:active {
  color: var(--subnavActiveItem) !important;
}

.ModelExamForm .subnav .custom-item {
  list-style-type: none;
  padding-left: 10px;
  margin-left: 20px;
  width: 31px;
  height: 17px;
  /* UI Properties */
  text-align: var(--subnavTextAlign);
  font: normal normal var(--subnavFontWeight) var(--subnavFontsize) var(--subnavFontFamily);
  letter-spacing: 0px;
  color: var(--subnavItemColor);
  opacity: 1;
  margin-top: 2px;
}

.ModelExamForm .subnav .material-icons {
  width: 5px;
  height: 8px;
  font-size: var(--subnavIconsSize);
  margin-top: 1px;
}

.ModelExamForm .Title {
  width: fit-content;
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 70px; */
  margin-top: 20px;
}

.ModelExamForm .progressTitle {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;
  /* width: 1166px; */
  height: 80px;
  opacity: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* //active card */

.ModelExamForm .progressTitle .Card {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ModelExamForm .progressTitle .Card .circle {
  width: 40px;
  height: 40px;
  background: var(--progressCardCircleColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--colorRed); */
  opacity: 1;
  border-radius: 50%;
  color: var(--progressCardCircleTextColor);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0;
}

.ModelExamForm .progressTitle .Card .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  margin-left: 300px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.ModelExamForm .progressTitle .Card .Stepborders {
  width: var(--progressstepborderWidth);
  height: 0px;
  border: 1px solid var(--progressActivestepborder);
  opacity: 1;
  position: absolute;
  margin-left: 202px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressActivestepborder);
}

.ModelExamForm .progressTitle .Card .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
  margin: 2px;
}

.ModelExamForm .progressTitle .Card .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--labelweight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

/* //inactive card */

.ModelExamForm .progressTitle .Cards {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ModelExamForm .progressTitle .Cards .circle {
  width: 40px;
  height: 40px;
  cursor: pointer;
  /* UI Properties */
  background: var(--progressCardCircleInactiveColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--progressCardBorder);
  opacity: 0.2;
  border-radius: 50%;
  color: var(--progressCardCircleInactiveTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.ModelExamForm .progressTitle .Cards .Stepborder {
  width: var(--progressstepborderWidth);
  height: 0px;
  /* transform: matrix(0, 1, -1, 0, 0, 0); */
  border: 1px solid var(--progressStepBorder);
  opacity: 0.2;
  position: absolute;
  /* line-height: 16px; */
  /* left: 250px; */
  margin-left: 300px;
  margin-top: 18px;
  border-bottom: 1px solid var(--progressStepBorder);
}

.ModelExamForm .progressTitle .Cards .cTitle {
  /* width: 106px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium var(--progressCardTitleFS) var(--progressCardTitle);
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin: 2px;
  width: max-content;
}

.ModelExamForm .progressTitle .Cards .cSubtitle {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: var(--progressCardFontsize);
  font-family: var(--progressCardSubTitle);
  font-weight: var(--progressCardInactiveWeight);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
  margin: 3px;
}

.ModelExamForm .StepOne .CancelButton,
.ModelExamForm .sectionThree .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.ModelExamForm .StepOne .SaveButton,
.ModelExamForm .sectionThree .SaveButton {
  width: var(--savebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.ModelExamForm .StepOne .SaveButton:hover::after,
.ModelExamForm .sectionTwo .SaveButton:hover::after,
.ModelExamForm .sectionThree .SaveButton:hover::after,
.ModelExamForm .sectionFour .SaveButton:hover::after,
.ModelExamForm .sectionFive .SaveButton:hover::after,
.ModelExamForm .sectionSix .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.ModelExamForm .StepOne .SaveButton:hover,
.ModelExamForm .sectionTwo .SaveButton:hover,
.ModelExamForm .sectionThree .SaveButton:hover,
.ModelExamForm .sectionFour .SaveButton:hover,
.ModelExamForm .sectionFive .SaveButton:hover,
.ModelExamForm .sectionSix .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(20px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.ModelExamForm .StepOne .SaveButton_Prev,
.ModelExamForm .sectionTwo .SaveButton_Prev,
.ModelExamForm .sectionThree .SaveButton_Prev,
.ModelExamForm .sectionFour .SaveButton_Prev,
.ModelExamForm .sectionFive .SaveButton_Prev,
.ModelExamForm .sectionSix .SaveButton_Prev {
  /* width: var(--modelExamWidth); */
  height: var(--saveButtonHeight);
  /* UI Properties */
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.ModelExamForm .StepOne .SaveButton_Prev:hover::before,
.ModelExamForm .sectionTwo .SaveButton_Prev:hover::before,
.ModelExamForm .sectionThree .SaveButton_Prev:hover::before,
.ModelExamForm .sectionFour .SaveButton_Prev:hover::before,
.ModelExamForm .sectionFive .SaveButton_Prev:hover::before,
.ModelExamForm .sectionSix .SaveButton_Prev:hover::before {
  content: var(--saveButtonBeforIcon);
  position: absolute;
  /* top: 0%; */
  left: -25px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-left: 5px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.ModelExamForm .StepOne .SaveButton_Prev:hover,
.ModelExamForm .sectionTwo .SaveButton_Prev:hover,
.ModelExamForm .sectionThree .SaveButton_Prev:hover,
.ModelExamForm .sectionFour .SaveButton_Prev:hover,
.ModelExamForm .sectionFive .SaveButton_Prev:hover,
.ModelExamForm .sectionSix .SaveButton_Prev:hover {
  height: 40px;
  /* UI Properties */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);

  padding: var(--saveButtonPaddingHover);
  transform: translateX(12px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: end;
}

.ModelExamForm .StepOne .SaveButton_Prev a,
.ModelExamForm .sectionTwo .SaveButton_Prev a,
.ModelExamForm .sectionThree .SaveButton_Prev a,
.ModelExamForm .sectionFour .SaveButton_Prev a,
.ModelExamForm .sectionFive .SaveButton_Prev a,
.ModelExamForm .sectionSix .SaveButton_Prev a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  width: max-content;
  opacity: 1;
}

.ModelExamForm .StepOne .SaveButton_Prev:hover a,
.ModelExamForm .sectionTwo .SaveButton_Prev:hover a,
.ModelExamForm .sectionThree .SaveButton_Prev:hover a,
.ModelExamForm .sectionFour .SaveButton_Prev:hover a,
.ModelExamForm .sectionFive .SaveButton_Prev:hover a,
.ModelExamForm .sectionSix .SaveButton_Prev:hover a {
  color: #ffffff;
}

.ModelExamForm .radioGroup .formCheck {
  margin-bottom: 20px;
  margin-left: 19px;
}

.ModelExamForm .radioGroup .formCheck .checkbox {
  width: var(--inputCheckboxwidth);
  height: var(--inputCheckboxheight);
  /* UI Properties */
  background: var(--inputcheckboxBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inputcheckboxBordercolor);
  opacity: 0.5;
}

.ModelExamForm .radioGroup .formCheck .checkbox-label {
  height: 19px;
  /* UI Properties */
  text-align: var(--checkboxlabelalign);
  font: normal normal var(--checkboxlabelweight) var(--checkboxlabelSize) var(--fontfamily2) !important;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
  margin-right: 17px;
  vertical-align: super;
}

.ModelExamForm .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.ModelExamForm .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionSix .box .forms .Inputs_Phone,
.ModelExamForm .sectionThree .box .forms .Inputs_Phone {
  height: 36px;
  width: 97%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px !important;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.ModelExamForm .StepOne .box .forms .InputsFile,
.ModelExamForm .sectionTwo .box .forms .InputsFile,
.ModelExamForm .sectionThree .box .forms .InputsFile,
.ModelExamForm .sectionFour .box .forms .InputsFile,
.ModelExamForm .sectionFive .box .forms .InputsFile,
.ModelExamForm .sectionSix .box .forms .InputsFile,
.ModelExamForm .sectionPhoto .box .forms .InputsFile {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionTwo .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.ModelExamForm .sectionTwo .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.ModelExamForm .sectionTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionTwo .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionTwo .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

/* ///section three */

.ModelExamForm .sectionThree .box {
  /* width: 1280px; */
  height: 220px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionThree .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */

  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionThree .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionThree .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionThree .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  line-height: var(--LineHeight);
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

/* //section four 
 */

.ModelExamForm .sectionFour .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionFour .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionFour .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionFour .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionFour .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 6px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.ModelExamForm .sectionFour .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionFour .box .final {
  width: 169px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionFour .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.ModelExamForm .sectionFour .SaveButton {
  width: var(--modelExamsavebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //sectio five */

.ModelExamForm .sectionFive .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .sectionFive .box .AddMore,
.ModelExamForm .sectionTwo .box .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer;
  margin-top: 16px;
}

.ModelExamForm .sectionFive .box .AddMore .material-icons,
.ModelExamForm .sectionTwo .box .AddMore .material-icons {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.ModelExamForm .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.ModelExamForm .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
}

.ModelExamForm .sectionFive .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionFive .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionFive .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionFive .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.ModelExamForm .sectionFive .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
  margin-top: 10px;
}

.ModelExamForm .sectionFive .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionFive .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.ModelExamForm .sectionFive .SaveButton {
  width: var(--modelExamsavebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModelExamForm .sectionTwo .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;

  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.ModelExamForm .sectionTwo .SaveButton {
  width: var(--modelExamsavebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //section Six */

.ModelExamForm .sectionSix .box {
  /* width: 1280px; */
  height: fit-content;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ModelExamForm .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.ModelExamForm .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--fontfamily2);
}

.ModelExamForm .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

/* ------------------------SectionOne--------------- */
/* Chrome, Safari, Edge, Opera */
.ModelExamForm .SectionOne .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .SectionOne .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .SectionOne .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------sectionTwo--------------------- */
.ModelExamForm .sectionTwo .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .sectionTwo .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .sectionTwo .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------------SectionThree-------------------- */
/* Chrome, Safari, Edge, Opera */
.ModelExamForm .SectionThree .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .SectionThree .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .SectionThree .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------------SectionFour-------------------- */
/* Chrome, Safari, Edge, Opera */
.ModelExamForm .SectionFour .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .SectionFour .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .SectionFour .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------sectionFive--------------------- */
/* Chrome, Safari, Edge, Opera */
.ModelExamForm .sectionFive .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .sectionFive .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .sectionFive .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------sectionSix---------------- */
.ModelExamForm .sectionSix .box .forms .Inputs::-webkit-outer-spin-button,
.ModelExamForm .sectionSix .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ModelExamForm .sectionSix .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------- */

.ModelExamForm .sectionSix .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--FieldTitleBoxColor);
  padding: var(--fieldStepTitlePadding);
  margin-top: -12px;
  margin-left: 10px;
}

.ModelExamForm .sectionSix .box .forms {
  display: flex;
  flex-direction: column;
}

.ModelExamForm .sectionSix .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin-top: 25px;
  margin-left: 20px;
}

.ModelExamForm .sectionSix .box .forms .InputsFocus {
  height: 36px;
  border: 1px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: 2px;
  font-family: var(--fontfamily2);
  margin-top: 10px;
}

.ModelExamForm .sectionSix .box .forms .Inputs {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
}

.ModelExamForm .sectionSix .box .final {
  width: 160px;
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 16px/21px var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.ModelExamForm .sectionSix .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.ModelExamForm .sectionSix .SaveButton {
  width: var(--modelExamsavebuttonWidth);
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModelExamForm .sectionSix .Checke {
  position: relative;
  bottom: 28px;
}

.BatchImage img {
  width: var(--batchImgWidth);
  height: var(--batchImgHeight);
  border-radius: var(--batchImgradius);
}

.ModelExamForm .BatchImage .title {
  /* height: 21px; */
  width: max-content;
  text-align: var(--batchTextalign);
  font: normal normal var(--batchtextweight) var(--batchTitlesize) var(--fontfamily2);
  letter-spacing: var(--batchtextletterspacing);
  color: var(--batchtextcolor);
  opacity: 1;
}

.BatchImage .subtitle {
  /* height: 16px; */
  text-align: var(--batchTextalign);
  font-size: var(--batchsubsize);
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
}

.ModelExamForm .BatchImage .subtitles {
  width: max-content;
  /* height: 16px; */
  text-align: var(--batchTextalign);
  font-size: 11px;
  text-decoration: underline;
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
}

.IconsImage {
  list-style-type: none;
  color: var(--iconsTextcolor);
  display: flex;
  align-items: flex-start;
}

.IconsImage .title {
  text-align: var(--iconstextalign);
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: var(--iconstextopacity);
  margin: 1px;
  font-size: var(--iconstextsize);
  font-family: var(--fontfamily2);
}

.IconsImage .subtitle {
  text-align: left;
  letter-spacing: 0px;
  color: var(--iconsTitlecolor);
  opacity: 1;
  margin: 0;
  font-size: var(--iconssubtextsize);
  font-family: var(--fontfamily2);
}

.ModelExamForm .errors {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 5px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
  margin-bottom: 10px;
}

.ModelExamForm .sectionTwo .box .forms .Inputs:focus-visible,
.ModelExamForm .sectionThree .box .forms .Inputs:focus-visible,
.ModelExamForm .sectionFour .box .forms .Inputs:focus-visible,
.ModelExamForm .sectionFive .box .forms .Inputs:focus-visible,
.ModelExamForm .sectionSix .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  font-family: var(--fontfamily2);
  color: black;
}

.ModelExamForm .sectionTwo .box .forms .Inputs::placeholder .ModelExamForm .StepOne .box .forms .Inputs::placeholder .ModelExamForm .sectionThree .box .forms .Inputs::placeholder .ModelExamForm .sectionFour .box .forms .Inputs::placeholder .ModelExamForm .sectionFive .box .forms .Inputs::placeholder .ModelExamForm .sectionSix .box .forms .Inputs::placeholder {
  text-align: center;
  font: normal 14px/19px !important;
  font-family: var(--fontfamily2) !important;
  letter-spacing: 2px !important;
  outline: none;
  color: #041134 !important;
  opacity: 0.6;
  font-family: var(--fontfamily2);
}

.display_none {
  display: "";
}

@media only screen and (max-width: 576px) {
  .widthForm {
    width: 100% !important;
  }

  .mb-mobile {
    margin-bottom: 20px;
  }

  .mHide {
    display: none;
  }

  .widthForms {
    width: 100% !important;
  }

  .ModelExamForm .BatchImage .title {
    width: 100%;
  }

  .display_none {
    display: none;
  }

  .ModelExamForm .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* .display_none_Stepborder {

        display: none;
    } */

  /* .heading {

        flex-direction: column;
    } */

  /* .heading .smButton {

        flex-direction: column;
        margin: 0px !important;

    } */

  .heading button {
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .ModelExamForm .StepOne .SaveButton,
  .ModelExamForm .sectionTwo .SaveButton,
  .ModelExamForm .sectionThree .SaveButton,
  .ModelExamForm .sectionFour .SaveButton,
  .ModelExamForm .sectionFive .SaveButton,
  .ModelExamForm .sectionSix .SaveButton {
    width: max-content;
  }

  .ModelExamForm .StepOne .SaveButton_Prev,
  .ModelExamForm .sectionTwo .SaveButton_Prev,
  .ModelExamForm .sectionThree .SaveButton_Prev,
  .ModelExamForm .sectionFour .SaveButton_Prev,
  .ModelExamForm .sectionFive .SaveButton_Prev,
  .ModelExamForm .sectionSix .SaveButton_Prev {
    width: max-content;
  }

  .ModelExamForm .StepOne .box,
  .ModelExamForm .sectionTwo .box,
  .ModelExamForm .sectionThree .box,
  .ModelExamForm .sectionFour .box,
  .ModelExamForm .sectionFive .box,
  .ModelExamForm .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .ModelExamForm .StepOne .box .forms label,
  .ModelExamForm .sectionTwo .box .forms label,
  .ModelExamForm .sectionThree .box .forms label,
  .ModelExamForm .sectionFour .box .forms label,
  .ModelExamForm .sectionFive .box .forms label,
  .ModelExamForm .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--labelfont) !important;
  }

  .ModelExamForm .StepOne .box .forms .Inputs,
  .ModelExamForm .sectionTwo .box .forms .Inputs,
  .ModelExamForm .sectionThree .box .forms .Inputs,
  .ModelExamForm .sectionFour .box .forms .Inputs,
  .ModelExamForm .sectionFive .box .forms .Inputs,
  .ModelExamForm .sectionSix .box .forms .Inputs {
    margin-left: 0px !important;
  }

  .ModelExamForm .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }

  .ModelExamForm .StepOne .box .forms .InputsFile,
  .ModelExamForm .sectionTwo .box .forms .InputsFile,
  .ModelExamForm .sectionThree .box .forms .InputsFile,
  .ModelExamForm .sectionFour .box .forms .InputsFile,
  .ModelExamForm .sectionFive .box .forms .InputsFile,
  .ModelExamForm .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
  }

  .ModelExamForm .sectionTwo .box,
  .ModelExamForm .sectionThree .box,
  .ModelExamForm .sectionFour .box,
  .ModelExamForm .sectionFive .box,
  .ModelExamForm .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .ModelExamForm .subnav .custom-item {
    margin-left: 0px;
    width: 100%;
  }

  .ModelExamForm .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
  }

  .ModelExamForm .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
  }

  .ModelExamForm .sectionSix .box .StepTitle {
    margin-left: -19px;
  }

  .ModelExamForm .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .ModelExamForm #radiobuttons {
    flex-direction: column;
  }

  .ModelExamForm .subnav {
    display: none;
  }

  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }

  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .ModelExamForm .subnav {
    display: none;
  }
}

/* //small devies */
/* @media only screen and (min-width: 576px) and (max-width: 768px) {

    .display_none{

        display: none;
    }

    .display_none_Stepborder{

        display: none;
    }
    
 } */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet devices */
  /* .display_none_Stepborder {

        display: "";
    } */

  .ModelExamForm #radiobuttons {
    flex-direction: column;
  }

  .ModelExamForm .progressTitle .Card .cTitle,
  .ModelExamForm .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .ModelExamForm .progressTitle .Card .cSubtitle,
  .ModelExamForm .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }

  .ModelExamForm .progressTitle .Card .Stepborders {
    width: 32px;
    height: 0px;
    border: 1px solid var(--progressActivestepborder);
    opacity: 1;
    position: absolute;
    margin-left: 105px;
    margin-top: 18px;
    border-bottom: 1px solid var(--progressActivestepborder);
  }

  .ModelExamForm .progressTitle .Card .Stepborder,
  .ModelExamForm .progressTitle .Cards .Stepborder {
    width: 24px !important;

    margin-left: 102px !important;
  }

  .ModelExamForm .sectionThree .box .forms label {
    height: 28px;
  }

  .ModelExamForm .StepOne .SaveButton_Prev,
  .ModelExamForm .sectionTwo .SaveButton_Prev,
  .ModelExamForm .sectionThree .SaveButton_Prev,
  .ModelExamForm .sectionFour .SaveButton_Prev,
  .ModelExamForm .sectionFive .SaveButton_Prev,
  .ModelExamForm .sectionSix .SaveButton_Prev {
    width: 100% !important;
    font-size: 11px !important;
  }

  .IconsImage .title {
    font-size: 11px !important;
  }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
  /* styles here */
}

.card .BatchImage {
  text-align: start !important;
}

.IconsImage .material-icons {
  font-size: 19px;
  line-height: 2 !important;
  margin-top: 10px;
}

.ModelExamForm .SaveButtons {
  /* width: 10%; */
  height: 20px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModelExamForm .SaveButtons a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ModelExamForm .SaveButton a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ModelExamForm .SaveButton:hover a {
  color: #ffffff;
}

.ModelExamForm .SaveButtons:hover a {
  color: #ffffff;
}

/* AIASA-000110  - v1  issues solved by lalitha */

.ModelExamForm .ProfilePhoto {
  width: 250px;
  height: 250px;
  transform: none;
  /* UI Properties */
  background: #02041134 0% 0% no-repeat padding-box;
  border: 1px solid #02041134;
  border-radius: 125px;
  /* opacity: 0.2; */
  margin-top: 45px;
  margin-bottom: 36px;
}

.ModelExamForm #text {
  position: relative;
  bottom: 50%;
  left: 32%;
  font-weight: var(--loginH1fontweight);
  font-family: var(--fontfamily2);
}

.ModelExamForm .profileText {
  text-align: left;
  font: normal normal var(--loginH1fontweight) 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
}

.ModelExamForm .warningOne {
  text-align: justify;
  font: normal normal var(--normalFontWeight) 12px/20px var(--fontfamily2);
  letter-spacing: 0px;
  color: #000000;
}

.ModelExamForm .warningTwo {
  text-align: justify;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: 12px;
  line-height: 20px;
}

/* //sectionprofile */

.ModelExamForm #border {
  text-align: left;
  font: normal normal medium 18px/26px var(--fontfamily2);
  border: 1px solid #041134 !important;
  letter-spacing: 0.07px;
  color: #041134;
  opacity: 1;
  height: 16px;
  margin-top: 4px;
}

.ModelExamForm .commanInfo #border {
  margin-left: 8px;
}

.ModelExamForm .commanInfo {
  display: flex;
  /* margin-left: auto; */
}

.ModelExamForm .commanInfo li {
  list-style-type: none;
  position: relative;
  left: 134px;
}

.ModelExamForm .commanInfo #number small {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal bold 12px/16px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
}

.ModelExamForm .commanInfo #number #tex {
  text-align: left;
  font: normal normal bold 12px/16px var(--fontfamily2);
  letter-spacing: 0.48px;
  color: #041134;
  opacity: 1;
}

#profilephotos {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: grey;
  margin-top: 9px;
}

#profilephotos img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

/* .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
    background: var(--colorRed) !important;
    border: 2px solid var(--colorRed) !important;  } */

.widthForm {
  width: 49%;
}

.widthForms {
  width: 95%;
}

#Verifycheckbox {
  text-align: left;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
}

/* .stepSeven{
    
    width: 600px;

} */
.ptitle {
  text-align: center;
  letter-spacing: 0.18px;
  color: #041134;
  opacity: 1;
  font-weight: bold;
}

.psubtitle {
  /* width: 650px; */
  /* height: 21px; */
  margin: 0px 20px;
  text-align: center;
  letter-spacing: NaNpx;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.sectionSeven {
  margin-top: 81px;
  margin-bottom: 120px;
}

.ModelExamForm .ReviewLabel {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.ModelExamForm .ReviewLabels {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: rgb(220, 53, 69);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.ModelExamForm .ReviewLabelss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

/* .download_icon{


    color:rgb(220,53,69) ;
} */

.ModelExamForm a {
  text-decoration: none;
}

.modelexam_payment_card {
  width: 97%;
  margin-left: 1.2em;
}

/* 
.radiobutton {
    display: inline-block;
    appearance: none;
    border: 1px solid #ccc;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    outline: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.radiobutton:checked:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radiobutton:focus {
    border-color: #999;
}

.radiobutton:checked {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
} */

.ModelExamForm .SaveButtons {
  /* width: var(--facultySaveButton); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.ModelExamForm .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;

  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);

  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.ModelExamForm .SaveButtons:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

@media only screen and (max-width: 576px) {
  .widthForm {
    width: 100% !important;
  }

  .widthForms {
    width: 100% !important;
  }

  .display_none {
    display: none;
  }

  .AdmissionForm .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* .display_none_Stepborder {

        display: none;
    } */
  /* 
    .heading {

        flex-direction: column;
    } */

  /* .heading .smButton {

        flex-direction: column;
        margin: 0px !important;

    } */

  .heading button {
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .ModelExamForm .StepOne .SaveButton,
  .ModelExamForm .sectionTwo .SaveButton,
  .ModelExamForm .sectionThree .SaveButton,
  .ModelExamForm .sectionFour .SaveButton,
  .ModelExamForm .sectionFive .SaveButton,
  .ModelExamForm .sectionSix .SaveButton {
    width: 100%;
    margin-top: 20px;
  }

  .ModelExamForm .progressTitle .Card .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 19% !important;
  }

  .ModelExamForm .progressTitle .Cards .Stepborder {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 17% !important;
  }

  .ModelExamForm .progressTitle .Card .Stepborders {
    width: var(--progressstepborderMobileWidth) !important;
    margin-left: 20% !important;
  }

  .ModelExamForm .StepOne .SaveButton_Prev,
  .ModelExamForm .sectionTwo .SaveButton_Prev,
  .ModelExamForm .sectionThree .SaveButton_Prev,
  .ModelExamForm .sectionFour .SaveButton_Prev,
  .ModelExamForm .sectionFive .SaveButton_Prev,
  .ModelExamForm .sectionSix .SaveButton_Prev {
    width: 100%;
    margin-top: 20px;
  }

  .ModelExamForm .StepOne .CancelButton,
  .ModelExamForm .sectionThree .CancelButton,
  .ModelExamForm .sectionFour .CancelButton,
  .ModelExamForm .sectionFive .CancelButton,
  .ModelExamForm .sectionSix .CancelButton,
  .ModelExamForm .StepTwo .CancelButton {
    margin-bottom: 20px;
  }

  .ModelExamForm .StepOne .box,
  .ModelExamForm .sectionTwo .box,
  .ModelExamForm .sectionThree .box,
  .ModelExamForm .sectionFour .box,
  .ModelExamForm .sectionFive .box,
  .ModelExamForm .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    width: 100%;
  }

  .ModelExamForm .StepOne .box .forms label,
  .ModelExamForm .sectionTwo .box .forms label,
  .ModelExamForm .sectionThree .box .forms label,
  .ModelExamForm .sectionFour .box .forms label,
  .ModelExamForm .sectionFive .box .forms label,
  .ModelExamForm .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--labelfont) !important;
  }

  .ModelExamForm .StepOne .box .forms .Inputs,
  .ModelExamForm .sectionTwo .box .forms .Inputs,
  .ModelExamForm .sectionThree .box .forms .Inputs,
  .ModelExamForm .sectionFour .box .forms .Inputs,
  .ModelExamForm .sectionFive .box .forms .Inputs,
  .ModelExamForm .sectionSix .box .forms .Inputs {
    margin-left: 0px !important;
  }

  .ModelExamForm .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }

  .ModelExamForm .StepOne .box .forms .InputsFile,
  .ModelExamForm .sectionTwo .box .forms .InputsFile,
  .ModelExamForm .sectionThree .box .forms .InputsFile,
  .ModelExamForm .sectionFour .box .forms .InputsFile,
  .ModelExamForm .sectionFive .box .forms .InputsFile,
  .ModelExamForm .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .ModelExamForm .sectionTwo .box,
  .ModelExamForm .sectionThree .box,
  .ModelExamForm .sectionFour .box,
  .ModelExamForm .sectionFive .box,
  .ModelExamForm .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .ModelExamForm .subnav .custom-item {
    margin-left: 0px;
    width: 100%;
  }

  .ModelExamForm .SaveButtons {
    width: 100% !important;
  }

  .ModelExamForm .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
    display: none;
  }

  .ModelExamForm .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
    display: none;
  }

  /* .ModelExamForm .sectionSix .box .StepTitle {

        margin-left: -19px;
    } */
  .ModelExamForm .progressTitle .Cards .cTitle,
  .ModelExamForm .progressTitle .Cards .cSubtitle {
    display: none;
  }

  .ModelExamForm .sectionSix .box .StepTitle {
    margin-left: 5px;
  }

  .ModelExamForm .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ModelExamForm .ReviewLabels {
    margin-left: 0px !important;
  }

  .ModelExamForm .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .ModelExamForm #radiobuttons {
    flex-direction: column;
  }

  .ModelExamForm .subnav {
    display: none;
  }

  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }

  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }
}

#modelexamcurr {
  position: relative;
  /* bottom: 70%; */
  left: -16px;
  color: var(--colorRed);
}

#mockinmcurr {
  position: relative;
  left: -24px;
  color: var(--colorRed);
}

#selectbutton {
  position: relative;
  bottom: 24px;
}

#BorderBathc {
  border-bottom: 1px solid rgb(242, 240, 240);
}

/* .form-check-label  {
    margin-top: 25px !important;
  } */

.ModelExamForm .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer !important;
  margin-top: 16px;
  font-size: 14px;
}

/* New added (mODEL Exam) */
.border-exam,
.border-exam-short {
  border: var(--bs-border-width) var(--bs-border-style) #c1393d !important;
  opacity: 0.2;
}

.border-exam-short {
  width: 93% !important;
  margin-left: 23px;
}

.ModelExamForm .BatchImage .subtitle_testpdf {
  /* height: 16px; */
  color: var(--colorRed) !important;
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  text-align: var(--batchTextalign);
  /* font-size: var(--batchsubsize); */
  font-size: 12px;
  /* New change */
  font-family: var(--fontfamily2);
  letter-spacing: var(--batchsubletterspacing);
  color: var(--batchSubtext);
  opacity: 1;
  text-decoration: underline;
}

.st_confirm_outer {
  border: 2px solid var(--color-secondary-border);
  /* margin: 15px 10px; */
  border-radius: var(--border-radius-md);
}

.st_confirm_top_img {
  width: 130px;
  height: 130px;
  border-radius: 8px;
}

.st_confirm_icon {
  width: 20px;
  height: 24px;
}

.st_confirm_icon_color {
  color: var(--color-brand-800);
}

.st_confirm_top {
  /* justify-content: center; */
  align-items: center;
  padding: 20px;
}

.st_confirm_top .title-t {
  color: var(--color-brand-800);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  margin-left: 10px;
}

.amount_details {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 10px;
  column-gap: 5px;
}

.st_confirm_label {
  font-size: 14px;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
  padding: 0px;
  margin-bottom: 5px;
}

.st_confirm_value {
  font-size: 16px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  padding: 0px;
  margin-bottom: 5px;
}

/* ---- */
.st_confirm_check {
  font-size: 14px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  padding: 0px;
  margin-bottom: 5px;
}

.st_confirm_bottom {
  border: 2px solid var(--color-secondary-border);
  padding: 10px;
  margin: 30px 10px;
  border-radius: var(--border-radius-md);
  margin-top: 10px;
}

.st_confirm_inner {
  border: 2px solid var(--color-secondary-border);
  padding: 10px;
  /* margin: 30px 10px; */
  border-radius: var(--border-radius-md);
  margin-top: 10px;
}

.st_confirm_link {
  border: none;
  background: none;
  /* border-bottom: 1px solid var(--color-brand-800); */
  font-size: 12px;
  color: var(--color-brand-800);
  font-weight: var(--font-weight-bold);
  text-align: start;
  padding: 0;
}

.inner_box_list_item {
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(4 17 52 / 16%);
}

@media (min-width: 320px) {
  /*xs smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /*sm portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 768px) {

  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  /* .st_confirm_outer {
    margin: 30px 20px;
  } */
  .amount_details {
    margin-top: 0px;
  }

  .st_confirm_inner {
    padding: 20px;
    margin: 0px 20px 10px 20px;
  }
}

@media (min-width: 961px) {
  /*lg tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /*xl big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /*xxl hi-res laptops and desktops */
}

.border-exam-short_stepss {
  width: 93% !important;
  margin-left: 23px;
  border: var(--bs-border-width) var(--bs-border-style) var(--colorBlue) !important;
  opacity: 0.2;
}

.border-exam-short_stepssA {
  width: 93% !important;
  margin-left: 23px;
  border: var(--bs-border-width) var(--bs-border-style) var(--colorBlue) !important;
  opacity: 0.2;
}

.GreenHallStatus {
  background: var(--colorGreen) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorGreen);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 12px 5px 12px;
}

.YellowHallStatus {
  background: var(--colorYellow) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorYellow);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 12px 5px 12px;
}

.PinkHallStatus {
  background: var(--colorPink) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorPink);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 12px 5px 12px;
}

.GreyHallStatus {
  background: var(--colorSemantic) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorSemantic);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 12px 5px 12px;
}

.GreenHallStatusFilBox {
  background: var(--colorGreen) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorGreen);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  padding: 3px 8px 3px 6px;
}

.PinkHallStatusFilBox {
  background: var(--colorPink) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorPink);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  padding: 3px 8px 3px 6px;
}

.GreyHallStatusFilBox {
  background: var(--colorSemantic) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorSemantic);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  padding: 3px 8px 3px 6px;
}

.YellowHallStatusFilBox {
  background: var(--colorYellow) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorYellow);
  border-radius: 3px;
  opacity: 1;
  text-align: left;
  font-size: var(--fontsizetwevele);
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  padding: 3px 8px 3px 6px;
}