/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

.PasswordForm {
    background-image: var(--bgimage) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* height: 100vh; */
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 768px;
    /* UI Properties */
    background: var(--bgcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--bgborder);
    opacity: 0.9;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .PasswordForm {
        background-size: 100% 100% !important;
    }
}

#wrapper_Forget_Password .box {
    text-align: center;
    padding: var(--forgetboxpadding);
    width: var(--forgetboxwidth);
    height: var(--forgetboxheight);
    position: relative;
    margin: auto;
    top: 100px;
    background: var(--forgetbackground) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--forgetbox-shadow);
    border: 1px solid var(--forgetborder);
    border-radius: 8px;
    opacity: 1;
}

#wrapper_Forget_Password .box h1 {
    margin-top: 10px;
    text-align: center;
    font: normal normal var(--boldtextWeight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
}

#wrapper_Forget_Password .box .reg {
    /* width: 109px; */
    height: var(--regtextheight);
    text-align: var(--regtextalign);
    font: normal normal var(--normalFontWeight) var(--regtextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginBluecolor);
    opacity: 1;
    /* margin-top: 42px;
      margin-left: 90px; */
}

#wrapper_Forget_Password .box .SignupText {
    /* margin-top: 30px; */

    margin: 30px;
}

#wrapper_Forget_Password .box .reg .rega {
    /* width: 68px; */
    height: var(--loginregatextheight);
    text-align: var(--loginregatextalign);
    text-decoration: underline;
    font: normal normal var(--loginregatextweight) var(--loginregatextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginRedColor);
    opacity: 1;
    margin-top: 30px;
    margin-left: 5px !important;
}

/* #wrapper_Forget_Password .box  input[type=text]::placeholder,
   #wrapper_Forget_Password .box  input[type=email]::placeholder,
   #wrapper_Forget_Password .box  input[type=password]::placeholder,
   #wrapper_Forget_Password .box  input[type=number]::placeholder
   #wrapper_Forget_Password .box  input[type=file]::placeholder{
      font-family: var(--fontfamily2);
      text-align: left;
      font-size: var(--fontSizeFourteen);
      font-weight: var(--boldtextWeight);
      letter-spacing: 0px;
      color: var(--colorBlue);
      opacity: 1;
  
  } */

#wrapper_Forget_Password .box .ForgetInputs::placeholder {
    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.8;
}

.ForgetInputs:focus-visible {
    background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
    /* border: 2px solid var(--focusInputBordercolor); */
    border: 1px solid var(--bluebrand) !important;
    border-radius: 6px;
    opacity: 1;
    padding: var(--focusInputpadding);
    outline: none;
    margin-top: 10px;
    color: var(--focusInputcolor);
    font-family: var(--fontfamily2);
}

#wrapper_Forget_Password .box input[type="email"],
.box input[type="password"] {
    width: var(--loginInputBoxWidth);
    height: var(--loginInputBoxHeight);
    padding: var(--loginInputBoxPadding);
    background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--loginInputBoxborder);
    border-radius: 6px;
    opacity: 1;
    margin-top: 20px;
    font-family: var(--fontfamily2);
}

#wrapper_Forget_Password .box .LoginBtn {
    width: var(--loginbuttonWidth);
    height: var(--loginButtonHeight);
    background: var(--loginRedColor) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: var(--loginWhiteColor);
    font: normal normal var(--normalFontWeight) var(--loginbuttoncolorsize) var(--fontfamily2);
    padding: var(--loginbuttoncolorPadding);
    border: 2px solid var(--loginRedColor);
    margin-top: 31px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

#wrapper_Forget_Password .box .LoginBtn:hover {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    background: var(--bluebrand);
    border: 2px solid var(--bluebrand);
    color: white !important;

    /* text-indent: -20px; */
    padding-left: 29px;
    text-align: left;
}

#wrapper_Forget_Password .box .LoginBtn::after {
    content: var(--saveButtonAfterIcon);
    font-weight: bold;
    position: absolute;
    border-width: 8px 8px 0 8px;
    bottom: 8px;
    color: white !important;
    right: 2%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s ease;
}

#wrapper_Forget_Password .box .LoginBtn:hover::after {
    opacity: 1;
    font-size: 24px;
    font-weight: bold;
}

#wrapper_Forget_Password .box p {
    width: 252px;
    height: 37px;
    text-align: var(--login_p_textalign);
    font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
    letter-spacing: var(--login_p_letter_spacing);
    color: var(--login_p_textcolor);
    opacity: 1;
    margin-top: 10px;
    margin-left: 54px;
}

#wrapper_Forget_Password .box a {
    width: 91px;
    height: 17px;

    text-align: end;
    text-decoration: var(--login_a_decoration);
    font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--bluebrand);
    opacity: 1;
    margin-top: 17px;
    margin-left: 116px;
}

#wrapper_Forget_Password .box .BackLogin a {
    text-align: left;
    font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--bluebrand);
    opacity: 1;
    margin-top: 30px;
    margin-left: 0px !important;
}

#wrapper_Forget_Password .box .copyRights {
    /* width: 135px; */
    height: 17px;

    text-align: var(--login_copyrightstextalign);
    font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--login_copyrightscolor);
    opacity: 1;
    margin-top: 30px;
    margin-left: 30px;
}

#wrapper_Forget_Password .box .BackLogin {
    text-align: left;
    font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginRedColor);
    opacity: 1;
    margin-top: 30px;
    margin-left: 124px;
}

#wrapper_Forget_Password #img {
    margin-top: 30px;
    width: var(--loginLogoWidthHeight);
    height: var(--loginLogoWidthHeight);
    opacity: 1;
}

@media only screen and (max-width: 576px) {
    #wrapper_Forget_Password img {
        height: 100%;
    }

    #wrapper_Forget_Password .box {
        text-align: center;
        padding: var(--forgetboxpadding);
        width: var(--forgetboxwidth);
        height: var(--forgetboxheight);
        position: relative;
        margin: auto;
        top: 100px;
        background: var(--forgetbackground) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px var(--forgetbox-shadow);
        border: 1px solid var(--forgetborder);
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Forget_Password .box .LoginBtn {
        width: 100%;
    }

    #wrapper_Forget_Password .box .SignupText {
        margin: 20px;
    }

    #wrapper_Forget_Password .box p {
        margin-left: 25px;
    }

    #wrapper_Forget_Password .box input[type="email"],
    .box input[type="password"] {
        width: 100%;
    }

    #wrapper_Forget_Password .box .copyRights {
        /* width: 135px; */
        height: 17px;

        text-align: var(--login_copyrightstextalign);
        font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--login_copyrightscolor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 88 !important;
    }

    #wrapper_Forget_Password .box .BackLogin {
        color: var(--unnamed-color-c1393d);
        text-align: left;
        font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 104px !important;
    }

    #wrapper_Forget_Password .box a {
        width: 91px;
        height: 17px;
        text-align: end;
        text-decoration: var(--login_a_decoration);
        font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 70px;
    }

    #wrapper_Forget_Password .box h1 {
        font: normal normal var(--boldtextWeight) var(--mqH1Fontsize) var(--fontfamily2);
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
    /* img {
          height: 100%;
      } */

    #wrapper_Forget_Password .box input[type="email"],
    .box input[type="password"] {
        width: 100%;
    }

    #wrapper_Forget_Password .box .LoginBtn {
        width: 100%;
    }

    #wrapper_Forget_Password .box p {
        margin-left: 25px;
    }

    #wrapper_Forget_Password .box {
        text-align: center;
        padding: 8px 8px 8px 8px;

        width: 360px;
        height: 500px;
        position: relative;
        margin: auto;
        top: 100px;

        /* UI Properties */
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #0000001a;
        border: 1px solid #04113480;
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Forget_Password .box .SignupText {
        margin: 20px;
    }

    #wrapper_Forget_Password .box .copyRights {
        /* width: 135px; */
        height: 17px;

        text-align: var(--login_copyrightstextalign);
        font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--login_copyrightscolor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 15px !important;
    }

    #wrapper_Forget_Password .box .BackLogin {
        text-align: left;
        font: normal normal var(--backloginFontWeight) var(--backloginFontsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 30px;
        margin-left: 110px !important;
    }

    #wrapper_Forget_Password .box a {
        width: 91px;
        height: 17px;
        text-align: end;
        text-decoration: var(--login_a_decoration);
        font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 70px;
    }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
    /* styles here */
}

.videoWrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.videoWrapper video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    z-index: -100;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

#wrapper_Forget_Password .box small {
    color: var(--loginsmalltextcolor);
    font: normal normal var(--loginsmalltextweight) var(--loginsmalltextsize) var(--fontfamily2);
}

#MIcons {
    margin-right: 7px;
    font-size: 16px;
    vertical-align: middle;
}