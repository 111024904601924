


button.btn.payment
{
    background-color: var(--color) !important;
    color: white;
}
a.payment
{
    color:var(--color);
    text-decoration: none;
}
a.payment:hover
{
    color:var(--color);
    text-decoration: none;
}
.card-body.payment
{
    box-shadow: 0px 0px 3px #0000004D;
    border-radius: 8px;
    height: 400px !important;
    padding: 2em 2em 2em 2em;


}
img.payment {
    border-radius: 8px;
}
.col_size
{
    /* width: 37%; */
    /* margin: 0px -67px 8px -56px; */

    height: 212px;
}
.payment_course_name
{
    color: var(--color);
    text-align: center;
font: var(--font) bold 16px/21px var(--fontfamily2);
letter-spacing: 0.32px;
opacity: 1;
margin-top:2em;
font-family: var(--fontfamily2), Bold;
font-size: var(--subtitlefontsize);
}
.payment_batch_name
{
    /* text-align: left; */
letter-spacing: 0.24px;
color: #041134;
font-family: var(--fontfamily2), Medium;
font-size: var(--subtitlefontsize);
font-weight: 600;
    margin-top: 4px;
    margin-bottom: 4px;
}

.payment_icon
{
    margin-bottom: 1em;
    color: var(--color);
    margin-right: 0.5em;

}
.innerflex_payment
{
    display: inline-block;
    margin-bottom: 3em;

}
.payment.flex_title
{
text-align: left;
font: (--font) medium 14px/26px var(--fontfamily2);
letter-spacing: 0px;
color: #041134;
opacity: 0.5;
}
h6.payment.flex_subtitle {
    /* margin-right: 2.1em; */
    text-align: left;

}h6.payment_fees_name {

    text-align: right;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    opacity: 0.5;
    font-size: 16px;
    font-family: var(--fontfamily2), Bold;
    margin-bottom: 2.9em;
}

.payment_fees {
    text-align: left;
    line-height: 21px;
    font-family: var(--fontfamily2);
    font-size: 18px;
    letter-spacing: 0.36px;
    color: #041134;
    opacity: 1;
    margin-bottom: 2.2em;

}
.payment_currency
{
    color: var(--color) ;
opacity: 1;

}
.payment_fees.payment_input
{
    width: 40% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    margin-top: -1em;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
.coupon-container {
    display: inline-block;
    margin-right: 10px;
  }

  button.btn.payment.payment_coupen {
   
    margin-top: -1em;
    margin-bottom: 2.9em;
    text-align: left;
font-family: var(--fontfamily2);
letter-spacing: 0px;
font-size: var(--contentfontsize);}

button.btn.payment.payment_coupen:hover {
   
    background: var(--bluebrand) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--bluebrand);
    margin-top: -1em;
    margin-bottom: 2.9em;
    text-align: left;

}

button.btn.payment.payment_coupen:hover a{

    color: white;
}

@media (min-width: 767px) and (max-width: 1024px){
    img.payment {
        width: 90px !important;
        height: 90px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px){
    img.payment {
        width: 90px !important;
        height: 90px !important;
    }
    .innerflex_payment {
        display: inline-block;
        margin-bottom: 3em;
        margin-right: -3em;
    }
    h6.payment_fees_name {
        margin-bottom: 1.4em;

    }
    .d-flex.coupon_box
    {
        display: unset !important;
    }
    .payment_fees.payment_input {
        margin-right: 7em;
        /* width: 100% !important; */
        margin-top: -0.5em;

    }
    button.btn.payment.payment_coupen {
        margin-right: 3em;
        /* width: 100%; */
        margin-top: -1em;
        margin-bottom: 2.5em;
        font-size: 10px;

    }
    .payment_fees
    {
        margin-bottom: 1.4em;

    }
    h6#remain_fee {
        margin-top: 5em;
    }
    
}

@media (min-width:425px) and (max-width: 766px) {
   
    img.payment
    {
        width: 150px !important;
        height: 150px !important;
    
    }
    .card-body.payment {
        height: 560px !important;
    }
    
    .d-flex.flex-column.align-items-start.payment
    {
    margin-left: 2em;
    margin-top: 4em;

    }
    .innerflex_payment {
        margin-bottom: 1em;
    }
    
    h6.payment_fees_name {
     font-size: var(--fontsizetwevele);
     margin-bottom: 1.5em;

    }

    .payment_fees {
        font-size: 12px;
        margin-bottom: 1.2em;


    }
    .d-flex.coupon_box
    {
        display: unset !important;
    }
    .payment_fees.payment_input {
        margin-right: 7em;
        width: 96% !important;
        margin-top: -0.5em;
    }
    button.btn.payment.payment_coupen {
        margin-right: 3em;
        width: 96%;
        margin-top: -3em;
        margin-bottom: 2.5em;
        font-size: 10px;

    }

    h6#remain_fee {
        margin-top: 7em;
    }
    button.btn.payment{
        margin-right: 1em;
font-size: 12px;
    }
}

@media  (max-width: 424px){
    img.payment
    {
        width: 150px !important;
        height: 150px !important;
    
    }
    .card-body.payment {
        height: 560px !important;
    }
    
    .d-flex.flex-column.align-items-start.payment
    {
        margin-left: 1em;
        margin-top: 4em;

    }
    .innerflex_payment {
        margin-bottom: 1em;
    }
    
    h6.payment_fees_name {
     font-size: 12px;
     margin-bottom:2.2em;

    }

    .payment_fees {
        font-size: 12px;
        margin-bottom: 1.2em;


    }
    .d-flex.coupon_box
    {
        display: unset !important;
    }
    .payment_fees.payment_input {
        margin-right: 7em;
        width: 95% !important;
        margin-top: -0.5em;

    }
    button.btn.payment.payment_coupen {
        margin-right: 3em;
        width: 95%;
        margin-top: -3em;
        margin-bottom: 2.5em;
        font-size: 10px;

    }

    h6#remain_fee {
        margin-top: 4em;
    }
    button.btn.payment{
        margin-right: 1em;
font-size: 12px;
    }
}

@media (max-width: 320px) {
}



