.supPageListDrop #paginationList {
  appearance: auto;
  background: #c0272d !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 5px;
}
.paginatonDiv,
.ListBtn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.pageNumber,
.itemPage {
  padding: 5px;
  margin: 0 10px;
  font-size: 14px;
}

.pagecountDropdown:focus,
.pagecountDropdown:focus-visible {
  border: 1px solid var(--bluebrand) !important;
  outline: none;
}
.materialIconsRightAndLeft {
  color: #fff;
  cursor: pointer !important;
  font-size: 18px;
  padding: 5px;
}
@media (max-width: 576px) {
  .supPageListDrop select {
    /* height: fit-content !important; */
  }
  .pagenumber,
  .itemPage {
    padding: 0px;
    margin: 0 10px;
    font-size: var(--fontsizetwevele);
  }
}
@media (max-width: 768px) {
  .supPageListDrop select {
    /* height: fit-content !important; */
  }
  .pageNumber,
  .itemPage {
    padding: 0px;
    margin: 0 10px;
    font-size: var(--fontsizetwevele);
  }
}
