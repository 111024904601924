.form-group.col-md-6 {
  text-align: left;
  /* margin-bottom: 2em; */
  padding: 2em 2em 0em 3em;
}

label {
  font-weight: 500;
}

a.course_link,
a.course_cancel {
  color: white;
  text-decoration: none;
}

/* Batch style */

.BathDet input[type="text"]::placeholder,
.BathDet input[type="email"]::placeholder,
.BathDet input[type="password"]::placeholder,
.BathDet input[type="number"]::placeholder,
.BathDet input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.BathDet {
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
  /* --------Text Not Select----- */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  /* ----------------------------- */
  /* pointer-events: none; */
}

.BathDet .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  /* color: var(--colorRed); */
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  /* margin-right: 10px; */
  margin-right: 19px;
  margin-top: 10px;
}

.BathDet .CancelButtons {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  /* color: var(--cancelButtonColor); */
  color: #c0272d;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 13px;
}

.BathDet .CancelButtons_ques {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 30px;
}

.BathDet .SaveButton a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .SaveButton {
  /* width: var(--batchbtnwidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  text-decoration: none;
}

.BathDet .SaveButton:hover::after {
  /* content: var(--saveButtonAfterIcon); */
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: #ffffff;
  border-radius: 3px;
}

.BathDet .Tit_rev {
  width: 84%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: #ffffff;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .SaveButton:hover a {
  color: white;
}

.BathDet .SaveButton_edit a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  font-family: var(--fontfamily2);
  width: max-content;
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .SaveButton_edit {
  /* width: var(--batchbtnwidth); */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  text-decoration: none;
}

/* 
.BathDet .SaveButton_edit:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.BathDet .SaveButton_edit:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .SaveButton_edit:hover a,
.BathDet .SaveButton_edit:hover .material-icons {
  color: #ffffff;
}

.BathDet .EditEventButton a {
  text-align: left;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  width: max-content;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .EditEventButton {
  /* width: fit-content; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  margin-right: 7px;
  text-decoration: none;
}

.BathDet .EditEventButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.BathDet .EditEventButton:hover {
  /* width: 135px; */
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.BathDet .EditEventButtonss a {
  text-align: left;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .EditEventButtonss {
  /* width: 213px; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  margin-right: 7px;
  text-decoration: none;
}

/* 
.BathDet .EditEventButtonss:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */

.BathDet .EditEventButtonss:hover {
  /* width: 213px; */
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .EditEventButtonss:hover a {
  color: white;
  text-align: left;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  opacity: 1;
}

.BathDet .EditEventButtons a {
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  width: max-content;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .EditEventButtons {
  /* width: fit-content; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: white;
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  /* margin-right: 7px; */
  text-decoration: none;
}

/* .BathDet .EditEventButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */

.BathDet .EditEventButtons:hover {
  /* width: fit-content; */
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPadding);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .box {
  /* width: 1280px; */
  height: fit-content;

  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 30px;
  margin-top: 30px !important;
  margin-left: 0px;
  margin-right: 0px;
}

.BathDet .StepOne .box_review {
  /* height: fit-content; */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 30px;
  margin-top: 30px;
}

.BathDet .StepOne .box_review {
  /* height: fit-content; */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 30px;
  margin-top: 30px;
}

.BathDet .StepOne .box_review_join {
  /* height: fit-content; */
  /* background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box; */
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 30px;
  margin-top: 30px;
}

.box_review_set {
  width: 160px;
}

.BathDet .StepOne .box_review .StepTitle {
  width: fit-content;
  height: 39px;
  /* New change */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #ffffff;
  /* New change */
  opacity: 1;
  background: #c1393d;
  /* New change */
  border-radius: 50px;
  /* New change */
  margin-top: -19px;
  /* New change */
  margin-left: 10px;
  padding: 8px 10px;
  /* New change */
  margin-bottom: 0px !important;
}

.BathDet .StepOne .box_review .forms {
  display: flex;
  flex-direction: column;
}

.BathDet .StepOne .box_review .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 17px;
  margin-left: 20px;
}

.BathDet .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  /* position: absolute; */
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
  margin-bottom: 0px;
}

.BathDet .StepOne .box_reviews .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  /* position: absolute; */
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
  margin-bottom: 0px;
}

.BathDet .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.BathDet .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 17px;
  margin-left: 20px;
}

.BathDet .StepOne .box_reviews .forms {
  display: flex;
  flex-direction: column;
}

.BathDet .StepOne .box_reviews .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 17px;
  margin-left: 20px;
}

.BathDet .StepTwo .box {
  /* width: 1280px; */
  height: fit-content;
  /* max-height: 100vh; */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
}

.BathDet .StepTwo .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  /* position: absolute; */
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.BathDet .StepTwo .box .forms {
  display: flex;
  flex-direction: column;
}

.BathDet .StepTwo .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  /* margin-top: 25px; */
  margin-left: 20px;
}

.InputsTextarea:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 2px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.InputsTextarea {
  height: 36px;
  /* UI Properties */
  width: 97%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .InputsTilte {
  height: 36px;
  font-size: 14px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
  outline: none;
  font-weight: 400;
}

.BathDet .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .box .forms .InputsFile {
  width: 83%;
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .box .forms .InputsFilessss {
  width: 81%;
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .error {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
}

/* .BathDet .SaveButton_disabled {
    width: 100%;
    height: var(--saveButtonHeight);
    background: #b74144d4 0% 0% no-repeat padding-box;
    border: 2px solid #b74144d4;
    color: var(--saveButtonTextColor);
    padding: var(--saveButtonPadding);
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontfamily2);
    text-decoration: none;
} */

.BathDet .radioGroup .formCheck {
  margin-bottom: 20px;
  margin-left: 19px;
}

.BathDet .radioGroup .formCheck .checkbox {
  width: var(--inputCheckboxwidth);
  height: var(--inputCheckboxheight);
  /* UI Properties */
  background: var(--inputcheckboxBackgroundColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inputcheckboxBordercolor);
  opacity: 0.5;
}

.BathDet .radioGroup .formCheck .checkbox-label {
  height: 19px;
  /* UI Properties */
  text-align: var(--checkboxlabelalign);
  font: normal normal var(--checkboxlabelweight) var(--checkboxlabelSize) var(--fontfamily2) !important;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-right: 17px;
  vertical-align: super;
}

.BathDet .button {
  background: transparent;
  color: var(--colorRed);
  width: 37px;
  height: 31px;
  border: none;
}

.BathDet .button_download {
  background: transparent;
  color: var(--colorRed);
  width: 9%;
  height: 31px;
  border: none;
}

#img {
  width: 30px;
  height: 30px;
}

.BathDet .form-check-inline {
  display: inline-block;
  margin-right: 0rem !important;
}

.checkbox-label {
  margin-left: 0px !important;
  vertical-align: sub !important;
}

#listViewTabs {
  /* justify-content: space-evenly; */
  opacity: 1;
  border-bottom: 1px solid var(--colorRed);
  /* margin-left: 17px; */
}

.tabSection .nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 0 1 auto !important;
  text-align: center;
  width: fit-content;
  margin-left: 0px !important;
}

.tabSection .nav-fill .nav-item .nav-link {
  cursor: context-menu;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 0 1 auto !important;
  text-align: center;
  /* width: 130px; */
  width: fit-content;
  margin-left: 20px;
}

#listViewTabs li a {
  font-weight: var(--boldtextWeight);
  text-align: center;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  /* font: normal normal medium /16px ; */
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
  outline: none;
}

#listViewTabs_sec li a {
  font-weight: var(--boldtextWeight);
  text-align: center;
  font-size: var(--fontsizetwevele);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
  outline: none;
  line-height: 16px;
}

.BathDet .borderTabs {
  width: 1280px !important;
  height: 1px !important;
  opacity: 1 !important;
  margin-left: 17px;
  background-color: #8080807a;
}

#listViewTabs .nav-link.active,
#listViewTabs .nav-item.show .nav-link {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--colorRed);
  opacity: 1 !important;
  color: var(--colorRed) !important;
}

#listViewTabs_sec .nav-link.active,
#listViewTabs_sec .nav-item.show .nav-link {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--colorRed);
  opacity: 1 !important;
  color: #041134ad !important;
}

#listViewTabs .nav-item.hover .nav-link,
#listViewTabs_sec .nav-item.hover .nav-link {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--bluebrand);
  opacity: 1 !important;
}

/* #listViewTabs .nav-link.active .nav-link:hover{
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--colorRed );opacity: 1 !important;

} */
.BathDet #listViewTabs .nav-link:hover {
  background-color: transparent;
  border: none;
  /* border-bottom: 1px solid  transparent !important; */
  opacity: 1 !important;
  color: var(--bluebrand) !important;
  font-weight: bolder;
  font-size: 14px;
}

.BathDet table thead th .thead {
  display: flex !important;
}

.material-icons {
  cursor: pointer;
}

.css-159ulw2-MuiPaper-root {
  box-shadow: none !important;
  justify-content: flex-start !important;
}

.BathDet #example #th {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  white-space: nowrap;
  /* position: relative; */
  /* word-break: break-word; */
  /* width: 15%; */
}

.BathDet #example #th .text_th {
  opacity: 0.7;
}

#th .text_th {
  opacity: 0.7;
}

.colorTextTH {
  color: white;
  opacity: 1 !important;
}

/* .table-responsive {

  padding: 1px !important;
} */

.BathDet #example #td {
  font-family: var(--fontfamily2);
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  font-size: 14px;
  /* width: 10%; */
  word-break: break-all;
  /* word-wrap: break-word; */
}

.BathDet #example #td a {
  font-family: var(--fontfamily2);
  text-align: left;
  letter-spacing: 0px;
  text-decoration: none;
  color: var(--colorBlue);
  opacity: 1;
  font-size: 14px;
  word-break: break-all;
  /* width: 10%; */
}

.BathDet #example #tr:hover a {
  color: white !important;
}

.BathDet #example #td:hover a {
  color: white !important;
}

.BathDet #example th .material-icons {
  opacity: 0.6 !important;
  color: var(--colorBlue);
  cursor: pointer !important;
  font-size: 18px;
}

.BathDet .pag span {
  text-align: left;
  font: normal normal normal var(--fontSizeFourteen) var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 5px;
}

.BathDet .pag select {
  background: var(--colorRed) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--colorRed) !important;
  /* width: 4% !important; */
  width: 5% !important;
  /* z-index: 9999; */
  position: relative;
  appearance: auto;
  height: -moz-fit-content !important;
  height: fit-content !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: white !important;
  padding-right: 0px;
}

.BathDet .pag select option {
  background-color: none;
}

.BathDet .pag .pagenumber {
  text-align: center;
  font: normal normal normal 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 2px;
}

.BathDet .pag .prev_button {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  position: relative;
  bottom: 2px;
}

.BathDet .pag .prev_button .material-icons {
  color: white;
  vertical-align: text-bottom;
  font-family: "Material Icons";
  font-size: 18px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font: normal normal medium 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.4;
}

.BathDet .Inputs {
  height: 36px;
  /* width: 80%; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .Inputs_searchList {
  height: 36px;
  width: 80%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .Inputs_searchList:focus-visible {
  outline: none;
}

.BathDet .Inputs_search {
  height: 36px;
  width: 50%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .Inputs_search:focus {
  border: 1px solid #77041134 !important;
  outline: none;
}

.BathDet .Inputs:focus {
  border: 1px solid #77041134 !important;
  outline: none;
}

.BathDet .Inputsss {
  height: 36px;
  width: 91%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .Inputsss:focus {
  border: 1px solid #77041134 !important;
  outline: none;
}

.BathDet .Inputss {
  height: 36px;
  width: 80%;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .Inputss:focus {
  border: 1px solid #77041134 !important;
  outline: none;
}

.BathDet .input-group-texts {
  width: 30px;
  display: flex;
  justify-content: center;
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolossr) 0% 0% no-repeat padding-box;
  /* border: none; */
  border-right: 1px solid var(--InputBorderColor);
  border-top: 1px solid var(--InputBorderColor);
  border-bottom: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .input-group-text {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  /* border: none; */
  border-right: 1px solid var(--InputBorderColor);
  border-top: 1px solid var(--InputBorderColor);
  border-bottom: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .SaveButton:hover .material-icons {
  color: white !important;
}

.BathDet .SaveButton:hover a {
  color: white;
}

.BathDet .Title {
  /* width: 100%; */
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 23px; */
  margin-top: 20px;
}

.BathDet .Titles {
  width: 57%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Titless {
  width: 59%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .ListViewBtn {
  /* width: fit-content; */
  height: 36px;
  /* UI Properties */
  text-align: left;

  /* letter-spacing: 0.28px; */
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  /* padding: var(--saveButtonPadding); */
  /* padding: 11px 15px; */
  /* border-radius: 6px; */
  border-radius: 3px;
  opacity: 1;
  display: flex;
  align-items: center;
  /* font-size: 12px; */
  font-size: 14px;
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: bold;
}

.BathDet .ListViewBtn a {
  text-align: inherit;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  width: max-content;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

/* .BathDet .ListViewBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -8%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtonHoverColor);
  border-radius: 3px;
} */

.BathDet .ListViewBtn:hover a,
.BathDet .ListViewBtn:hover .material-icons {
  color: var(--saveButtonHoverColor);
}

.BathDet .ListViewBtn:hover {
  /* width: fit-content; */
  height: 36px;
  text-align: left;
  /* letter-spacing: 0.28px; */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  color: var(--saveButtonTextColor);
  /* padding: var(--saveButtonPadding);
  border-radius: 6px; */
  /* padding: 11px 15px; */
  border-radius: 3px;
  opacity: 1;
  display: flex;
  align-items: center;
  /* font-size: 12px; */
  font-size: 14px;
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: bold;
}

.BathDet .ListViewBtnss {
  /* width: 164px; */
  height: 36px;
  /* UI Properties */
  text-align: left;

  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);

  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: bold;
}

.BathDet .ListViewBtnss a {
  text-align: inherit;
  font-weight: bold;
  font-size: 12px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  width: max-content;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

/* .BathDet .ListViewBtnss:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -8%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtonHoverColor);
  border-radius: 3px;
} */

.BathDet .ListViewBtnss:hover a,
.BathDet .ListViewBtnss:hover .material-icons {
  color: white;
}

.BathDet .ListViewBtnss:hover {
  /* width: 176px; */
  height: 36px;
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  /* color: var(--saveButtonHoverTextColor); */
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .PinkText {
  text-align: center;
  font-family: var(--fontfamily2);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .ReviewLabel,
.BathDet .ReviewLabel_sub {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
  margin-bottom: 6px;
}

.BathDet .ReviewLabelText {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #2378e9;
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
  margin-bottom: 6px;
}

.BathDet .ReviewLabels {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: rgb(220, 53, 69);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.BathDet .ReviewLabelss {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

.hide {
  display: none;
}

.tabnn {
  width: 21% !important;
}

.BathDet .filterBoxs {
  width: 240px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;

  /* top: 70%;
  left: 66%; */
}

.BathDet .filterBoxs_fg {
  width: 240px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;
  right: 40px;
  /* top: 70%;
  left: 66%; */
}

.BathDet .filterBoxs_testlist {
  width: 240px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;
  right: 40px;
  /* top: 70%;
  left: 66%; */
}

.MuiTextField-root {
  margin-top: 7px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: none !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: 1px solid var(--InputBorderColor) !important;
  outline: none;
}

.MuiTextField-root:focus-visible {
  border-color: 1px solid var(--colorRed) !important;
  outline: none;
}

.BathDet .PinkText {
  text-align: center;
  padding-left: 2px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: var(--colorPink);
  font: normal normal medium;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: 12px/16px;
  opacity: 1;
  border-radius: 2px;
}

.BathDet .PinkTexts {
  text-align: center;
  width: 139px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: var(--colorPink);
  font: normal normal medium;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .GreenTexts {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  width: 139px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .GreenText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  /* padding-top: 3px;
  padding-bottom: 2px; */
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
  text-wrap: nowrap;
}

.BathDet .GreyTexts {
  text-align: center;
  width: 124px;
  padding-left: 7px;
  padding-right: 7px;

  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorSemantic);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .GreyText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorSemantic);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .GreyText1 {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  /* padding-top: 3px;
  padding-bottom: 2px; */
  background-color: #4fa94d !important;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .clearFilter {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font: normal normal medium 14px/19px var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--bluebrand);
  opacity: 1;
}

.BathDet .FilterButton {
  bottom: 8px;
  width: 135px;
  height: -moz-fit-content;
  height: 40px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* .BathDet .FilterButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 0%;
  right: -15px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */

.BathDet .FilterButton:hover a,
.BathDet .FilterButton:hover .material-icons {
  color: white !important;
}

.BathDet .FilterButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(1px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.BathDet .filterBoxOne {
  width: 240px;
  height: 332px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
  z-index: 999;
  position: absolute;
  /* right: 20px; */
  /* top: 67%;
  left: 67%; */
}

.BathDet .filterBoxOne_Con {
  width: 240px;
  height: 332px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
  z-index: 999;
  position: absolute;
  right: 20px;
  /* top: 67%;
  left: 67%; */
}

.BathDet .filterBoxOne_date {
  width: 240px;
  height: 332px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
  z-index: 999;
  position: absolute;

  /* top: 67%;
  left: 67%; */
}

.BathDet .filterBoxTwo {
  width: 240px;
  max-height: 332px;
  /* UI Properties */
  overflow: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;

  position: absolute;
  /* top: 66%;
    left: 34%; */
  z-index: 999;
  overflow-y: scroll;
  overflow-x: clip;
}

.BathDet .form-check-label,
.BathDet .form-label {
  font-size: 14px;
  font-family: var(--fontfamily2);
  opacity: 1;
  color: var(--colorBlue);
  vertical-align: sub;
  margin-left: 10px;
}

.BathDet .filterBoxs .form-check-label,
.BathDet .filterBoxs_fg .form-check-label,
.BathDet .filterBoxssss .form-check-label,
.BathDet .filterBoxOne .form-check-label,
.BatchDet .filterBoxOne_Con .form-check-label,
.BathDet .filterBoxOne_date .form-check-label {
  font-size: 14px;
  font-family: var(--fontfamily2);
  opacity: 1;
  color: var(--colorBlue);
  vertical-align: top;
  margin-left: 10px;
  text-wrap: wrap;
}

.BathDet .filterBoxs_testlist .form-check-label,
.BathDet .filterBoxssss .form-check-label,
.BathDet .filterBoxOne .form-check-label,
.BatchDet .filterBoxOne_Con .form-check-label .BathDet .filterBoxOne_date .form-check-label {
  font-size: 14px;
  font-family: var(--fontfamily2);
  opacity: 1;
  color: var(--colorBlue);
  vertical-align: top;
  margin-left: 10px;
}

.BathDet .FilterInputs {
  height: 36px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0411341f;
  border-radius: 6px;
  opacity: 0.9;
  /* UI Properties */
  /* background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px; */
  /* opacity: 1; */
  /* margin-left: 20px; */
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 2px;
  font-family: var(--fontfamily2);
}

.borderActive {
  width: 1280px;
  border-bottom: 1px solid var(--colorRed);
  opacity: 0.6;
  position: relative;
  top: -18px;
  left: 19px;
  /* left: 28px; */
}

.CalendarTitle {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeTwenty);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
}

.BathDet .PrevBtn {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  margin-left: 5px;
  color: white;
}

.BathDet .PrevBtn .material-icons {
  font-size: var(fontSizeeighteen);
  font-weight: var(--boldtextWeight);
  vertical-align: middle;
}

.BathDet .calViewBtn {
  /* width: fit-content; */
  height: 36px;
  /* UI Properties */
  text-align: left;

  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: var(--fontsizetwevele);
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 1px;
  font-weight: var(--boldtextWeight);
}

.BathDet .calViewBtn a {
  text-align: inherit;
  font-weight: bold;
  font-size: 12px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .calViewBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: -8%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.BathDet .calViewBtn:hover a,
.BathDet .calViewBtn:hover .material-icons {
  color: var(--colorRed) !important;
}

.BathDet .calViewBtn:hover {
  /* width: 110px; */
  height: 36px;
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.up-in-toggle {
  /* padding: 5%; */
}

.up-in-toggle input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.up-in-toggle input:checked+label {
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  /* border-radius: 3px 0px 0px 3px; */
  opacity: 1;
  color: white;
}

.up-in-toggle input:checked+label:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
}

.up-in-toggle label {
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  display: inline-block;
  width: 60px;
  text-align: center;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);

  padding: 6px 6px;
  float: left;
  transition: all 0.1s ease-in-out;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);

  opacity: 1;
}

.up-in-toggle label:hover {
  cursor: pointer;
}

/* .up-in-toggle label:first-of-type {
    border-radius: 15.5px 0 0 15.5px;
  } */

/* .up-in-toggle label:last-of-type {
    border-radius: 0 15.5px 15.5px 0;
  } */

.react-datepicker {
  position: absolute !important;
  z-index: 99 !important;
}

.hovered-cell {
  background-color: var(--colorYellow);
}

.rbc-day-bg:hover {
  cursor: pointer !important;
  color: white !important;
  box-shadow: 2px 2px 2px #00000029 !important;
  border: 2px solid var(--colorRed) !important;
  opacity: 1 !important;
}

.rbc-day-bg {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 2px solid var(--colorBlue) !important;
  opacity: 0.4 !important;
  border-radius: 6px !important;
  margin: 2px !important;
}

.rbc-day-bg:hover .rbc-button-link {
  color: white !important;
}

.rbc-day-bg .rbc-button-link {
  color: var(--colorRed) !important;
}

.rbc-month-view {
  border: none !important;
}

.rbc-header+.rbc-header {
  border: none !important;
  background-color: transparent !important;
}

.rbc-header {
  border: none !important;
  background-color: transparent !important;
}

.rbc-today {
  outline: none;
  border: 2px solid var(--bluebrand) !important;
  border-radius: 6px !important;
  box-shadow: 0 3px 6px var(--blackBoxShadow) !important;
  opacity: 1 !important;
  background: var(--bluebrand) !important;
}

/* 
.rbc-today .rbc-event, .rbc-day-slot .rbc-background-event{

  margin-top: 3px !important;
  border-left: 2px solid  var(--bluebrand) !important;
  box-sizing: border-box;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 3px #0000001A !important;
  border-radius: 2px !important;
  opacity: 1 !important;
  margin-left: 7px !important;
  padding: 0px 7px !important;
  color: var(--colorRed) !important;
  cursor: pointer;
  width: 93% !important;
  text-align: left;
} */

.rbc-date-cell {
  text-align: left !important;
}

.rbc-header .rbc-button-link {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--colorRed) !important;
  margin: 2px 0px 0 2px !important;
}

.rbc-button-link {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--colorRed) !important;
  margin: 10px 0px 0 10px !important;
}

.rbc-current .rbc-button-link {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  padding: 4px !important;
  background: var(--colorRed) !important;
  border-radius: 4px !important;
  margin: 10px 0px 0 10px !important;
}

@keyframes blink {

  0%,
  100% {
    transform: scale(1);
    /* Normal size */
  }

  50% {
    transform: scale(0.9);
    /* Zoom in to 120% of normal size */
  }
}

.multi-select-datepicker .material-icons {
  color: var(--bluebrand);
}

/* .rbc-day-bg + .rbc-day-bg */

.rbc-event,
.rbc-day-slot .rbc-background-event {
  margin-top: 3px !important;
  border-left: 2px solid var(--colorRed) !important;
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 3px #0000001a !important;
  border-radius: 2px !important;
  opacity: 1 !important;
  margin-left: 7px !important;
  padding: 0px 7px !important;
  color: var(--colorRed) !important;
  cursor: pointer;
  width: 93% !important;
  text-align: left;
}

.BathDet .allclear {
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  text-align: left;
  /* font: normal normal medium 14px/19px var(--fontfamily2); */
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  position: relative;
  top: 3px;
  text-decoration: none;
}

/* .BathDet .ipage{
    margin-top: 3px;
  } */

/* #mytoolbar{

    position: relative;
    top: -45px;
  }
  #mytoolbar .CalendarTitle{

    margin-top: 19px;
  }
  #mycompontent {

    position: relative;
    z-index: 9999;
  } */

.BathDet .PrevBtns {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorRed);
  border-radius: 3px;
  opacity: 1;
  margin-left: 5px;
  margin-top: 1px;
  height: 33px;
  color: white;
}

.BathDet .PrevBtns:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
}

#paperil ul {
  display: flex !important;
  justify-content: flex-start !important;
  box-shadow: none !important;
}

.rbc-show-more {
  background-color: transparent !important;
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #000 !important;
}

/* --------start---------- */
.BathDet .StepOne .box .forms .Inputs::-webkit-outer-spin-button,
.BathDet .StepOne .box .forms .Inputs::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BathDet .StepOne .box .forms .Inputs[type="number"] {
  -moz-appearance: textfield;
}

/* --------End---------- */
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: visible;
  height: 100%;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  background-color: none !important;
}

textarea {
  height: var(--InputTextarea) !important;
}

textarea::placeholder {
  text-align: left;
  font-size: var(--textareaPlaceHolder);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--textareaPlaceHolderColor);
  opacity: 0.4;
}

.BathDet .SaveButtons a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
}

.BathDet .SaveButtons {
  width: 352px;
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
  font-size: 14px;
}

/* 
.BathDet .SaveButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
} */

.BathDet .SaveButtons:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.BathDet .SaveButtonsss a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .SaveButtonsss {
  width: 170px;
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
  font-size: 14px;
}

.BathDet .SaveButtonsss:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.BathDet .SaveButtonsss:hover {
  height: var(--saveButtonHeight);
  background: var(--saveButtonHoverColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonHoverBorderColor);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.BathDet .SaveButtonsss:hover a {
  color: var(--saveButtonHoverTextColor);
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  opacity: 1;
}

#OldSBatch,
#panelMem,
#exam_center,
#staffMem,
#District,
#MEABatch,
#district {
  margin-left: 16px;
  margin-top: 6px;
  /* width: 81%; */
}

.BathDet .AddMore {
  text-decoration: none;
  color: var(--addmoreTextcolor);
  font-family: var(--fontfamily2);
  font-weight: var(--addmoreFontWeight);
  cursor: pointer !important;
  margin-top: 16px;
  font-size: 14px;
}

.BathDet .material-icons {
  border-radius: 3px;
  cursor: pointer;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

.mat_icon {
  border-radius: 3px;
  cursor: pointer !important;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
  vertical-align: bottom;
  color: var(--colorRed);
  margin-right: 3px;
}

.BathDet .redText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  /* padding-top: 3px;
  padding-bottom: 2px; */
  background-color: #db4b4b;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

/* styles.css */
.tooltip {
  position: absolute;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tooltip a {
  text-decoration: underline;
  color: blue;
}

.EventTitle {
  font-size: 14px;
  font-family: var(--fontfamily2);
}

.EventButton {
  background: white;
  position: relative;
  bottom: 2px;
  border: none;
  border-radius: 6px;
}

.EventButton a {
  text-decoration: none;
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: 600;
  color: var(--colorRed);
}

.BathDet .Tit {
  width: 68%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tit_ques {
  /* width: 63%; */
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tit_withddd {
  /* width: 86%; */
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tit_witTest {
  font-size: var(--fontSizesixteen);
  font-weight: bold;
  font-family: var(--fontfamily2);
  color: var(--Titlecolor);
  margin-top: 30px;
  margin-right: 10px;
}

.BathDet .Tit_wittTest {
  /* height: 26px; */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  white-space: nowrap;
  color: var(--Titlecolor);
  opacity: 1;
  margin-top: 20px;
}

.BathDet .Tit_withddd_inss {
  width: 96%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tits {
  width: 65%;
  /* width: 60%; */
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Titss {
  width: 75%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tits_vvv {
  width: 72%;
  white-space: nowrap;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tits_sss {
  width: 74%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .ssTits_sss {
  width: 72%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tits_detail {
  width: 47%;
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Tits_details {
  /* width: 71%; */
  height: 26px;
  white-space: nowrap;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;

}

.BathDet .tktk {
  width: 74%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Title_width {
  width: 68%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Titles_width {
  width: 64%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Title_widths {
  width: 63%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Title_widthss {
  width: 65%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .Title_widthsss {
  width: 80%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .twidth {
  width: 63%;
  height: 26px;
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .StepOne .boxs {
  /* width: 1280px; */
  height: fit-content;

  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin: 30px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.BathDet .StepOne .boxs .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.BathDet .StepOne .boxs .forms {
  display: flex;
  flex-direction: column;
}

.BathDet .StepOne .boxs .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 28px;
  margin-left: 20px;
}

.BathDet .StepOne .boxs .forms .Inputs:focus {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 2px solid var(--colorRed) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.BathDet .StepOne .boxs .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .EditEventButtons:disabled {
  opacity: 0.8;
}

.BathDet .detailEventButton a {
  text-align: left;
  font-weight: bold;
  width: max-content;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .detailEventButton {
  /* width: 226px; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  margin-right: 7px;
  text-decoration: none;
}

.BathDet .detailEventButton_upload a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .detailEventButton_upload {
  /* width: 156px; */
  height: 37px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  /* padding: var(--saveButtonPadding); */
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  margin-right: 7px;
  text-decoration: none;
}

.BathDet .detailEventButton_upload:hover {
  height: 37px;
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  /* padding: var(--saveButtonPaddingHover); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

/* .BathDet .detailEventButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.BathDet .detailEventButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  /* padding: var(--saveButtonPaddingHover); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .detailEventButton:hover a,
.BathDet .detailEventButton:hover .material-icons {
  color: #ffffff;
}

.BathDet .nPButton a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .nPButton {
  /* width: 100px; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  /* margin-right: 7px; */
  text-decoration: none;
}

.BathDet .nPButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 14%;
  right: -6px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  /* padding: var(--saveButtonPaddingHover); */
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.BathDet .nPButton:hover {
  /* width: 120px; */
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  /* padding: var(--saveButtonPaddingHover); */
  transform: translateX(5px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.BathDet .nNButton a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .nNButton {
  width: 100px;
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  /* padding: var(--saveButtonPadding); */
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  margin-right: 7px;
  text-decoration: none;
}

.BathDet .submit_bun {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  height: 40px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPadding);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .submit_bun a {
  opacity: 1;
  font-family: var(--fontfamily2);
  font-size: 14px;
  text-decoration: none;
  color: white;
}

.BathDet .nPButton:disabled,
.BathDet .nNButton:disabled {
  opacity: 0.4;
}

.BathDet .submit_bun:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .nNButton:hover::before {
  content: var(--saveButtonBeforIcon);
  position: absolute;
  top: -18%;
  right: 32px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.BathDet .nNButton:hover {
  width: 120px;
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: white;
  /* padding: var(--saveButtonPaddingHover); */
  transform: translateX(5px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--fontfamily2);
}

.BathDet .nNButton:hover a {
  margin-left: 25px;
}

.BathDet .detailEventButtons a {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .detailEventButtons {
  /* width: 220px; */
  height: var(--saveButtonHeight);
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  font-size: 14px;
  /* margin-right: 7px; */
  text-decoration: none;
}

/* .BathDet .detailEventButtons:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.BathDet .detailEventButtons:hover {
  /* width: 220px; */
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  /* padding: var(--saveButtonPaddingHover); */
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.BathDet .detailEventButtons:hover a,
.BathDet .detailEventButtons:hover .material-icons {
  color: #ffffff;
}

.BathDet .detailEventButtons:hover .Tits_detail {
  width: 51% !important;
}

.questionpapera {
  width: 144px;
  height: 21px;
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  text-decoration: none;
}

.filterBoxText {
  width: 100%;
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.filterBoxText::-webkit-scrollbar {
  width: 3px;
  /* Width of the scrollbar */
}

.filterBoxText::-webkit-scrollbar-track {
  background: var(--colorRed);
  /* Background color of the scrollbar track */
}

.filterBoxText::-webkit-scrollbar-thumb {
  background: var(--colorRed);
  /* Color of the scrollbar thumb */
}

.filterBoxText::-webkit-scrollbar-thumb:hover {
  background: var(--colorRed);
  /* Color of the scrollbar thumb on hover */
}

.tablenj {
  width: 19% !important;
}

.Question_label {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 0.5;
  margin-bottom: 0.5rem;
  margin-top: 30px;
  margin-left: 20px;
}

/* .Question_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Question_label span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.question_label {
  /* width: 502px;
height: 19px; */
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 0.5;
  margin-top: 30px;
  margin-left: 20px;
}

/* -----desktop (Join Test Question)--- */
.answer_label {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 5px;
}

.answer_label_tamil {
  text-align: left;
  font-family: var(--tamilfont);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 0px;
  margin-left: 5px;
}

.answer_label_english {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 0px;
  margin-left: 5px;
}

.answer_label_forSeven {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 2px;
  margin-bottom: 0px;
  /* margin-left: 26px; */
}

.answer_label_tamil_forSeven {
  text-align: left;
  font-family: var(--tamilfont);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 0px;
  /* margin-left: 24px; */
}

.answer_label_english_forSeven {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 0px;
  /* margin-left: 25px; */
}

/* --------View Question Paper -----(Tamil and English Answer)--------  */
.answer_label_viewQ {
  text-align: left;
  font-family: var(--tamilfont);
  /* New Tamil Font FAMILY */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  vertical-align: sub;
  margin-top: 7px;
  margin-bottom: 0px;
  margin-left: 0px;
  vertical-align: sub;
}

.answer_label_viewQ_tamil {
  text-align: left;
  font-family: var(--tamilfont);
  /* New Tamil Font FAMILY */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 7px;
  margin-left: 0px;
  vertical-align: sub;
}

.answer_label_viewQ_english {
  text-align: left;
  font-family: var(--fontfamily2);
  /* New english Font FAMILY */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  vertical-align: sub;
}

/* -----------end----------- */
.answer_labela {
  /* width: 91px;
height: 21px; */
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 0.7;
  margin-top: 15px;
  margin-bottom: 0px;
  /* margin-left: 20px; */
}

.crt_answer_label {
  /* width: 101px;
  height: 19px; */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
  margin-left: 20px;
  margin-top: 15px;
}

.Sil {
  display: block;
  word-wrap: normal;
}

.questionSet {
  width: 50px;
  height: 30px;
  /* UI Properties */
  border: 2px solid var(--unnamed-color-c1393d);
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 15px;
  margin-right: 3px;
  margin-bottom: 20px;
  cursor: pointer;
  /* margin-top: 21px; */
}

.questionSet_Review {
  width: 50px;
  height: 30px;
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 14px;
  margin-right: 9px;
  margin-bottom: 12px;
  cursor: pointer;
  /* margin-top: 21px; */
}

.questionSet_wrong {
  width: 50px;
  height: 30px;

  /* UI Properties */

  background: #4caf50 0% 0% no-repeat padding-box;
  border: 2px solid #4caf50;
  border-radius: 6px;
  opacity: 1;
  margin-left: 14px;
  margin-right: 9px;
  cursor: pointer;
  margin-bottom: 12px;
  /* margin-top: 21px; */
}

.box_review {
  display: flex;
  flex-direction: column;
}

/* .row {
  display: flex;
  flex-direction: row;
} */

/* Set the same height for the components within .box_review */
/* .box_review .row > div {
  flex-grow: 1;
} */

.questionSet_with {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #041134;
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
}

.questionSet_wOut {
  width: 50px;
  height: 50px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 6px;
  opacity: 0.2;

  margin-left: 7px;
  margin-right: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  /* margin-top: 21px; */
}

.mark_number {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.mark_numbers {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--fontfamily2);

  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  display: flex;
  margin-left: 0px;
  margin-top: 3px;
  justify-content: center;
}

.Timing_Sec {
  height: 40px;
  box-shadow: 3px 3px 10px #00000029;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  margin-top: 20px;
  text-align: center;
}

.Timing_Sec .Time {
  width: 21px;
  height: 24px;
  text-align: right;
  font-family: var(--fontfamily2);
  font-size: 18px;
  font-weight: bold;
  color: var(--colorRed);
}

.Timing_Sec .time_span {
  text-align: right;
  font-family: var(--fontfamily2);
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
}

.correct_mark {
  /* width: 18px;
height: 13px; */
  /* UI Properties */
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  color: white;
  opacity: 1;
  display: flex;
  justify-content: center;
}

/* ------View Question paper (Tamil and English Question) start------ */
.question_labels {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #041134;
}

.question_labels_tamil {
  text-align: left;
  font-family: var(--tamilfont);
  font-size: 14px;
  line-height: 24px;
  /* letter-spacing: 0.25px; */
  color: #041134;
}

.question_labels_english {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #041134;
}

/* ---------end------------- */
.answer_labels {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 0px;
  margin-left: 10px;
}

input[type="radio"].radioInput:checked {
  opacity: 1 !important;
  outline-style: none;
  appearance: none !important;

  border: 2px solid #c0272d !important;
}

input[type="radio"].radioInput1:checked {
  opacity: 1 !important;
  outline-style: none;
  appearance: none !important;

  border: 2px solid #c0272d !important;
}

.radioInput {
  position: relative;
}

.radioInput1 {
  position: relative;
}

.radioInput:checked {
  background-color: #fff !important;
  border-color: #c0272d !important;
}

.radioInput1:checked {
  background-color: #fff !important;
  border-color: #c0272d !important;
}

.radioInput:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  /* border: 2px solid #c0272d; */
  background-color: #c0272d !important;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.radioInput1:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  /* border: 2px solid #c0272d; */
  background-color: #c0272d !important;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.radioInput:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 10px;
  border: 2px solid #c0272d;
  background-color: #c0272d !important;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.radioInput1:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 10px;
  border: 2px solid #c0272d;
  background-color: #c0272d !important;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-label {
  margin-left: 5px;
}

.tamilQues {
  margin-top: 20px;
  /* margin-bottom: 30px; */
}

.Question_temp {
  margin-top: 30px;
  margin-left: 20px;
}

.abcd_label {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* letter-spacing: 0.28px; */
  letter-spacing: 5px;
  /* new change*/
  color: #041134;
  opacity: 0.5;
  margin-top: 30px;
  /* margin-left: 26px; */
  /* margin-left: 25px; */
  /* new change*/
}

.user_answer {
  /* width: 82px; */
  /* height: 19px; */
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorRed);
  opacity: 1;
}

.user_answer_en {
  /* width: 82px; */
  height: 19px;
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 0.5;
}

.user_label_answer {
  width: 10px;
  height: 21px;
  /* UI Properties */
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
}

.flag_mark {
  font-size: 15px !important;
  margin-left: 7px;
  color: white;
  position: relative;
  bottom: 22px;
  left: 11px;
}

.flag_mark_red {
  font-size: 15px !important;
  margin-left: 20px;
  color: var(--colorRed);
  position: relative;
  bottom: 20px;
  left: 0px;
}

.flag_capture {
  margin-right: 8px;
  cursor: pointer;
}

.flag_capture_sta {
  margin-right: 8px;
  cursor: pointer;
  /* height: 100%; */
}

.close_capture {
  margin-right: 8px;
  cursor: pointer;
  height: 100%;
}

.close_capture_text {
  text-align: left;
  line-height: 19px;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  margin-right: 2px;
  cursor: pointer;
  margin-top: 4px;
}

.timing_text {
  text-align: left;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
  font-size: 10px;
  font-weight: 800;
  /* Set a fixed width to the container */
  width: 100px;
  /* Change the width to your desired value */
  /* Add ellipsis for text overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* AIASA-000182 v1 issues solved by lalitha */
table#example tbody tr:not(#Subthead):hover td {
  /* background: #e5e6ea; */
  background-color: var(--bluebrand);
  color: white !important;
}

table#example tbody tr:not(#Subthead):hover td .MateWhite {
  color: white !important;
}

/* table#example tbody tr:not(#Subthead):hover td {
  color: white !important;
} */

table#example tbody tr:not(#Subthead):hover td a {
  color: white !important;
}

table#example tbody tr:not(#Subthead):hover td .material-icons {
  color: white !important;
}

table#example tbody tr:not(#Subthead):hover td,
table#example tbody tr:not(#Subthead):hover td .material-icons,
table#example tbody tr:not(#Subthead):hover td a {
  color: white !important;
}

table#example tbody tr:not(#Subthead):hover th,
table#example tbody tr:not(#Subthead):hover th .material-icons,
table#example tbody tr:not(#Subthead):hover th a {
  color: white !important;
}

.BathDet #example #ths {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #0411348a;
  width: 5%;
  /* position: relative; */
  /* opacity: 0.5; */
}

.BathDet #example #thss {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #0411348a;
  width: 14%;
  /* position: relative; */
  /* opacity: 0.5; */
}

.BathDet #example th .active_icons {
  color: #ffffff !important;
  opacity: 1 !important;
  background-color: var(--colorRed);
  padding: 2px;
}

.flag_question {
  text-align: left;
  line-height: 19px;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  cursor: pointer;
}

.rightside_scroll {
  position: inherit;
  overflow-y: scroll;
}

.rightside_scroll::-webkit-scrollbar {
  display: none;
}

.fixed-column {
  position: fixed;
  width: 33.3333%;
  /* Equivalent to col-md-4 in Bootstrap grid */
  height: 100vh;
  top: 33%;
  /* Add any other styling you want for the fixed column */
}

.content-column {
  margin-left: 33.3333%;
  /* Equivalent to col-md-4 in Bootstrap grid */
  /* Add any other styling you want for the content column */
}

.fixed-columns {
  position: fixed;
  width: 33.3333%;
  /* Equivalent to col-md-4 in Bootstrap grid */
  height: 100vh;
  top: 35%;
  /* Add any other styling you want for the fixed column */
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0.5rem;
  margin: 0;
}

.chip {
  display: inline-flex;
  align-items: center;
  background-color: var(--colorRed);
  border: 2px solid var(--colorRed);
  border-radius: 16px;
  padding: 0.25rem 0.5rem;
  margin-top: -0.4rem;
  margin-left: 0px;
  font-size: 14px;
}

.chip-close-icon {
  margin-left: 0.25rem;
  cursor: pointer;
}

.hidden_fields {
  display: none;
}

.batch_fees_text {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--reviewlabelsize);
  line-height: 26px;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
  font-weight: bold;
  margin-right: 15px;
}

.mockinterview_checkbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 3px;
  opacity: 0.7;
  width: 30px;
  height: 30px;
  margin-top: 35px;
  margin-right: 20px;
}

.mockinter_fff {
  position: relative;
  bottom: 36px;
  display: flex;
  justify-content: start;
}

#mockInterviewcurr {
  position: relative;
  top: -17%;
  left: -5%;
  color: var(--colorRed);
}

.cxcxcxc {
  position: relative;
  bottom: 35px;
}

.PrevIcons_ques {
  background: var(--color) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color);
  border-radius: 6px;
  opacity: 1;
  margin-right: 6px;
}

.PrevIcons_ques .material-icons {
  vertical-align: text-top;
  color: #ffffff;
  font-size: 21px;
  opacity: 1;
}

.PrevIcons_ques:disabled {
  background: var(--color) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color);
  border-radius: 6px;
  opacity: 0.5;
  margin-right: 6px;
}

.TestPerformance .nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 0 1 auto;
  text-align: center;
}

.rbc-show-more {
  position: relative !important;
  bottom: 9px !important;
}

.tablehhh {
  width: 13% !important;
}

.MuiChip-deleteIcon {
  color: var(--colorRed) !important;
}

.input-group-text {
  color: var(--bluebrand) !important;
}

.table>thead {
  border-bottom: 1px solid var(--colorRed);
  text-wrap: nowrap;
}

.BathDet .filterBoxssss {
  width: 240px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  position: absolute;
  right: 18px;
}

.MuiInputBase-root {
  margin-left: 20px;
  border-radius: 5px;
}

.MuiInputBase-input {
  height: 4px !important;
}

@media (max-width: 767px) {
  /* .table-responsive {
    max-width: 356px;
    overflow-y: auto;
  } */

  .BathDet .pag select {
    width: 17% !important;
  }

  .fixed-column {
    width: 100% !important;

    height: fit-content !important;
    position: inherit !important;
    margin-bottom: 52px;

    /* Add any other styling you want for the fixed column */
  }

  .fixed-columns {
    width: 100% !important;

    height: fit-content !important;
    position: inherit !important;
    margin-bottom: 52px;

    /* Add any other styling you want for the fixed column */
  }

  .BathDet .StepTwo .box {
    margin-top: 30px;
  }

  .content-column {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .display_sm_center {}

  .text_align_sm_center {
    text-align: center;
  }

  .TimerIconEvent {
    left: 85% !important;
  }

  .answer_label_forSeven {
    margin-left: 13px;
  }

  .answer_label_tamil_forSeven {
    margin-left: 13px;
  }

  .answer_label_english_forSeven {
    margin-left: 13px;
  }

  .width_100 {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .display_sm_end {
    display: flex;
    justify-content: end;
  }

  .BathDet .heading_column {
    flex-direction: column;
  }

  .BathDet .StepOne .box {
    margin-top: 30px;
  }

  .MuiInputBase-root {
    margin-left: 0px !important;
  }

  .AdmissionForm .subnav .custom-itemss {
    pointer-events: none;
  }

  .BathDet .BorderQuery {
    width: 89% !important;
  }

  #assign_to_id {
    margin-left: 20px !important;
  }

  .BathDet {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }

  .BathDet .Inputs {
    width: 100%;
  }

  .BathDet .InputsSer {
    width: 88%;
  }

  .BathDet .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    left: 23px !important;
    height: 36px !important;
    top: 11px !important;
  }

  .BathDet .SaveButton {
    margin-top: 20px;
    width: 100%;
  }

  .BathDet .filterBoxs {
    right: 18%;
  }

  .BathDet .filterBoxs_testlist {
    right: 18%;
  }

  .fixed-column {
    width: 100% !important;

    height: fit-content !important;
    position: inherit !important;
    margin-bottom: 52px;
    /* Add any other styling you want for the fixed column */
  }

  .BathDet .StepOne .box .forms .InputsFile {
    margin-left: 0px !important;
    width: 100%;
  }

  .Event .nn_upload_width {
    width: 100% !important;
  }

  .BathDet .nn_upload_width {
    width: 100% !important;
  }

  .BathDet .Inputsss {
    width: 87%;
  }

  .BathDet .input-group-texts,
  #OldSBatch,
  #panelMem,
  #exam_center,
  #staffMem,
  #District,
  .InputsTextarea,
  #PreDates_mock,
  #department,
  #CPaneMem,
  #CStaffM {
    margin-left: 0px !important;
  }

  .BathDet .input-group .Inputs {
    width: 86%;
  }

  .BathDet .react-datepicker-wrapper_span {
    position: relative;
    cursor: pointer !important;
    top: 17px;
    right: 13px;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  .fixed-columns {
    width: 100% !important;

    height: fit-content !important;
    position: inherit !important;
    margin-bottom: 52px;
    /* Add any other styling you want for the fixed column */
  }

  .BathDet .StepTwo .box {
    margin-top: 30px;
  }

  .content-column {
    margin-left: 0px !important;
  }

  #listViewTabs li a,
  #listViewTabs_sec li a {
    font-size: var(--fontsizetwevele);
    padding-right: 5px;
    /*New code*/
    padding-left: 5px;
    /*New code*/
  }

  .nav-fill .nav-item,
  .nav-fill>.nav-link {
    flex: 1 1 auto !important;
    margin-left: 0px;
    width: max-content;
  }

  .BathDet #listViewTabs .nav-link:hover {
    font-size: var(--fontsizetwevele);
  }

  .BathDet .ListViewBtn {
    width: 100%;
  }

  .BathDet .detailEventButtons {
    width: 100%;
  }

  .Question_label {
    margin-left: 0px;
  }

  .BathDet .detailEventButton {
    width: 100%;
  }

  .BathDet .Titles {
    width: max-content;
    font-size: var(--fontSizesixteen);
  }

  .BathDet .StepOne .box .forms label,
  .BathDet .StepOne .box .forms .Inputs {
    margin-left: 0px !important;
  }

  .BathDet .form-check-inline {
    padding-left: 0px !important;
  }

  #UGDegree,
  #UGMajor,
  #UGClassName,
  #PGDegree,
  #PGMajor,
  #PGClassName,
  #IPDegree,
  #IPMajor,
  #IPClassName,
  #DDegree,
  #DMajor,
  #DClassName,
  #PCMajor,
  #PCDegree,
  #PCClassName,
  #OtClassName,
  #PHDDegree,
  #PHDMajor,
  #PHDClassName,
  #Cast,
  #name,
  #CityPresent,
  #CityPermanent,
  #CourseCate,
  #ExamName,
  #CompetitiveExamName,
  #Designation,
  #Department,
  #CategoryAias,
  #CourseAias,
  #class_name,
  #subject,
  #designation,
  #exp_designation,
  #kComputer,
  #Subject {
    margin-left: 0px !important;
  }

  .BathDet .StepOne .box .forms .InputsFilessss {
    margin-left: 0px !important;
  }

  .BathDet .SaveButtons {
    font-size: var(--fontsizetwevele) !important;
    padding: var(--MobilePaddingButton) !important;
    margin-top: 20px;
    width: 100%;
  }

  .BathDet .Titless {
    width: 100%;
    margin-bottom: 20px;
  }

  .mobiledown {
    flex-direction: column !important;
  }

  .BathDet .ReviewLabel {
    margin-left: 0px !important;
    font-size: 15px !important;
  }

  /* ---------Mobile ------ */
  .BathDet .ReviewLabel_sub {
    font-size: 15px !important;
  }

  .up-in-toggle {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .rbc-current .rbc-button-link {
    margin: 6px 0px 0 7px !important;
  }

  .BathDet .input-group .mobileInput {
    width: 76%;
  }

  .BathDet .pag .prev_button {
    width: 30px;
    height: 30px;
  }

  .BathDet .pagssss select {
    width: 19% !important;
  }

  .BathDet .ListViewBtn a {
    font-size: var(--fontsizeelveen);
  }

  .BathDet .GreyText {
    padding-left: 1px;
    padding-right: 0px;
    padding-top: 3px;
  }

  .BathDet .PinkText {
    padding-left: 2px;
    padding-right: 1px;
    padding-top: 3px;
  }

  .BathDet .pag_fff select {
    width: 19% !important;
  }

  .BathDet .searchInputsss .Inputs {
    width: 75%;
  }

  .BathDet .Title {
    font-size: var(--fontSizeFourteen);
  }

  .BathDet .mobileCanclebutton {
    margin-right: 0px;
    margin-top: 2px;
  }

  .BathDet .can_mobile {
    margin-top: 12px;
  }

  .BathDet .model_label {
    margin-top: 10px !important;
  }

  .BathDet .ListViewBtnss {
    width: 100%;
  }

  .BathDet .facultyTeach .react-datepicker-wrapper_span {
    position: relative;
    top: 9px;
    right: 27px;
    cursor: pointer !important;
    color: var(--bluebrand);
    opacity: 1;
    font-size: 22px;
  }

  .BathDet .SaveButton_edit {
    width: 100%;
  }

  .modal-footer {
    justify-content: center;
  }

  .salary_label {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .salary_text {
    margin-left: 0px !important;
  }

  .editSaveButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-labels {
    margin-left: 0px;
  }

  .BathDet .filterBoxOne .react-datepicker-wrapper_span,
  .BathDet .filterBoxOne_date .react-datepicker-wrapper_span {
    position: relative;
    top: 9px;
    right: 27px;
    color: var(--bluebrand);
    opacity: 1;
    cursor: pointer !important;
    font-size: 22px;
  }
}

.BathDet .pag .prev_button:hover {
  background-color: var(--bluebrand);
  border: 1px solid var(--bluebrand);
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .BathDet {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }

  .BathDet .Tit_witTest {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--fontfamily2);

    color: var(--Titlecolor);

    margin-top: 10px;
  }

  .BathDet .pag select {
    width: 6% !important;
  }

  .BathDet .filterBoxs,
  .BathDet .filterBoxs_fg,
  .BathDet .filterBoxOne,
  .BatchDet .filterBoxOne_Con,
  .BathDet .filterBoxOne_date {
    right: 18% !important;
  }

  .BathDet .filterBoxs_testlist,
  .BathDet .filterBoxOne,
  .BatchDet .filterBoxOne_Con,
  .BathDet .filterBoxOne_date {
    right: 18% !important;
  }

  .dflexbetww {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .flex_rowcolum {
    flex-direction: row;
  }

  .BathDet .Titles {
    width: 51%;
  }

  .BathDet .CancelButton {
    margin-right: 3px !important;
  }

  .BathDet .SaveButtons {
    font-size: var(--fontsizeThrteen);
    padding: var(--MobilePaddingButton) !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) and (orientation: landscape) {
  .BathDet .pag select {
    width: 5% !important;
  }

  .BathDet .ListViewBtn {
    padding: 11px 9px 9px 11px !important;
  }

  .Faculty .CancelButtons {
    margin-right: 0px !important;
  }
}

.preWrap {
  white-space: pre-wrap;
}

.admi_date {
  text-align: left;
  font-family: var(--fontfamily2);
  font-weight: bold;
  font-size: var(--fontSizesixteen);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  margin-top: 10px;
}

/* -------View Question Paper - (Explaination English and Tamil)--------- */
.BathDet .explain_ques {
  text-align: left;
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  padding: 10px;
  margin-left: 10px;
}

.BathDet .explain_ques_tamil {
  text-align: left;
  font-size: 14px;
  font-family: var(--tamilfont);
  /* New Tamil font family*/
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  padding: 10px;
  margin: 0;
}

.BathDet .explain_ques_english {
  text-align: left;
  font-size: 14px;
  font-family: var(--fontfamily2);
  /* English font family*/
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  padding: 10px;
  margin: 0;
}

/* --------end--------- */
.BathDet .explanationQ {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.BathDet .Question_temp {
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.BathDet .Question_temp_VQ {
  /* margin-top: 15px; */
  padding: 20px 20px;
  /* margin-bottom: 30px; */
  /* padding-bottom: 20px; */
}

.QuestionRow .Question_temp {
  margin-top: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.BathDet .BorderQuery {
  width: 97%;
  height: 2px;
  background: var(--colorRed);
  margin: 20px;
}

.BathDet .titleQuery {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
  margin-left: 20px;
}

.titleQuery_attch {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  /* line-height: 19px; */
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
  margin-left: 20px;
}

.BathDet .iconQuery {
  color: var(--colorRed);
  opacity: 1;
  font-size: var(--fontsizeTwentyOne);
  margin-left: 10px;
}

.BathDet .textareaInput {
  height: 100%;
  width: 97%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDet .textareaInput:focus-visible {
  border: 1px solid var(--bluebrand);
  outline: none;
}

.BathDet .QueryButton {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  padding: 0px 73px 0px 72px;
  height: 40px;
}

.BathDet .QueryButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
}

.BathDet .QueryButton a {
  text-align: center;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .QueryEditButton {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  padding: 0px 14px 0px 14px;
  height: 40px;
  cursor: pointer;
}

.BathDet .QueryEditButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
}

.BathDet .QueryEditButton a {
  text-align: center;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .QueryRemindButton {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  padding-top: 6px;
  padding-bottom: 11px;
  cursor: pointer;
  padding-left: 45px;
  padding-right: 44px;
  height: 40px;
}

.BathDet .QueryRemindButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
}

.BathDet .QueryRemindButton a {
  text-align: center;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.BathDet .dflexCenter {
  display: flex;
  justify-content: center;
}

.TestLogo {
  width: 100%;
  height: auto;
  padding-right: 40px;
}

.testProfilepath {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.testUsernae {
  /* text-align: center; */
  font-size: var(--fontSizesixteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  color: #041134;
  margin-top: 10px;
}

.QuesLabel_test {
  text-align: var(--labelalign);
  /* font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important; */
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: bold;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 20px;
  margin-left: 10px;
}

.questionSet_with_green {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #4caf50 0% 0% no-repeat padding-box;
  border: 2px solid #4caf50;
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
}

.questionSet_with_red {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #c1393d 0% 0% no-repeat padding-box;
  border: 2px solid #c1393d;
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
}

.questionSet_with_blue {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #5a3e64 0% 0% no-repeat padding-box;
  border: 2px solid #5a3e64;
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
}

.questionSet_with_blue .flaggreen,
.questionSet_with_blue .flagred {
  position: relative;
  bottom: 5px;
  left: 5px;
}

.JoinStepTitle {
  width: fit-content;
  height: 39px;
  /* New change */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #ffffff;
  /* New change */
  opacity: 1;
  background: #c1393d;
  /* New change */
  border-radius: 50px;
  /* New change */
  margin-top: -19px;
  /* New change */
  margin-left: 10px;
  padding: 8px 10px;
  /* New change */
  margin-bottom: 0px !important;
}

.margin-left_30 {
  margin-left: 34px;
}

.inline-container {
  display: inline-block;
  /* margin-bottom: 20px; */
}

.inline-containers {
  display: inline-block;
  padding: 20px;
}

.inline-span {
  display: inline-block;
}

.review-value {
  text-align: var(--regsisterappvaltextalign);
  font: normal normal var(--regsisterappvalfontweight) var(--regsisterappvalfontsize) Roboto !important;
  letter-spacing: 0.32px;
  color: var(--regsisterappvaltextcolor);
  opacity: 1;
  word-wrap: break-word;
}

.questionSet_bg_white {
  width: 50px;
  height: 30px;
  /* New Change */
  /* UI Properties */
  border: 2px solid var(--unnamed-color-c1393d);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #041134;
  border-radius: 6px;
  opacity: 1;
  margin-left: 14px;
  margin-right: 9px;
  margin-bottom: 12px;
  cursor: pointer;
  /* margin-top: 21px; */
}

.view-button {
  text-align: left;
  font-weight: medium;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #c1393d;
  opacity: 1;
}

.errors {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  margin-left: 20px;
  margin-top: 5px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
}

.payment-name {
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  font-weight: medium;
  font-family: var(--fontfamily2);
  letter-spacing: 0.24px;
  color: #041134;
  opacity: 1;
}

.payment-paragraph {
  text-align: left;
  font-size: 18px;
  line-height: 32px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.18px;
  color: #041134;
  opacity: 1;
}

.payment-span {
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-weight: medium;
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: #c1393d;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.payment-color {
  color: #c1393d;
}

.payment-col-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-brand {
  width: 200px;
  height: 290px;
  margin-left: auto;
  margin-top: -20px;
}

.BathDet .test-table #th {
  background: #041134 0% 0% no-repeat padding-box !important;
  opacity: 1;
}

.BathDet #example #th .color-table {
  color: white;
  opacity: 1 !important;
}

.BathDet #example th .material-icons .color-table {
  opacity: 0.6 !important;
  color: #ffffff !important;
  cursor: pointer !important;
  font-size: 18px;
}

#example th:last-child .color-table {
  background-color: var(--colorBlue) !important;
}

.questionSet_with_right {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #041134;
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
  justify-content: center;
}

.questionSet_left {
  width: 50px;
  height: 30px;
  border: 2px solid var(--unnamed-color-c1393d);
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  margin-right: 0px;
  cursor: pointer;
  margin-top: 15px;
}

.questionSet_with_green_right {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #4caf50 0% 0% no-repeat padding-box;
  border: 2px solid #4caf50;
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
}

.questionSet_with_blue_right {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #5a3e64 0% 0% no-repeat padding-box;
  border: 2px solid #5a3e64;
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
}

.questionSet_with_green_set {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  background: #4caf50 0% 0% no-repeat padding-box;
  border: 2px solid #4caf50;
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
  justify-content: center;
}

.flgQuestionSetImg {
  width: 10px;
  height: 11px;
  position: relative;
  /* left: 8px; (Old Code) */
  left: 2px;
  top: 5px;
}

.questionSet_with_red_sets {
  /* width: 50px;  (Old Code)*/
  width: 56px;
  height: 30px;
  /* UI Properties */
  background: #c1393d 0% 0% no-repeat padding-box;
  border: 2px solid #c1393d;
  border-radius: 6px;
  opacity: 1;
  margin-left: 8px;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  text-align: center;
  justify-content: center;
}

.tabSection {
  padding-left: 30px;
  padding-right: 30px;
}

#listViewTabs_sec {
  opacity: 1;
  border-bottom: 1px solid #04113440 !important;
}

.margin-left_25 {
  margin-left: 25px !important;
}

#listViewTabs_sec {
  opacity: 1;
  border-bottom: 1px solid #04113440 !important;
}

.margin-left_25 {
  margin-left: 25px !important;
}

#Lang {
  margin-top: 20px;
  margin-bottom: 20px;
}

.BathDet .StepOne .box_reviews {
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 30px;
  margin-top: 30px;
}

.width_75 {
  width: 74%;
}

.attachmentQuery {
  color: var(--colorRed);
  opacity: 1 !important;
  font-size: 10px;
}

#batchImage {
  width: 230px;
  height: 230px;
  border-radius: 0%;
  background: grey;
  margin-top: 9px;
}

#batchImage img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* New code  */
#batchImageSmall {
  width: 120px;
  height: 120px;
  border-radius: 3%;
  background: grey;
}

#batchImageSmall img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.HallText {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.14px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 12px;
}

.HallModelTitle {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeTwenty);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--colorBlue);
  opacity: 1;
}

.HallParaText {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizesixteen);
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
}

.hallTLables {
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
}

.Increasecricle {
  margin-top: 6px;
  margin-left: 6px;
  color: var(--colorRed);
}

.alert_info_ReviewLabel {
  background: var(--alertColorInfo) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: var(--colorBlue);
  padding: 20px 14px 14px 20px;
}

.modelHallTicketTitle {
  text-align: left;
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0.2px;
  color: var(--colorBlue);
  opacity: 1;
}

.NavButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.BathDet .buttonClear_download {
  background: var(--colorRed);
  color: #ffffff;
  width: 35%;
  height: 31px;
  border: none;
  font-size: 15px;
  font-family: var(--fontfamily2);
  margin-left: 20px;
  border-radius: 8px;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 36px;
  height: 20px;
  background: #707070;
  /* Default background color */
  border-radius: 0px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: 0.2s;
  background: #fff;
}

.react-switch-checkbox:checked+.react-switch-label {
  background: #2378e9;
  /* Background color when checked */
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 16px;
}

.DisabledListvi {
  opacity: 0.3 !important;
}

.DisabledListviS {
  opacity: 0.3 !important;
}

/* Base styling for the button */
.DisabledListviS {
  /* padding: 10px 20px; */
  font-size: 16px;
  color: white;
  /* background-color: blue; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for the button */
.DisabledListviS:hover {
  /* background-color: darkblue; */
}

.BathDet .DisabledListviS a {
  /* background-color: grey; */
  cursor: not-allowed;
}

/* Disabled state for the button */
.DisabledListviS:disabled {
  /* background-color: grey; */
  cursor: not-allowed;
}

/* Disabling hover effect when the button is disabled */
.DisabledListviS:disabled:hover {
  background-color: grey;
  /* Ensuring the background color stays grey */
}

a:disabled:hover {
  background-color: grey;
  /* Ensuring the background color stays grey */
}

.DisabledFilter {
  opacity: 0.3 !important;
  cursor: no-drop !important;
}

.radioSwitchBtn {
  width: 60px !important;
  height: 46px !important;
}

.MuiSwitch-thumb {
  position: relative !important;
  top: 4px !important;
  left: 0px !important;
}

.greyIconEyes {
  color: #bac5ba !important;
}

#alert-dialog-slide-description {
  margin-top: 38px;
}

.StepOne_modelFilter .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.StepOne_modelFilter .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: white;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
}

.StepOne_modelFilter .box .forms {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.StepOne_modelFilter .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 8px;
  /* margin-left: 20px; */
}

.StepOne_modelFilter .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.StepOne_modelFilter .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.StepOne_modelFilter .box .forms .Inputs::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

.StepOne_modelAdmin .box .forms .Inputs {
  margin-left: 0px !important;
}

.ClearFilterModel {
  text-align: right;
  font-size: var(--fontSizeFourteen);
  font-weight: 500;
  line-height: 19px;
  font-family: var(--fontfamily2);
  cursor: pointer;
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.StepOne_modelFilter .react-datepicker-wrapper_span {
  position: relative;
  top: 16px;
  cursor: pointer !important;
  right: 31px;
  color: var(--bluebrand);
  opacity: 1;
  font-size: 22px;
}

.error_filter {
  color: var(--colorRed);
  font-size: 12px;
  font-family: var(--fontfamily2);
  font-weight: medium;
  margin-top: 3px;
}

.PresentSta {
  background: var(--colorGreen) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorGreen);
  border-radius: 3px;
  opacity: 1;
  text-align: center;
  font-size: var(--fontsizetwevele);
  font-weight: 600;
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 5px 10px 4px 10px;
}

.AbsentSta {
  background: var(--colorPink) 0% 0% no-repeat padding-box;
  border: 1px solid var(--colorPink);
  border-radius: 3px;
  opacity: 1;
  text-align: center;
  font-size: var(--fontsizetwevele);
  font-weight: 600;
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 5px 10px 4px 10px;
}

.LabelClearFilter {
  text-align: left;
  font-size: var(--fontsizetwevele);
  font-weight: 600;
  line-height: 16px;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 1;
}

.BoldTy {
  text-align: left;
  width: max-content;
  display: contents;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #041134;
  opacity: 1;
}

.BoldTyText {
  text-align: right;
  width: max-content;
  display: contents;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #041134;
  opacity: 0.5;
  padding-left: 5px;
}

.topAlert {
  background-color: white;
  color: #c1393d;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 6px;
}

.questionListNumberSet {
  margin-left: 10px;
}

/* Media query 400px */
@media screen and (max-width: 576px) {
  .BathDet .Tit_witTest {
    /* width: 86%; */
    font-size: 16px !important;
    font-weight: bold;
    font-family: var(--fontfamily2);
    letter-spacing: 0.2px;
    color: var(--Titlecolor);
    /* margin-top: 10px; */
  }

  .abcd_label {
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    /* letter-spacing: -6.72px; */
    letter-spacing: -4.72px;
    /*New code*/
    color: #041134;
    opacity: 0.5;
    margin-top: 30px;
    /* margin-left: 19px; */
    /* margin-left: 23px; */
    /* New code*/
  }

  .Timing_Sec {
    /* top: 27%; */
    width: 129px;
    height: 39px;
    border: 2px solid var(--unnamed-color-c1393d);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #00000029;
    border: 2px solid var(--colorRed);
    border-radius: 6px;
    opacity: 1;
    /* position: absolute; */
  }

  .testUsernae {
    font-size: 16px !important;
    font-family: var(--fontfamily2);
    font-weight: bold;
    color: #041134;
  }

  .questionSet_with_blue {
    width: 80px;
    height: 30px;
    background: #5a3e64 0% 0% no-repeat padding-box;
    border: 2px solid #5a3e64;
    border-radius: 6px;
    opacity: 1;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 15px;
    color: white;
    text-align: center;
  }

  /* ----------Join Test Screen ------------(Mobile) */
  .QuesLabel_test {
    height: 19px;
    text-align: var(--labelalign);
    font-size: 12px;
    font-family: var(--fontfamily2);
    font-weight: bold;
    letter-spacing: 0.28px;
    color: var(--labelcolor);
    opacity: 1;
    margin-top: 20px;
    margin-left: 10px;
  }

  .flag_question {
    text-align: left;
    line-height: 19px;
    font-weight: bold;
    font-size: 12px;
    font-family: var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--colorRed);
    opacity: 1;
    cursor: pointer;
  }

  .close_capture_text {
    text-align: left;
    line-height: 19px;
    font-weight: bold;
    font-size: 12px;
    font-family: var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--colorRed);
    opacity: 1;
    margin-right: 8px;
    cursor: pointer;
    margin-top: 4px;
  }

  .flag_capture_sta {
    margin-right: 8px;
    cursor: pointer;
    height: 80%;
  }

  .close_capture {
    margin-right: 8px;
    cursor: pointer;
    height: 80%;
  }

  /* -------Mobile - View Question Paper (Tamil and English) --------- */
  .question_labels {
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #041134;
  }

  .question_labels_tamil {
    text-align: left;
    font-family: var(--tamilfont);
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #041134;
  }

  .question_labels_english {
    text-align: left;
    font-family: var(--fontfamily2);
    font-size: 12px;
    line-height: 24px;
    /* letter-spacing: 0.25px; */
    color: #041134;
  }

  /* --------Mobile View Question Paper -----(Tamil and English Answer)--------  */
  .answer_label_viewQ {
    text-align: left;
    font-family: var(--tamilfont);
    /* New Tamil Font FAMILY */
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    vertical-align: sub;
    opacity: 0.7;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .answer_label_viewQ_tamil {
    text-align: left;
    font-family: var(--tamilfont);
    /* New Tamil Font FAMILY */
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    vertical-align: sub;
    opacity: 0.7;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .answer_label_viewQ_english {
    text-align: left;
    font-family: var(--fontfamily2);
    /* New Tamil Font FAMILY */
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    opacity: 0.7;
    margin-top: 8px;
    vertical-align: sub;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  /* -------View Question Paper - (Explaination English and Tamil)--------- */
  .BathDet .explain_ques_tamil {
    text-align: left;
    font-size: 12px;
    font-family: var(--tamilfont);
    /* New Tamil font family*/
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: var(--colorBlue);
    opacity: 1;
    padding: 10px;
    margin: 0;
  }

  .BathDet .explain_ques {
    text-align: left;
    font-size: 14px;
    font-family: var(--fontfamily2);
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: var(--colorBlue);
    opacity: 1;
    padding: 10px;
  }

  .BathDet .explain_ques_english {
    text-align: left;
    font-size: 12px;
    font-family: var(--fontfamily2);
    /* New English font family*/
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: var(--colorBlue);
    opacity: 1;
    padding: 10px;
    margin: 0;
  }

  /* --------end--------- */
  .answer_label {
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    opacity: 0.7;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 8px !important;
  }

  .answer_label_tamil {
    text-align: left;
    font-family: var(--tamilfont);
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    opacity: 0.7;
    margin-top: 3px;
    margin-bottom: 0px;
    margin-left: 8px !important;
  }

  .answer_label_english {
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #041134;
    opacity: 0.7;
    margin-top: 3px;
    margin-bottom: 0px;
    margin-left: 8px !important;
  }

  .QuestionRow .Question_temp {
    margin-top: 0px;
    margin-left: 8px;
    margin-bottom: 20px;
  }

  .flagred {
    position: relative;
    bottom: 1px !important;
    left: 7px !important;
  }

  .BathDet .StepOne {
    padding: 0;
  }

  .viewQuestionList {
    padding: 0 !important;
  }

  .mobileSets {
    margin-left: 5px !important;
  }

  .BathDet .StepOne .box_review {
    /* height: fit-content; */
    background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--fieldTitleBorder);
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 30px;
    margin-top: 30px;
    /* width: 100% !important; */
    /* margin-left: 2px; */
  }

  .BathDet .StepOne .box_review_join {
    /* height: fit-content; */
    /* background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box; */
    border: 1px solid var(--fieldTitleBorder);
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100% !important;
    margin-left: 2px;
  }

  .BathDet .Question_temp {
    margin-top: 30px;
    margin-left: 11px;
    margin-bottom: 20px;
    overflow-y: auto;
    /* Allow vertical scrolling */
    max-height: 50vh;
  }

  .BathDet .Question_temp_VQ {
    /* margin-top: 15px; */
    margin-left: 12px;
    /* margin-bottom: 30px; */
    overflow-y: auto;
    max-height: 50vh;

    max-height: 50vh;
  }
}

@media (min-width: 768px) {
  .mobileSets {
    margin-left: 5px !important;
  }
}

@media (min-width: 576px) and (max-width: 760px) {
  .TestLogo {
    width: 150px;
    height: 60px;
  }

  /* -----mobile view --- */
  .abcd_label {
    text-align: left;
    font-family: var(--fontfamily2);
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    /* letter-spacing: -6.72px; */
    letter-spacing: -4.72px;
    /*New code*/
    color: #041134;
    opacity: 0.5;
    margin-top: 30px;
    /* margin-left: 19px; */
    /* margin-left: 17px; */
    /* New code*/
  }

  .Timing_Sec .Time {
    width: 21px;
    height: 24px;
    text-align: right;
    font-family: var(--fontfamily2);
    font-size: 13px;
    font-weight: bold;
    color: var(--colorRed);
  }

  .BathDet .Tit_witTest {
    width: 21%;
    font-size: 16px !important;
    font-weight: bold;
    font-family: var(--fontfamily2);
    letter-spacing: 0.2px;
    color: var(--Titlecolor);
    opacity: 1;
    margin-left: 3px !important;
    margin-top: 2px;
  }

  .testProfilepath {
    width: 45px;
    height: 60px;
    opacity: 1;
    border-radius: 50%;
    margin-right: 30px;
  }

  /* .testUsernae {
    text-align: center;
    font-size: 19px !important;
    font-family: var(--fontfamily2);
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #041134;
    opacity: 1;
  } */
}

.bg-logo {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  position: absolute;
  z-index: -1;
  opacity: 0.1;
}

@media (max-width: 767px) {

  /* Mobile devices */
  .bg-logo {
    width: 89%;
    margin: 30px auto;
  }

  .Timing_Sec .Time {
    width: 21px;
    height: 24px;
    text-align: right;
    font-family: var(--fontfamily2);
    font-size: 13px;
    font-weight: bold;
    color: var(--colorRed);
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* Tablets */
  .bg-logo {
    width: 75%;
    margin: 30px auto;
  }

  .Timing_Sec .Time {
    width: 21px;
    height: 24px;
    text-align: right;
    font-family: var(--fontfamily2);
    font-size: 13px;
    font-weight: bold;
    color: var(--colorRed);
  }
}

@media (min-width: 992px) {

  /* Desktops */
  .bg-logo {
    width: 60%;
    margin: 10px 45px;
  }
}

.bg-logo {
  width: 500px;
  height: auto;
  display: block;
  position: fixed;
  z-index: -1;
  opacity: 0.1;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {

  /* Mobile devices */
  .bg-logo {
    width: 89%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* Tablets */
  .bg-logo {
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
  }

  .Timing_Sec {
    height: 43px;
    box-shadow: 3px 3px 10px #00000029;
    border: 2px solid var(--colorRed);
    border-radius: 6px;
    margin-top: 5px;
    text-align: center;
  }

  .Timing_Sec .Time {
    width: 21px;
    height: 24px;
    text-align: right;
    font-family: var(--fontfamily2);
    font-size: 13px;
    font-weight: bold;
    color: var(--colorRed);
  }
}

@media (min-width: 992px) {

  /* Desktops */
  .bg-logo {
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.width70PX {
  width: 70px;
}

.TimerIconME {
  cursor: pointer;
}

.TimerIconEvent {
  cursor: pointer;
  position: relative;
  bottom: 31px;
  left: 89%;
}

.HyperLink {
  color: var(--colorRed);
  text-decoration: none;
  cursor: pointer;
}