.secondaryButton {
  color: #c0272d !important;
  text-decoration: none;
  margin-right: 10px;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}

.BathDetCentreSup {
  padding: 20px !important;
}

.CentresupListButton {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.BathDetCentreSup .TitleSupervisor {
  font-weight: var(--titlefontWeight);
  font-size: var(--titlefontSize);
  margin-bottom: 0;
  padding: 12px;
}

.examListDropdown {
  display: flex;
  justify-content: end;
  margin: 10px 0 10px auto;
}

.pagecountDropdown {
  padding: 3px 5px;
}

.InputsSupervisor {
  width: 300px !important;
  border-radius: 6px;
  margin: 0 !important;
}

.InputsSupervisor:focus,
.InputsSupervisor:focus-visible {
  border: 1px solid var(--bluebrand) !important;
  outline: none;
}

.pagecountDropdown:focus,
.pagecountDropdown:focus-visible {
  border: 1px solid var(--bluebrand) !important;
  outline: none;
}

.supPageListDrop select {
  appearance: auto;
  background: #c0272d !important;
  color: #fff !important;
  border-radius: 4px;
}

.BathDetCentreSup #centreSupList th .material-iconsRightAndLeft {
  color: var(--colorBlue);
  cursor: pointer !important;
  font-size: 18px;
}

.BathDetCentreSup .material-iconsRightAndLeft {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  font-weight: var(--addmoreiconsweight);
}

.BathDetCentreSup #centreSupList #th {
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  color: var(--colorBlue);
}

.BathDetCentreSup #centreSupList #td {
  color: var(--colorBlue);
  font-size: var(--fontSizeFourteen);
  word-break: break-all;
  padding: 15px 0px 15px 10px;
}

.BathDetCentreSup #centreSupList th .material-icons {
  opacity: 0.6 !important;
  cursor: pointer !important;
  font-size: 18px;
}

.helloWorld {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
}

.ellipsis {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.venueTh {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prev_buttonCentreSup .material-icons:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
  opacity: 0.6;
}

.material-icons:disabled {
  cursor: not-allowed;
}

.prev_buttonCentreSup:disabled {
  border: #a9a9a9;
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
  opacity: 0.6;
}

.prev_buttonCentreSup {
  background-color: #c0272d;
  border: 1px solid var(--colorRed);
  border-radius: 4px;
}

.prev_buttonCentreSup .material-iconsRightAndLeft {
  color: #fff;
  vertical-align: text-bottom;
  font-family: "Material Icons";
}

.listButton {
  margin-top: 10px;
}

@media (max-width: 576px) {
  .BathDetCentreSup {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 0px !important;
  }

  .CentresupListButton {
    margin: 0;
    list-style-type: none;
    display: block;
    padding: 0 !important;
  }

  .BathDetCentreSup .TitleSupervisor {
    font-size: var(--fontSizeFourteen);
    font-weight: var(--titlefontWeight);
    color: var(--Titlecolor);
    margin-top: 10px;
    padding: 10px 0px;
  }

  .listButton {
    margin: 0px 0px 10px 0px;
  }

  .ellipsis {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: clip;
  }

  .examListDropdown {
    display: flex;
    justify-content: end;
  }

  .prev_buttonCentreSup {
    background-color: #c0272d;
    border: 1px solid var(--colorRed);
    border-radius: 4px;
    height: fit-content !important;
  }

  .centreSupListBtn {
    flex-direction: column;
    text-align: center;
  }

  .InputsSupervisor {
    width: 100% !important;
    font-size: var(--fontsizetwevele);
  }

  .secondaryButton {
    font-size: var(--fontsizetwevele);
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .BathDetCentreSup #centreSupList #th {
    color: var(--colorBlue);
    text-align: inherit;
    font-weight: var(--boldtextWeight);
    font-size: var(--fontsizetwevele);
    white-space: nowrap;
  }

  .BathDetCentreSup #centreSupList #td {
    white-space: nowrap;
    font-size: var(--fontsizetwevele);
  }
}

@media (max-width: 768px) {}

.BathDetCentreSup .PinkText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  width: fit-content;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--colorPink);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDetCentreSup .GreenTexts {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  width: fit-content;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}