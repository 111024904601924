.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-brand-800);
  background-color: var(--color-brand-800);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 12px;
  min-width: 300px;
  border-radius: var(--border-radius-sm);
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: background-color 0.5s ease, border-color 0.5s ease;
}

.button:hover {
  background-color: var(--color-blue-600);
  border-color: var(--color-blue-600);
  justify-content: start;
}
.button:disabled {
  opacity: 0.5;
  /* background-color: var(--color-brand-200);
  border: none; */
}
.button:disabled:hover {
  background-color: var(--color-brand-800);
  opacity: 0.5;
  justify-content: center;
  border-color: var(--color-brand-800);
  cursor: not-allowed;
}
.icon {
  font-size: 18px;
  position: absolute;
  right: 20px;
  visibility: hidden;
  transition: right 0.5s ease, visibility 0.5s ease;
}

.button:hover .icon {
  right: 10px;
  visibility: visible;
}
.button:disabled .icon {
  visibility: hidden;
}
@media (min-width: 768px) {
  /*md portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .button {
    min-width: 200px;
  }
  /* custome styles from diff pages */
  .ViewTestAlert_viewTestPerfomance {
    min-width: 220px;
  }
}
