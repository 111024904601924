@import url('../../../../Themes/themes.css');

.form-group.col-md-6 {
    text-align: left;
    /* margin-bottom: 2em; */
    padding: 2em 2em 0em 3em;
}
label {
    font-weight: 500;
}
a.Test_link,a.course_cancel {
    color: white;
    text-decoration: none;
}
#search-btn {
    margin-left: 0em;
    margin-top: -1.6em;
    /* margin-right: -22em; */
    font-size: 2.5em;
}
.card-body.batchupdate
{
    box-shadow: 0px 0px 3px #0000004D;
    border-radius: 8px;
}
img.batchupdate {
    border-radius: 8px;
}
.batchupdate_course_name,.batchupdate_batch_name
{
    color: var(--color);
    text-align: center;
font: var(--font) bold 16px/21px var(--fontfamily2);
letter-spacing: 0.32px;
opacity: 1;
margin-top:1em;
font-family: var(--fontfamily2), Bold;
font-size: var(--subtitlefontsize);
}
.batchupdate_icon
{
    margin-top: 0em;
    margin-bottom: -2em;
    color: var(--color);
    margin-right: 6.5em;


}