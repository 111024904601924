.screen-share-container {
  display: flex;
  top: 20px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin: 50px auto;
}
.backAndNext {
  text-align: center !important;
}
.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.popup-title {
  font-size: x-large;
  padding-left: 10px;
  text-align: left;
  font-weight: bolder;
}

.toggle-slider {
  width: 50px;
  height: 25px;
  background-color: #ccc; /* Default color */
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
}
.eventProdAndName {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}
.eventAndImage {
  display: flex !important;
}
.eventAndImagesss {
  visibility: hidden;
  display: none;
}

.toggle-slider.on {
  background-color: #2378e9; /* Color when screen sharing is enabled */
}

.toggle-slider::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.2s;
}
.toggle-slider.on::before {
  transform: translateX(25px);
}

.instructions {
  text-align: left;
}

.instructions h2 {
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 10px;
}

.instructions ol {
  font-size: 14px;
  padding-left: 20px;
}

.error-message {
  color: #c0272d;
  margin-bottom: 20px;
}

.start-exam-button {
  background-color: #c0272d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
}

.start-exam-button:hover {
  background-color: #2378e9;
}
.start-exam-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.start-exam-button:hover:not(.disabled) {
  background-color: #2378e9;
}

.fullscreenpopup {
  position: absolute;
  z-index: 99; /* Ensure content is above the overlay */
  text-align: center;
  top: 0%;
  left: 35%;
}

.fullpopupscreen {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Ensure it covers the entire screen */
  top: 0;
  left: 0;
  z-index: 9999;
}

.fullpopupscreen::before {
  content: "";
  position: fixed; /* Ensure the overlay covers the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.7);
  /* background-color: #45a049; */
  z-index: 1;
}

.hidden {
  display: none;
}
@media screen and (max-width: 576px) {
  .eventAndImage {
    margin-top: 10px;
  }
  .eventProdAndName {
    display: flex;
    flex-direction: column-reverse;
  }

  .eventAndImagesss {
    visibility: unset;
    display: flex !important;
    padding-top: 5px;
    padding: 15px 0px 5px;
  }
}
@media screen and (max-width: 768px) {
  .tabEvent {
    margin-top: 0px;
    font-size: 14px;
  }
  .questionSet_with_blue {
    width: 83px;
    height: 30px;
    background: #5a3e64 0% 0% no-repeat padding-box;
    border: 2px solid #5a3e64;
    border-radius: 6px;
    opacity: 1;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 24px;
    color: white;
    text-align: center;
  }
  .usSelectImage img {
    width: 20px;
    height: 19px;
  }
}
