.Faculty {
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: var(--paddingScreen);
  padding-right: var(--paddingScreen);
}

.Faculty .Inputs::placeholder,
.Faculty input[type="tel"]::placeholder {
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.7;
}

/* 
.Faculty input[type="text"]::placeholder,
.Faculty input[type="email"]::placeholder,
.Faculty input[type="password"]::placeholder,
.Faculty input[type="number"]::placeholder,
.Faculty input[type="file"]::placeholder,
.Faculty input[type="tel"]::placeholder , */

.Faculty .CancelButton {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 10px;
}

.dbetw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Faculty .CancelButtons {
  height: 19px;
  text-align: var(--cancelbuttonAlign);
  font-family: var(--cancelFontFamily);
  font-size: var(--cancelButtonFontSize);
  font-weight: var(--cancelFontWeight);
  color: var(--cancelButtonColor);
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  margin-right: 30px;
  margin-top: 16px;
}

.Faculty .SaveButton a {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
  width: max-content;
}

.Faculty .SaveButton {
  /* width: 197px; */
  height: 40px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
}

.Faculty .SaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: 3%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: #ffffff !important;
  border-radius: 3px;
}

.Faculty .SaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Faculty .SaveButton:hover a {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  width: max-content;
  color: #ffffff;
  opacity: 1;
}

.editSaveButton a {
  text-align: left;
  width: max-content;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

.editSaveButton {
  /* width: 197px; */
  height: 40px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
  text-decoration: none;
}

.model_label {
  margin-top: 10px !important;
}

/* .Faculty .editSaveButton:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: 3%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: #FFFFFF !important;
  border-radius: 3px;
} */

.editSaveButton:hover {
  height: var(--saveButtonHeight);
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.editSaveButton:hover a {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #ffffff;
  width: max-content;
  opacity: 1;
}

.Faculty .StepOne .SaveButton_Prev a,
.Faculty .sectionTwo .SaveButton_Prev a,
.Faculty .sectionThree .SaveButton_Prev a,
.Faculty .sectionFour .SaveButton_Prev a,
.Faculty .sectionFive .SaveButton_Prev a,
.Faculty .sectionSix .SaveButton_Prev a {
  text-align: center;
  text-decoration: none;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen);
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Faculty .StepOne .SaveButton_Prev:hover a,
.Faculty .sectionTwo .SaveButton_Prev:hover a,
.Faculty .sectionThree .SaveButton_Prev:hover a,
.Faculty .sectionFour .SaveButton_Prev:hover a,
.Faculty .sectionFive .SaveButton_Prev:hover a,
.Faculty .sectionSix .SaveButton_Prev:hover a {
  color: var(--color);
}

.Faculty .SaveButton:hover a .material-icons {
  color: #ffffff;
}

.Faculty .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  /* UI Properties */
  background: var(--FieldTitleBoxColor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.Faculty .Titles {
  /* width: 71%; */
  height: 26px;
  font-weight: var(--titlefontWeight);
  font-size: var(--titlefontSize);
  font-family: var(--fontfamily2) !important;
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.Faculty .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font-weight: var(--fieldStepTitleWeight);
  font-size: var(--fieldStepTitleSize);
  font-family: var(--fontfamily2) !important;

  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  opacity: 1;
  position: absolute;
  background: var(--colorWhite);
  margin-top: -12px;
  margin-left: 2px;
  padding: 0 10px;
}

.Faculty .StepOne .box .forms {
  display: flex;
  flex-direction: column;
}

.Faculty .checkbox {
  margin-right: 0;
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
}

.Faculty .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font-weight: var(--labelweight);
  font-size: var(--labelfont);
  font-family: var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 18px;
  margin-left: 20px;
}

.Inputs:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.Faculty .StepOne .box .forms .Inputs:focus-visible,
.Faculty .StepOne .box .forms .Inputs_Phone:focus-visible {
  background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.Faculty .StepOne .box .forms .Inputs {
  height: 36px;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Faculty .StepOne .box .forms .Inputs_Phone {
  height: 36px;
  /* UI Properties */
  width: 97%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--countrycodePadding);
  line-height: var(--LineHeight);
  margin-top: 10px !important;
  font-family: var(--fontfamily2);
}

.Faculty .iti__flag-container {
  left: 20px !important;
  height: 36px;
  top: 10px;
}

/* .Faculty .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container{

  left: 20px !important;
  height: 34px;
 top: 10px;
} */

/* .Faculty #subjectdata span{



} */

.Faculty .sectionSix .box .forms .InputsMutiple {
  /* height: 36px; */
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
  line-height: var(--LineHeight);
}

.Faculty .StepOne .box .forms .InputsFile {
  height: var(--InputHeight);
  /* UI Properties */
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 0.8;
  margin-left: 20px;
  padding: var(--InputFilepadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Faculty .error {
  font-size: var(--errortextsize);
  color: var(--errortextcolor);
  /* margin: 15px; */
  text-align: var(--errortextalign);
  margin-top: 10px;
  font-weight: var(--errortextweight);
  margin-bottom: 0;
  margin-left: 20px;
}

.Faculty .StepOne .box .forms .InputsMutiple {
  height: 99px;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Faculty .StepOne .box .forms .InputsMutiple option {
  margin-top: 10px;
  margin-left: 10px;
}

.Faculty .form-check-inline {
  display: inline-block;
  margin-right: -1rem;
}

.Faculty .ListViewBtn {
  /* width: fit-content; */
  height: var(--ListViewBtnHeight);
  /* UI Properties */
  text-align: left;
  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: var(--fontsizetwevele);
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: var(--boldtextWeight);
}

.Faculty .ListViewBtn a {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

.Faculty .ListViewBtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  /* top: -8%; */
  right: -26px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  /* margin-right: 20px; */
  background-color: transparent;
  color: white !important;
  border-radius: 3px;
}

.Faculty .ListViewBtn:hover a,
.Faculty .ListViewBtn:hover .material-icons {
  color: white !important;
}

.Faculty .ListViewBtn:hover {
  /* width: var(--ListViewBtnHover); */
  height: var(--ListViewBtnHeight);
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.ListViewBtn_facul {
  /* width: 160px; */
  height: var(--ListViewBtnHeight);
  /* UI Properties */
  text-align: left;
  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: var(--fontsizetwevele);
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: var(--boldtextWeight);
}

.ListViewBtn_facul a {
  width: max-content;
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontsizetwevele);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

/* .Faculty .ListViewBtn_facul:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -8%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: var(--saveButtoniconColor);
  border-radius: 3px;
} */

.Faculty .ListViewBtn_facul:hover a,
.Faculty .ListViewBtn_facul:hover .material-icons {
  color: white !important;
}

.Faculty .ListViewBtn_facul:hover {
  /* width: 160px; */
  height: var(--ListViewBtnHeight);
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontfamily2);
}

.Faculty .ReviewLabel {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--reviewlabelsize);
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Faculty .ReviewLabels {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--reviewlabelsize);
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorRed);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
}

.Faculty .ReviewLabelss {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--reviewlabelsize);
  font-family: var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 3px;
  word-wrap: break-word;
}

.Faculty .Title {
  /* width: 100%; */
  height: 26px;
  /* UI Properties */
  font: normal normal var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  margin-left: 10px;
  margin-top: 20px;
}

.Faculty .modal-title {
  text-align: left;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontSizeTwenty);
  font-family: var(--fontfamily2);
  letter-spacing: 0.2px;
  color: var(--colorBlue);
  opacity: 1;
}

.Faculty .modal-body p {
  text-align: left;
  font-weight: medium;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  letter-spacing: 0.14px;
  color: var(--colorBlue);
  opacity: 1;
}

.Faculty .backView {
  font-weight: medium;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  text-align: right;
  letter-spacing: 0px;
  color: var(--colorRed);
  opacity: 1;
}

.Faculty .Modalbtn {
  width: var(--modelbtnwidth);
  height: var(--modelbtnHeight);

  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  color: var(--colorWhite);
  opacity: 1;
}

.Faculty .Modalbtn:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -13%;
  right: -29px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color: white;
  border-radius: 3px;
}

.Faculty .Modalbtn:hover a,
.Faculty .Modalbtn:hover .material-icons {
  color: white;
}

.Faculty .Modalbtn:hover {
  width: var(--modelbtnwidthHover);
  height: var(--modelbtnHeight);
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.Faculty .Modalbtn a {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontsizetwevele);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

/* .Inputs::placeholder{
  font-family: var(--fontfamily2);
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  letter-spacing: 0px;
  color: var(--colorBlue);
  opacity: 0.2;
} */
textarea::placeholder {
  text-align: left;
  font-size: var(--textareaPlaceHolder);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--textareaPlaceHolderColor);
  opacity: 0.4;
}

.MobileShow {
  display: none;
}

#SearchFilterInputs {
  width: 80%;
}

@media only screen and (max-width: 576px) {
  .widthForm {
    width: 100% !important;
  }

  .Faculty {
    padding-left: var(--paddingMobileScreen);
    padding-right: var(--paddingMobileScreen);
    margin-top: 40px;
  }

  .Faculty .iti__flag-container,
  .ModelExamForm .iti__flag-container {
    left: 0px !important;
    height: 36px !important;
    top: 10px !important;
  }

  .Faculty .StepOne .box .forms #phoneNumber {
    margin-left: 0px !important;
  }

  .MobileHide {
    display: none;
  }

  .MobileShow {
    display: block;
    margin-right: 0px !important;
    /* margin-top: 19px !important; */
    height: auto !important;
  }

  .MarginTop {
    margin-top: 10px !important;
  }

  .ListViewBtn_facul,
  .Faculty .Modalbtn {
    width: 100%;
  }

  .Faculty .ReviewLabel {
    margin-left: 0px !important;
  }

  .Faculty .Title {
    margin-left: 0px !important;
    font-size: var(--fontSizeFourteen);
  }

  .dbetw {
    display: flex;
    justify-content: space-between;
  }

  .widthForms {
    width: 100% !important;
  }

  .display_none {
    display: none;
  }

  .Faculty .ProfilePhoto {
    width: 212px !important;
    height: 214px !important;
  }

  #profilephotos {
    width: 184px !important;
    height: 184px !important;
  }

  /* 
  .display_none_Stepborder {
    display: none;
  } */
  .Faculty .CancelButton {
    margin-bottom: 20px;
  }

  .Faculty .form-check-inline {
    padding-left: 0px !important;
  }

  .Faculty .react-datepicker-wrapper_span {
    top: 15px;
    cursor: pointer !important;
    right: 14px;
  }

  .Faculty .mobileTop {
    margin-top: 20px;
  }

  div#subjectdata span {
    margin-left: 0px !important;
    width: 100% !important;
  }

  /* .heading {
    flex-direction: column;
  } */

  /* .heading .smButton {
    flex-direction: column;
    margin: 0px !important;
  } */

  .heading button {
    font-size: 12px;
    font-family: var(--fontfamily2);
  }

  .Faculty .StepOne .SaveButton,
  .Faculty .sectionTwo .SaveButton,
  .Faculty .sectionThree .SaveButton,
  .Faculty .sectionFour .SaveButton,
  .Faculty .sectionFive .SaveButton,
  .Faculty .sectionSix .SaveButton {
    width: 100%;
  }

  .Faculty .StepOne .SaveButton_Prev,
  .Faculty .sectionTwo .SaveButton_Prev,
  .Faculty .sectionThree .SaveButton_Prev,
  .Faculty .sectionFour .SaveButton_Prev,
  .Faculty .sectionFive .SaveButton_Prev,
  .Faculty .sectionSix .SaveButton_Prev {
    width: 100%;
  }

  .Faculty .StepOne .box,
  .Faculty .sectionTwo .box,
  .Faculty .sectionThree .box,
  .Faculty .sectionFour .box,
  .Faculty .sectionFive .box,
  .Faculty .sectionSix .box {
    margin: 0;
    margin-top: 30px;
    /* width: 100%; */
  }

  .Faculty .StepOne .box .forms label,
  .Faculty .sectionTwo .box .forms label,
  .Faculty .sectionThree .box .forms label,
  .Faculty .sectionFour .box .forms label,
  .Faculty .sectionFive .box .forms label,
  .Faculty .sectionSix .box .forms label {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: var(--labelfont) !important;
  }

  .Faculty .StepOne .box .forms .Inputs,
  .Faculty .sectionTwo .box .forms .Inputs,
  .Faculty .sectionThree .box .forms .Inputs,
  .Faculty .sectionFour .box .forms .Inputs,
  .Faculty .sectionFive .box .forms .Inputs,
  .Faculty .sectionSix .box .forms .Inputs,
  .Faculty .sectionSix .box .forms .Inputs_Phone {
    margin-left: 0px !important;
  }

  .Faculty .sectionSix .box .forms .InputsMutiple {
    margin-left: 0px !important;
  }

  .Faculty .StepOne .box .forms .InputsFile,
  .Faculty .sectionTwo .box .forms .InputsFile,
  .Faculty .sectionThree .box .forms .InputsFile,
  .Faculty .sectionFour .box .forms .InputsFile,
  .Faculty .sectionFive .box .forms .InputsFile,
  .Faculty .sectionSix .box .forms .InputsFile {
    margin-left: 0px !important;
  }

  .Faculty .sectionTwo .box,
  .Faculty .sectionThree .box,
  .Faculty .sectionFour .box,
  .Faculty .sectionFive .box,
  .Faculty .sectionSix .box {
    height: fit-content;

    padding-bottom: 20px;
  }

  .Faculty .subnav .custom-item {
    margin-left: 0px;
    width: 100%;
  }

  .Faculty .progressTitle .Card .cTitle {
    font-size: var(--mqprogressCardTitleFontsize);
    font-family: var(--progressCardTitle);
  }

  .Faculty .progressTitle .Card .cSubtitle {
    font-size: var(--mqprogressCardSubFontsize);
    font-family: var(--progressCardTitle);
    margin: 5px;
  }

  .Faculty .sectionSix .box .StepTitle {
    margin-left: -19px;
  }

  .Faculty .radioGroup .formCheck .checkbox {
    margin: 3px !important;
  }

  .ImageText {
    margin-top: 10px;
  }

  .IconsImage {
    margin-top: 10px;
  }

  #WhatsAppNumber {
    margin-top: 23px;
  }

  .Faculty #radiobuttons {
    flex-direction: column;
  }

  .Faculty .subnav {
    display: none;
  }

  .psubtitle {
    width: 100% !important;
    /* height: 37% !important; */
    margin: 0px;
  }

  .sectionSeven {
    margin-top: 0px !important;
    margin-bottom: 100px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet devices */
  /* .display_none_Stepborder {
    display: "";
  } */

  .Faculty #radiobuttons {
    flex-direction: row;
  }

  .Faculty .progressTitle .Card .cTitle,
  .Faculty .progressTitle .Cards .cTitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
  }

  .Faculty .progressTitle .Card .cSubtitle,
  .Faculty .progressTitle .Cards .cSubtitle {
    font-size: var(--fontSizeFourteen);
    width: max-content;
    margin: 0;
  }

  .Faculty .progressTitle .Card .Stepborders {
    width: 32px;
    height: 0px;
    border: 1px solid var(--progressActivestepborder);
    opacity: 1;
    position: absolute;
    margin-left: 105px;
    margin-top: 18px;
    border-bottom: 1px solid var(--progressActivestepborder);
  }

  .Faculty .progressTitle .Card .Stepborder,
  .Faculty .progressTitle .Cards .Stepborder {
    width: 24px !important;

    margin-left: 102px !important;
  }

  .Faculty .sectionThree .box .forms label {
    height: 28px;
  }

  .Faculty .StepOne .SaveButton_Prev,
  .Faculty .sectionTwo .SaveButton_Prev,
  .Faculty .sectionThree .SaveButton_Prev,
  .Faculty .sectionFour .SaveButton_Prev,
  .Faculty .sectionFive .SaveButton_Prev,
  .Faculty .sectionSix .SaveButton_Prev {
    width: 100% !important;
    font-size: 11px !important;
  }

  .IconsImage .title {
    font-size: 11px !important;
  }
}

.Faculty .filterBox {
  width: 240px;
  height: 245px;
  /* UI Properties */
  background: var(--colorWhite) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border: 1px solid var(--colorWhite);
  border-radius: 10px;
  opacity: 1;
  z-index: 99;
  /* position: relative;
    top: -288px;
    left: 70%; */
  position: absolute;
  /* top: 66%;
  left: 66%; */
}

.BathDet .YellowText {
  text-align: center;
  padding-left: 6px;
  padding-right: 7px;
  text-wrap: nowrap;
  /* padding-top: 2px;
  padding-bottom: 3px; */
  background-color: var(--colorYellow);

  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: 12px !important;
  opacity: 1;
  border-radius: 2px;
}

/* New ADDED in StudentsQuery */
.BathDet .RedText {
  text-align: center;
  padding-left: 6px;
  padding-right: 7px;
  background-color: #e91e63;
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: 12px !important;
  opacity: 1;
  border-radius: 2px;
}

.BathDet .GreenText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  /* padding-top: 3px;
  padding-bottom: 2px; */
  background-color: var(--colorGreen);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele) !important;
  opacity: 1;
  border-radius: 2px;
}

.BathDet .RedText {
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorLightRed);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  font-size: var(--fontsizetwevele);
  opacity: 1;
  border-radius: 2px;
}

.BathDet .clearFilter {
  color: var(--unnamed-color-c1393d);
  text-align: left;
  font-family: var(--fontfamily2);
  font-size: var(--fontSizeFourteen) !important;
  letter-spacing: 0px;
  color: var(--bluebrand);
  opacity: 1;
}

.BathDet .PinkText {
  text-align: center;
  padding-left: 2px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: var(--colorPink);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite) !important;
  font-size: var(--fontsizetwevele) !important;
  opacity: 1;
  border-radius: 2px;
}

.Faculty .StepOne .box .AddMore {
  text-align: left;
  opacity: 1;
  text-decoration: none;
  color: var(--bluebrand);
  font-family: var(--fontfamily2);
  font-size: 14px;
  font-weight: var(--addmoreFontWeight);
  cursor: pointer;
  margin-top: 16px;
}

.Faculty .StepOne .box .AddMore .material-icons {
  border-radius: 3px;
  font-size: var(--addmoreiconssize);
  /* font-family: 'Material Icons'; */
  font-weight: var(--addmoreiconsweight);
}

div#subjectdata span {
  margin-left: 20px;
  margin-top: 4px;
  width: 81%;
}

#hio {
  color: #041134;
  opacity: 0.6;
}

.form-labels {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  margin-left: 23px;
}

.UploadMarkSheetLabel {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.28px;
  color: #041134;
  opacity: 1;
  /* margin-left: 23px; */
}

.InputsTextarea::placeholder {
  text-align: left;
  font: normal normal medium 14px/19px Roboto;
  letter-spacing: 0px;
  color: #041134;
  opacity: 0.4;
}

.Faculty .borderActive {
  width: 1280px;
  border-bottom: 1px solid var(--colorRed);
  opacity: 0.6;
  position: relative;
  top: -18px;
  left: 37px;
}

.Faculty .STitles {
  width: 66%;
  height: 26px;
  font-weight: var(--titlefontWeight);
  font-size: var(--titlefontSize);
  font-family: var(--fontfamily2) !important;
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.Faculty .STitless {
  width: 73%;
  height: 26px;
  font-weight: var(--titlefontWeight);
  font-size: var(--titlefontSize);
  font-family: var(--fontfamily2) !important;
  letter-spacing: 0.2px;
  color: var(--Titlecolor);
  opacity: 1;
  /* margin-left: 47px; */
  margin-top: 9px;
}

.BathDet .borderSalary {
  /* width: 1280px; */
  height: 0px;
  border: 2px solid #041134;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 0px;
}

.Boxs {
  width: 1280px;
  height: 105px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 8px;
  opacity: 1;
}

.salary_text {
  /* width: 142px; */
  height: 21px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0.32px;
  color: #041134;
  opacity: 1;
  margin-left: 20px;
  margin-top: 7px;
}

.salary_label {
  text-align: inherit !important;
  font-weight: var(--boldtextWeight) !important;
  font-size: var(--fontSizeFourteen) !important;
  font-family: var(--fontfamily2) !important;
  letter-spacing: 0px !important;
  color: #0411348a !important;
  width: 20%;
}

.model_title {
  width: 167px;
  height: 26px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0.2px;
  color: #041134;
  opacity: 1;
}

.modal-header {
  border-bottom: 2px solid #041134;
  /* New change */
}

.modal-footer {
  border-top: 2px solid #041134;
  /* New change */
}

.model_label {
  /* width: 110px; */
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.model .Inputs {
  height: 36px;
  width: 100%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 0px !important;
  padding: var(--Inputpadding);
  margin-top: 6px !important;
  line-height: var(--LineHeight);
  font-family: var(--fontfamily2);
}

.model {
  padding-bottom: 100px;
}

.modeltitle {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  font-family: var(--fontfamily2);
  letter-spacing: 0.2px;
  color: #041134;
  opacity: 1;
}

.active_comment {
  width: 6px;
  height: 6px;
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  opacity: 1;
  /* position: relative; */
  /* bottom: 12px; */
  border-radius: 50%;
  /* right: 5px; */
  display: inline-block;
}

.Faculty .ListViewBtn_event {
  /* width: 120px; */
  height: var(--ListViewBtnHeight);
  /* UI Properties */
  text-align: left;
  letter-spacing: 0.28px;
  background: var(--saveButtonColor) 0% 0% no-repeat padding-box;
  border: 2px solid var(--saveButtonBorder);
  color: var(--saveButtonTextColor);
  padding: var(--saveButtonPadding);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: var(--fontsizetwevele);
  justify-content: center;
  font-family: var(--fontfamily2);
  margin-top: 10px;
  font-weight: var(--boldtextWeight);
}

.Faculty .ListViewBtn_event a {
  text-align: inherit;
  font-weight: var(--boldtextWeight);
  font-size: var(--fontsizetwevele);
  font-family: var(--fontfamily2);
  letter-spacing: 0px;
  color: var(--colorWhite);
  opacity: 1;
  text-decoration: none;
}

/* .Faculty .ListViewBtn_event:hover::after {
  content: var(--saveButtonAfterIcon);
  position: absolute;
  top: -2%;
  right: -20px;
  font-size: var(--savebuttonIconsSize);
  font-weight: var(--saveButtonfontweight);
  padding: var(--saveButtonPaddingHover);
  font-family: var(--fontfamily2);
  margin-right: 20px;
  background-color: transparent;
  color:white !important;
  border-radius: 3px;
} */

.Faculty .ListViewBtn_event:hover a,
.Faculty .ListViewBtn_event:hover .material-icons {
  color: white !important;
}

.Faculty .ListViewBtn_event:hover {
  /* width: 120px; */
  height: var(--ListViewBtnHeight);
  /* height: var(--saveButtonHeight); */
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
  border-radius: 6px;
  opacity: 1;
  color: var(--saveButtonHoverTextColor);
  padding: var(--saveButtonPaddingHover);
  transform: translateX(0px);
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--fontfamily2);
}

.optionFields {
  font-family: var(--fontfamily2);
  font-style: italic;
  font-size: var(--fontsizetwevele);
  opacity: 0.6;
  font-weight: 400;
}

.Faculty .Question_temp {
  margin-top: 30px;
  margin-left: 20px;
}

/* ---------Query ------Question paper--- */
.Faculty .explain_ques {
  text-align: left;
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

.Faculty .explain_ques_english {
  text-align: left;
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

.Faculty .explain_ques_tamil {
  text-align: left;
  font-size: 14px;
  font-family: var(--tamilfont);
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

/* ----------------end-------------- */
.Faculty .explanationQ {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.Faculty .BorderQuery {
  width: 97%;
  height: 2px;
  background: var(--colorRed);
  margin: 20px;
}

.Faculty .titleQuery {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
  margin-left: 20px;
}

.Faculty .iconQuery {
  color: var(--colorRed);
  opacity: 1;
  font-size: var(--fontsizeTwentyOne);
  margin-left: 10px;
}

.Faculty .textareaInput {
  height: 100%;
  width: 97%;
  background: var(--InputBGcolor) 0% 0% no-repeat padding-box;
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  line-height: var(--LineHeight);
  padding: var(--Inputpadding);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.Faculty .textareaInput:focus-visible {
  border: 1px solid var(--bluebrand);
  outline: none;
}

.Faculty .QueryButton {
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  padding: 0px 73px 0px 72px;
  height: 40px;
}

/* .Faculty .QueryButton a {


  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  opacity: 1;
  padding: 12px 73px 12px 72px;
} */

.Faculty .QueryButton a {
  text-align: center;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.Faculty .QueryButton:hover {
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 2px solid var(--bluebrand);
}

.Faculty .dflexCenter {
  display: flex;
  justify-content: center;
}