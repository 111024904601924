/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

.LoginForm {
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 768px;
    /* UI Properties */
    background: var(--bgcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--bgborder);
    opacity: 0.9;
    background-image: var(--bgimage) !important;
    background-size: cover;
}

.resend_otp {
    cursor: pointer !important;
}

#wrapper_Login .box {
    text-align: center;
    padding: var(--loginboxPadding);
    width: var(--loginboxWidth);
    height: var(--loginboxHeight);
    position: relative;
    /* margin: auto; */
    top: 50px;
    /* UI Properties */
    background: var(--loginbox) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px var(--loginboxshadow);
    border: 1px solid var(--loginboxborder);
    border-radius: 8px;
    opacity: 1;
}

#wrapper_Login .box h1 {
    margin: 0 auto;
    width: max-content;
    text-align: var(--loginH1Textalign);
    font: normal normal var(--loginH1fontweight) var(--H1Fontsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginH1Color);
    opacity: 1;
    letter-spacing: 0px;
    margin-top: 10px;
}

#wrapper_Login .box .reg {
    /* width: 109px; */
    height: var(--regtextheight);
    text-align: var(--regtextalign);
    font: normal normal var(--normalFontWeight) var(--regtextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: #041134;
    opacity: 1;
}

#wrapper_Login .box .SignupText {
    margin-top: 40px;
    /* margin-left: 90px; */
}

#wrapper_Login .box .reg .rega {
    /* width: 68px; */
    height: var(--loginregatextheight);
    text-align: var(--loginregatextalign);
    text-decoration: underline;
    font: normal normal var(--loginregatextweight) var(--loginregatextsize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--bluebrand);
    opacity: 1;
    margin-top: 30px;
    margin-left: 5px !important;
}

#wrapper_Login .box p {
    text-align: var(--login_p_textalign);
    font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
    letter-spacing: var(--login_p_letter_spacing);
    color: var(--login_p_textcolor);
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 15px;
}

#wrapper_Login .box .LoginInputs {
    width: var(--loginInputBoxWidth);
    height: var(--loginInputBoxHeight);
    padding: var(--loginInputBoxPadding);
    background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--loginInputBoxborder);
    border-radius: 6px;
    opacity: 1;
    margin-top: 15px;
    font-family: var(--fontfamily2);
}

#wrapper_Login .box .registerfield {
    /* width: var(--loginInputBoxWidth); */
    height: var(--loginInputBoxHeight);
    padding: var(--loginInputBoxPadding);
    background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--loginInputBoxborder);
    border-radius: 6px;
    opacity: 1;
    margin-top: 15px;
    font-family: var(--fontfamily2);
}

/* -------------Start------------ */
#wrapper_Login .box .registerfield::-webkit-outer-spin-button,
#wrapper_Login .box .registerfield::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#wrapper_Login .box .registerfield[type="number"] {
    -moz-appearance: textfield;
}

/* -------------End---------------- */
#wrapper_Login .box input[type="text"]::placeholder,
#wrapper_Login .box input[type="email"]::placeholder,
#wrapper_Login .box input[type="password"]::placeholder,
#wrapper_Login .box input[type="number"]::placeholder,
#wrapper_Login .box input[type="file"]::placeholder {
    font-family: var(--fontfamily2);
    text-align: left;
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    letter-spacing: 0px;
    color: var(--colorBlue);
    opacity: 0.7;
}

#wrapper_Login .box input[type="checkbox"] {
    width: var(--loginCheckInputBoxWidthHeight);
    height: var(--loginCheckInputBoxWidthHeight);
    margin-top: 16px;
    /* UI Properties */
    background: var(--loginCheckInputBoxcolor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--loginCheckInputBoxborder);
    border-radius: 3px;
    opacity: 1;
    vertical-align: middle;
    font: normal normal var(--normalFontWeight) var(--loginCheckInputBoxSize) var(--fontfamily2);
}

#wrapper_Login .box .checkbox-inline {
    width: var(--loginCheckbox_inlinewidth);
    height: var(--loginCheckbox_inlineheight);
    text-align: left;
    font: normal normal var(--normalFontWeight) var(--loginCheckInputBoxSize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--loginCheckbox_inlinecolor);
    opacity: 1;
    margin-left: 6px;
    margin-top: 15px;
}

#wrapper_Login .box a {
    /* width: 91px; */
    height: 17px;
    text-align: end;
    text-decoration: var(--login_a_decoration);
    font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--bluebrand);
    opacity: 1;
    margin-top: 15px;
    margin-left: 96px;
}

#wrapper_Login .box .copyRights {
    /* width: 135px; */
    height: 17px;
    text-align: var(--login_copyrightstextalign);
    font: normal normal var(--normalFontWeight) var(--login_copyrightssize) var(--fontfamily2);
    letter-spacing: 0px;
    color: var(--login_copyrightscolor);
    opacity: 1;
    margin-top: 20px;
    margin-left: 23px;
}

#wrapper_Login .box .LoginBtn {
    width: var(--loginbuttonWidth);
    height: var(--loginButtonHeight);
    background: var(--loginRedColor) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: var(--loginWhiteColor);
    font: normal normal var(--normalFontWeight) var(--loginbuttoncolorsize) var(--fontfamily2);
    padding: var(--loginbuttoncolorPadding);
    border: none;
    margin-top: 31px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

#wrapper_Login .box .LoginBtn:hover {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    background: var(--bluebrand);
    border: 2px solid var(--bluebrand);
    color: white !important;
    /* text-indent: -20px; */
    padding-left: 29px;
    text-align: left;
}

#wrapper_Login .box .LoginBtn::after {
    content: var(--saveButtonAfterIcon);
    font-weight: bold;
    position: absolute;
    border-width: 8px 8px 0 8px;
    bottom: 8px;
    right: 2%;
    transform: translateX(-50%);
    opacity: 0;
    color: white !important;
    transition: all 0.3s ease;
}

#wrapper_Login .box .LoginBtn:hover::after {
    opacity: 1;
    font-size: 24px;
    font-weight: bold;
}

#wrapper_Login #img {
    top: 140px;
    left: 634px;
    width: var(--loginLogoWidthHeight);
    height: var(--loginLogoWidthHeight);
    opacity: 1;
}

@media only screen and (max-width: 576px) {
    #wrapper_Logins img {
        height: 100%;
    }

    #wrapper_Logins .box {
        text-align: center;
        padding: var(--loginboxPadding);
        width: var(--loginboxWidth);
        height: var(--loginboxHeight);
        position: relative;
        margin: auto;
        top: 50px;
        /* UI Properties */
        background: var(--loginbox) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px var(--loginboxshadow);
        border: 1px solid var(--loginboxborder);
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Login .box h1 {
        margin: 0 auto;
        width: max-content;
        text-align: var(--loginH1Textalign);
        font: normal normal var(--loginH1fontweight) var(--H1Fontsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginH1Color);
        opacity: 1;
        letter-spacing: 0px;
        margin-top: 10px;
    }

    #wrapper_Login .box .reg {
        /* width: 109px; */
        height: var(--regtextheight);
        text-align: var(--regtextalign);
        font: normal normal var(--normalFontWeight) var(--regtextsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: #041134;
        opacity: 1;
    }

    #wrapper_Login .box .SignupText {
        margin-top: 40px;
        /* margin-left: 90px; */
    }

    #wrapper_Login .box .reg .rega {
        /* width: 68px; */
        height: var(--loginregatextheight);
        text-align: var(--loginregatextalign);
        text-decoration: underline;
        font: normal normal var(--loginregatextweight) var(--loginregatextsize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--bluebrand);
        opacity: 1;
        margin-top: 30px;
        margin-left: 5px !important;
    }

    #wrapper_Login .box p {
        text-align: var(--login_p_textalign);
        font: normal normal var(--normalFontWeight) var(--login_p_textsize) var(--fontfamily2);
        letter-spacing: var(--login_p_letter_spacing);
        color: var(--login_p_textcolor);
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    #wrapper_Login .box .LoginInputs {
        width: var(--loginInputBoxWidth);
        height: var(--loginInputBoxHeight);
        padding: var(--loginInputBoxPadding);
        background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
        border: 1px solid var(--loginInputBoxborder);
        border-radius: 6px;
        opacity: 1;
        font-family: var(--fontfamily2);
        margin-top: 15px;
    }

    #wrapper_Logins .box input[type="checkbox"] {
        width: var(--loginCheckInputBoxWidthHeight);
        height: var(--loginCheckInputBoxWidthHeight);
        margin-top: 16px;
        /* UI Properties */
        background: var(--loginCheckInputBoxcolor) 0% 0% no-repeat padding-box;
        border: 1px solid var(--loginCheckInputBoxborder);
        border-radius: 3px;
        opacity: 1;
        vertical-align: middle;
        font: normal normal var(--normalFontWeight) var(--loginCheckInputBoxSize) var(--fontfamily2);
    }

    #wrapper_Logins .box .checkbox-inline {
        width: var(--loginCheckbox_inlinewidth);
        height: var(--loginCheckbox_inlineheight);
        text-align: left;
        font: normal normal var(--normalFontWeight) var(--loginCheckInputBoxSize) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginCheckbox_inlinecolor);
        opacity: 1;
        margin-left: 6px;
        margin-top: 15px;
    }

    #wrapper_Logins .box .LoginBtn {
        width: 100%;
    }

    #wrapper_Logins .box a {
        /* width: 91px; */
        height: 17px;
        text-align: end;
        text-decoration: var(--login_a_decoration);
        font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 57px;
    }

    #wrapper_Logins #boxs {
        transform: translate(0%, 0%) !important;
    }

    #wrapper_Logins #boxs #privacypolicy {
        position: relative;
        left: 41px;
        top: -21px;
    }

    #wrapper_Logins #boxs #poliytext {
        margin-left: 32px;
    }

    #wrapper_Logins #boxss {
        transform: translate(0%, 0px) !important;
    }

    #wrapper_Login .box h1 {
        font: normal normal var(--loginH1fontweight) var(--mqH1Fontsize) var(--fontfamily2);
        width: max-content;
        margin: 0 auto;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
    /* img {
      height: 100%;
    } */

    #wrapper_Logins .box {
        text-align: center;
        padding: var(--loginboxPadding);
        width: var(--loginboxWidth);
        height: var(--loginboxHeight);
        position: relative;
        margin: auto;
        top: 50px;
        /* UI Properties */
        background: var(--loginbox) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px var(--loginboxshadow);
        border: 1px solid var(--loginboxborder);
        border-radius: 8px;
        opacity: 1;
    }

    #wrapper_Logins .box input[type="email"],
    #wrapper_Logins .box input[type="password"],
    #wrapper_Logins .box input[type="text"] {
        width: 100%;
        height: var(--loginInputBoxHeight);
        padding: var(--loginInputBoxPadding);
        background: var(--loginInputBoxcolor) 0% 0% no-repeat padding-box;
        border: 1px solid var(--loginInputBoxborder);
        border-radius: 6px;
        opacity: 1;
        margin-top: 15px;
        font-family: var(--fontfamily2);
    }

    #wrapper_Logins .box .LoginBtn {
        width: 100%;
    }

    #wrapper_Logins .box a {
        height: 17px;
        text-decoration: var(--login_a_decoration);
        font: normal normal var(--login_a_weight) var(--login_a_size) var(--fontfamily2);
        letter-spacing: 0px;
        color: var(--loginRedColor);
        opacity: 1;
        margin-top: 17px;
        margin-left: 70px;
    }
}

/* Medium devices (laptops, desktops) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    /* styles here */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 992px) {
    /* styles here */
}

.videoWrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.videoWrapper video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

#wrapper_Login .box small {
    color: var(--loginsmalltextcolor);
    font: normal normal var(--loginsmalltextweight) var(--loginsmalltextsize) var(--fontfamily2);
}

/* #wrapper_Login  #boxs {
  
      width:var(--regsisterformsWidth)!important;
     
      height:fit-content !important;
   
      position: relative;
      margin: 0 auto;
  } */

/* #wrapper_Login #boxs input[type="email"], #boxs input[type="password"] {
  
      margin-top: 16px !important
  } */

/* #wrapper_Login #boxs a{
      margin: 0px !important;
  } */

/* #wrapper_Login #boxs .copyRights{
      margin-top: 14px !important;
      margin-left: 0px !important;
  } */

/* #wrapper_Login  #boxss{
  
      width: 360px;
      height: 344px;
      position: absolute;
      transform: translate(50%, 0px);
  } */

/* #wrapper_Login  #boxss a{
  
      margin: 0px !important;
  } */

.LoginInputs:focus-visible {
    background: var(--focusInputBackgroundColor) 0% 0% no-repeat padding-box;
    /* border: 2px solid var(--focusInputBordercolor); */
    border: 1px solid var(--bluebrand) !important;
    border-radius: 6px;
    opacity: 1;
    padding: var(--focusInputpadding);
    outline: none;
    margin-top: 10px;
    color: var(--focusInputcolor);
    font-family: var(--fontfamily2);
}

#wrapper_Login .eyes_login {
    position: relative;
    left: 45%;
    bottom: 27px;
    font-size: 20px;
    color: var(--bluebrand);
}