
@import url('../../../Themes/themes.css');

button.SaveButton.mockinterview
{
width: var(--mockinterviewavebuttonWidth) !important;
}

#panelmem{

    position: relative;
    top: 13px;
}

/* .form-check-label  {
    margin-top: 25px !important;
  } */
